<template>
  <div class="configure-dialog-step-six">
    <div class="flex-box-column-start">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont mr-2">{{ $t('vue.contactForm') }}</div>
          <vx-tooltip :text="$t('info.contactForm')" position="top">
            <img :src="infoImage" />
          </vx-tooltip>
        </vs-col>
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="translation--container">
            <div class="mr-4"><info-icon fill="#01AAF4" color="#fff" :width="20" :height="20" /></div>
            <div v-html="$t('info.translation')"></div>
          </div>
        </vs-col>
      </div>
    </div>
    <div class="flex-view">
      <div class="mt-5 mr-6 flex flex-column flex-grow" style="max-width: 630px">
        <div>{{ $t('vue.messageIfNobodyAvailable') }}</div>
        <vs-textarea counter="300" height="100px" placeholder="" v-model="dialog.contactForm.message" class="w-full p-1" />
        <div class="feedback-form-colors flex flex-row flex-wrap justify-between w-full">
          <div class="flex flex-column flex-grow">
            <div>{{ $t('vue.contactFormBackgroundColor') }}</div>
            <div><input class="pa-color-picker" type="color" v-model="dialog.contactForm.backgroundColor" name="feedbackFormConfirmButtonColor" /></div>
          </div>
          <div class="flex flex-column flex-grow">
            <div>{{ $t('vue.fontColor') }}</div>
            <div><input class="pa-color-picker" type="color" v-model="dialog.contactForm.fontColor" name="feedbackFormFontColor" /></div>
          </div>
        </div>

        <div class="mt-4 flex items-center">
          <div class="mr-2"><vs-switch color="#275D73" v-model="dialog.contactForm.isBookingEnabled" :disabled="!HAS_SCHEDULING_ACCESS"> </vs-switch></div>
          <div class="mr-2" style="align-self: flex-end">{{ $t('vue.meetingSlotBooking') }}</div>

          <div>
            <vx-tooltip v-if="HAS_SCHEDULING_ACCESS" :text="$t('info.meetingSlotBooking')" position="top">
              <img :src="infoImage" />
            </vx-tooltip>
            <vx-tooltip v-else :text="$t('upgrade-messages.locked-feature')" position="top" style="height: 20px">
              <small-lock-icon />
            </vx-tooltip>
          </div>
        </div>

        <div class="mt-4 flex items-center" v-if="dialog.contactForm.isBookingEnabled && HAS_SCHEDULING_ACCESS">
          <div class="mr-2">{{ $t('vue.bookableAppointments') }}</div>
          <vx-tooltip :text="$t('info.bookableAppointments')" position="top">
            <img :src="infoImage" />
          </vx-tooltip>
        </div>

        <v-select
          v-if="dialog.contactForm.isBookingEnabled && HAS_SCHEDULING_ACCESS"
          class="mb-4 md:mb-0 w-full"
          :options="appointments"
          :clearable="false"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="selectedAppointment"
        >
          <template v-slot:option="option">
            <div>
              {{ option.label }}
            </div>
          </template>

          <template #no-options>{{ '' }}</template>

          <template #list-footer>
            <div
              class="configure-dialog-step-six__select-link-message"
              @click="$router.push({ name: 'company-setup', params: { action: 'create-appointment' } })"
            >
              <CalendarIcon color="#3B86F7" class="configure-dialog-step-six__select-link-message__icon" :width="13" :height="13" />

              {{ $t('vue.createNewBookableAppointments') }}
            </div>
          </template>
        </v-select>

        <div class="mt-4">{{ $t('vue.contactFormFields') }}</div>
        <div class="contact-form-fields flex flex-row flex-wrap justify-between w-full">
          <div class="mt-2 flex flex-column">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('vue.name') }}</div>
              <div v-if="!dialog.contactForm.isBookingEnabled" style="margin-left: auto">
                <div @click="dialog.contactForm.fields.name.enabled = !dialog.contactForm.fields.name.enabled">
                  <toggle-switch-icon :enabled="dialog.contactForm.fields.name.enabled" :width="40" :height="40" />
                </div>
              </div>
            </div>
            <div>
              <vs-checkbox :disabled="dialog.contactForm.isBookingEnabled" v-model="dialog.contactForm.fields.name.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
          <div class="mt-2 flex flex-column">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('vue.email') }}</div>
            </div>
            <div>
              <vs-checkbox disabled v-model="dialog.contactForm.fields.email.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
          <div class="mt-2 flex flex-column" v-if="dialog.contactForm.isBookingEnabled">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('vue.date') }}</div>
              <div v-if="!dialog.contactForm.isBookingEnabled" style="margin-left: auto">
                <div @click="dialog.contactForm.fields.date.enabled = !dialog.contactForm.fields.date.enabled">
                  <toggle-switch-icon :enabled="dialog.contactForm.fields.date.enabled" :width="40" :height="40" />
                </div>
              </div>
            </div>
            <div>
              <vs-checkbox :disabled="dialog.contactForm.isBookingEnabled" v-model="dialog.contactForm.fields.date.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
          <div class="mt-2 flex flex-column" v-if="!dialog.contactForm.isBookingEnabled">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('vue.phone') }}</div>
              <div style="margin-left: auto">
                <div @click="dialog.contactForm.fields.phone.enabled = !dialog.contactForm.fields.phone.enabled">
                  <toggle-switch-icon :enabled="dialog.contactForm.fields.phone.enabled" :width="40" :height="40" />
                </div>
              </div>
            </div>
            <div>
              <vs-checkbox v-model="dialog.contactForm.fields.phone.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
          <div class="mt-2 flex flex-column">
            <div class="contact-form-fields-item flex-grow flex items-center">
              <div class="contact-form-fields-item-text">{{ $t('inputs.message') }}</div>
              <div style="margin-left: auto">
                <div @click="dialog.contactForm.fields.message.enabled = !dialog.contactForm.fields.message.enabled">
                  <toggle-switch-icon :enabled="dialog.contactForm.fields.message.enabled" :width="40" :height="40" />
                </div>
              </div>
            </div>
            <div>
              <vs-checkbox v-model="dialog.contactForm.fields.message.required">
                <span class="contact-form-fields-item-required">{{ $t('vue.requiredInformation') }}</span>
              </vs-checkbox>
            </div>
          </div>
        </div>
        <!-- PRIVACY INFORMATION -->
        <div class="mt-4 flex flex-row items-center justify-center">
          <div class="headline">{{ $t('vue.privacyInformation') }}</div>
          <div class="ml-2">
            <vx-tooltip :text="$t('info.privacyInformation')" position="top">
              <img :src="infoImage" />
            </vx-tooltip>
          </div>
        </div>

        <div class="mt-4 flex items-center">
          <div class="mr-2">
            <div @click="dialog.contactForm.isPrivacyInformationEnabled = !dialog.contactForm.isPrivacyInformationEnabled">
              <toggle-switch-icon :enabled="dialog.contactForm.isPrivacyInformationEnabled" :width="40" :height="40" />
            </div>
          </div>
          <div class="mr-2">{{ $t('vue.enablePrivacyInformation') }}</div>
        </div>
        <div class="mt-4 mr-2 w-full" v-if="dialog.contactForm.isPrivacyInformationEnabled">
          <quill-editor v-model="dialog.contactForm.privacyInformationHtml" ref="quillEditorA" :options="editorOption"> </quill-editor>
        </div>

        <!-- SEND A MESSAGE -->
        <div class="mt-4 flex flex-row items-center justify-center">
          <div class="headline">{{ $t('vue.sendMessageEndMessage') }}</div>
          <div class="ml-2">
            <vx-tooltip :text="$t('info.sendMessageEndMessage')" position="top">
              <img :src="infoImage" />
            </vx-tooltip>
          </div>
        </div>

        <div class="mt-4 flex items-center">
          <div class="mr-2">
            <div @click="showSendMessagePreview = !showSendMessagePreview">
              <toggle-switch-icon :enabled="showSendMessagePreview" :width="40" :height="40" />
            </div>
          </div>
          <div class="mr-2">{{ $t('vue.showPreview') }}</div>
        </div>

        <div class="mt-4">{{ $t('inputs.title') }}</div>
        <vs-input class="w-full mr-2" v-model="dialog.contactForm.sendMessageTitle" />
        <div class="mt-4">{{ $t('inputs.message') }}</div>
        <vs-textarea counter="300" height="100px" v-model="dialog.contactForm.sendMessageCopy" class="w-full p-1" />

        <!-- MEETING SLOT BOOKING -->

        <div v-if="dialog.contactForm.isBookingEnabled" class="mt-4 flex flex-row items-center justify-center">
          <div class="headline">{{ $t('vue.bookingSlotEndMessage') }}</div>
          <div class="ml-2">
            <vx-tooltip :text="$t('info.bookingSlotEndMessage')" position="top">
              <img :src="infoImage" />
            </vx-tooltip>
          </div>
        </div>

        <div v-if="dialog.contactForm.isBookingEnabled" class="mt-4 flex items-center">
          <div class="mr-2">
            <div @click="showBookingSlotPreview = !showBookingSlotPreview">
              <toggle-switch-icon :enabled="showBookingSlotPreview" :width="40" :height="40" />
            </div>
          </div>
          <div class="mr-2">{{ $t('vue.showPreview') }}</div>
        </div>

        <div v-if="dialog.contactForm.isBookingEnabled" class="mt-4">{{ $t('inputs.title') }}</div>
        <vs-input v-if="dialog.contactForm.isBookingEnabled" class="w-full mr-2" v-model="dialog.contactForm.bookingSlotTitle" />
        <div v-if="dialog.contactForm.isBookingEnabled" class="mt-4">{{ $t('inputs.message') }}</div>
        <vs-textarea counter="300" height="100px" v-if="dialog.contactForm.isBookingEnabled" v-model="dialog.contactForm.bookingSlotCopy" class="w-full p-1" />
      </div>

      <div class="flex flex-column" style="margin-left: auto">
        <div class="mb-3" style="align-self: flex-start">{{ $t('vue.preview') }}</div>
        <div class="relative flex flex-column" v-if="showContactFormPreview">
          <div class="flex justify-center items-center" :style="contactFormBackgroundStyle">
            <div class="message-container" :style="contactFormStyle">
              <scroll-view ref="chatScrollArea" class="contact-form-scroll-area">
                <div style="position: absolute; right: 0px; top: 0px">
                  <div class="close-box flex justify-center items-center" :style="closeButton">
                    <close-icon :width="16" :height="16" :color="dialog.contactForm.fontColor"></close-icon>
                  </div>
                </div>
                <div class="message-container-title">
                  {{ $t('vue.contactForm') }}
                </div>
                <div class="mt-4 message-container-copy">
                  {{ dialog.contactForm.message }}
                </div>

                <div class="flex mt-4 justify-center items-center">
                  <div class="message-container-upper">{{ $t('vue.sendAMessage') }}</div>

                  <div
                    class="ml-2 mr-2 message-container-upper pointer"
                    style="margin-top: 5px"
                    @click="isBookingEnabled = !isBookingEnabled"
                    v-if="dialog.contactForm.isBookingEnabled"
                  >
                    <switch-on-icon color="#12598D" :width="41" :height="20" v-if="isBookingEnabled"></switch-on-icon>
                    <switch-off-icon color="#B2B2B2" :width="41" :height="20" v-if="!isBookingEnabled"></switch-off-icon>
                  </div>
                  <div v-if="dialog.contactForm.isBookingEnabled" class="message-container-upper">{{ $t('vue.bookAMeeting') }}</div>
                </div>

                <!-- CONTACT FORM FIELDS -->
                <div class="mt-4 message-container-fields" v-show="dialog.contactForm.fields.name.enabled">
                  <div class="mr-2" style="margin-top: 1px"><user-icon :width="15" :height="15" color="#262629"></user-icon></div>
                  <div class="upper-case">{{ $t('vue.name') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.name.required">*</span>
                </div>
                <div class="mt-2 message-container-fields" v-if="dialog.contactForm.fields.email.enabled">
                  <div class="mr-2" style="margin-top: 1px"><mail-icon :width="15" :height="15" color="#262629"></mail-icon></div>
                  <div class="upper-case">{{ $t('vue.emailAddress') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.email.required">*</span>
                </div>
                <div class="mt-2 message-container-fields" v-if="dialog.contactForm.fields.date.enabled && dialog.contactForm.isBookingEnabled">
                  <div class="mr-2" style="margin-top: 1px"><calendar-icon :width="15" :height="15" color="#262629"></calendar-icon></div>
                  <div class="upper-case">{{ $t('vue.date') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.date.required">*</span>
                </div>
                <div class="mt-2 message-container-fields" v-if="dialog.contactForm.fields.phone.enabled && !dialog.contactForm.isBookingEnabled">
                  <div class="mr-2" style="margin-top: 1px">
                    <VerticalPhoneIcon :width="15" :height="15" color="#262629" />
                  </div>
                  <div class="upper-case">{{ $t('vue.phone') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.phone.required">*</span>
                </div>
                <div class="mt-2 message-container-fields ht-100" v-if="dialog.contactForm.fields.message.enabled">
                  <div class="mr-2" style="margin-top: 1px"><message-square-icon :width="15" :height="15" color="#262629"></message-square-icon></div>
                  <div class="upper-case">{{ $t('inputs.message') }}</div>
                  <span class="ml-1" v-if="dialog.contactForm.fields.message.required">*</span>
                </div>

                <!-- CONTACT FORM FIELDS -->

                <div style="margin-top: 40px" v-if="dialog.contactForm.isPrivacyInformationEnabled" class="flex flex-row">
                  <div>
                    <vs-checkbox
                      class="configure-dialog-step-six-checkbox"
                      :style="{
                        '--contact-form-darken-background': dialog.contactForm.sendButtonBackgroundColor,
                        '--contact-form-font-color': dialog.contactForm.fontColor
                      }"
                      v-model="hasAgreedToPrivacy"
                    >
                    </vs-checkbox>
                  </div>
                  <div
                    class="message-container-privacy-policy"
                    :style="{
                      '--contact-form-font-color': dialog.contactForm.fontColor
                    }"
                    v-html="dialog.contactForm.privacyInformationHtml"
                  ></div>
                </div>
                <div :style="contactFormSendButtonBackgroundStyle" class="message-container-action-button flex items-center justify-center">
                  {{ isBookingEnabled ? $t('vue.bookMeeting') : $t('vue.sendMessage') }}
                </div>
              </scroll-view>
            </div>
          </div>

          <powered-by />
        </div>

        <div class="flex flex-column relative" style="margin-left: auto" v-if="showSendMessagePreview">
          <div style="position: absolute; right: 20px; top: 20px; cursor: pointer">
            <close-icon :width="16" :height="16"></close-icon>
          </div>
          <div class="flex justify-center items-center" :style="contactFormFeedbackStyle">
            <div class="feedback-message-container">
              <div class="feedback-message-container-title">
                {{ dialog.contactForm.sendMessageTitle }}
              </div>
              <div class="mt-4 feedback-message-container-copy">
                {{ dialog.contactForm.sendMessageCopy }}
              </div>
            </div>
          </div>

          <powered-by />
        </div>

        <div class="flex flex-column relative" style="margin-left: auto" v-if="showBookingSlotPreview">
          <div style="position: absolute; right: 20px; top: 20px; cursor: pointer">
            <close-icon :width="16" :height="16"></close-icon>
          </div>
          <div class="flex justify-center items-center" :style="contactFormFeedbackStyle">
            <div class="feedback-message-container">
              <div class="feedback-message-container-title">
                {{ dialog.contactForm.bookingSlotTitle }}
              </div>
              <div class="mt-4feedback-message-container-copy">
                {{ dialog.contactForm.bookingSlotCopy }}
              </div>
            </div>
          </div>

          <powered-by />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import { mapGetters } from 'vuex'

import vSelect from 'vue-select'

import CloseIcon from '../icons/CloseIcon.vue'
import SwitchOnIcon from '../icons/SwitchOnIcon.vue'
import SwitchOffIcon from '../icons/SwitchOffIcon.vue'
import MessageSquareIcon from '../icons/MessageSquareIcon.vue'
import CalendarIcon from '../icons/CalendarIcon.vue'
import VerticalPhoneIcon from '@/views/campaigns/visitor/icons/VerticalPhoneIcon.vue'
import UserIcon from '../icons/UserIcon.vue'
import MailIcon from '../icons/MailIcon.vue'
import ToggleSwitchIcon from '../icons/ToggleSwitchIcon.vue'
import PoweredBy from '@/components/dialog/PoweredBy.vue'
import SmallLockIcon from '@/components/icons/SmallLockIcon.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'

export default {
  name: 'ContactForm',
  components: {
    quillEditor,
    ScrollView,
    vSelect,
    CloseIcon,
    SwitchOnIcon,
    SwitchOffIcon,
    MessageSquareIcon,
    CalendarIcon,
    VerticalPhoneIcon,
    UserIcon,
    MailIcon,
    ToggleSwitchIcon,
    PoweredBy,
    SmallLockIcon,
    InfoIcon
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      hasWhiteLabel: 'hasWhiteLabel',
      company: 'company',
      dialog: 'campaign'
    }),
    HAS_SCHEDULING_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('scheduling') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    contactFormBackgroundStyle() {
      const backgroundStyle = {
        width: '280px',
        height: '586px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '900px 586px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        backgroundPosition: '-200px 0px'
      }

      return backgroundStyle
    },
    contactFormSendButtonBackgroundStyle() {
      return {
        marginTop: '10px',
        backgroundColor: this.contactFormSendButtonBackgroundColor
      }
    },
    closeButton() {
      return {
        backgroundColor: this.contactFormSendButtonBackgroundColor
      }
    },
    contactFormSendButtonBackgroundColor() {
      return this.lightenDarkenColor(this.dialog.contactForm.backgroundColor, -16)
    },
    contactFormStyle() {
      return {
        width: '100%',
        backgroundColor: this.dialog.contactForm.backgroundColor,
        color: this.dialog.contactForm.fontColor,
        '--contact-form-darken-background': this.contactFormSendButtonBackgroundColor,
        borderRadius: this.hasWhiteLabel ? '13px' : '13px 13px 0 0'
      }
    },
    contactFormHeight() {
      let heightToReduce = 0
      if (!this.dialog.contactForm.fields.name.enabled) heightToReduce += 45
      if (!this.dialog.contactForm.fields.email.enabled) heightToReduce += 45
      if (!this.dialog.contactForm.fields.phone.enabled) heightToReduce += 45
      if (!this.dialog.contactForm.fields.message.enabled) heightToReduce += 100
      const totalHeight = 642 - heightToReduce
      return `${totalHeight}px`
    },
    contactFormFeedbackStyle() {
      return {
        width: '280px',
        height: '586px',
        backgroundColor: this.dialog.contactForm.backgroundColor,
        color: this.dialog.contactForm.fontColor,
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        backgroundPosition: '-280px 0px'
      }
    }
  },
  async mounted() {
    if (!this.HAS_SCHEDULING_ACCESS) {
      this.dialog.contactForm.isBookingEnabled = false
    }

    this.dialog.contactForm.sendButtonBackgroundColor = this.contactFormSendButtonBackgroundColor
    if (this.activeUserInfo && this.activeUserInfo.company) {
      const bookableAppointmentsRef = await this.$db.collection('company').doc(this.activeUserInfo.company).collection('appointments').get()
      bookableAppointmentsRef.forEach((ref) => {
        const appointment = ref.data()
        this.appointments.push({ label: appointment.name, value: ref.id })
      })

      if (this.dialog.contactForm.selectedAppointment) {
        this.selectedAppointment = this.appointments.find((x) => x.value === this.dialog.contactForm.selectedAppointment)
      }
    }
  },
  data() {
    return {
      logo: require('@/assets/images/logo/logo.svg'),
      appointments: [],
      selectedAppointment: { label: '', value: '' },
      isBookingEnabled: false,
      showContactFormPreview: true,
      showSendMessagePreview: false,
      showBookingSlotPreview: false,
      hasAgreedToPrivacy: false,
      editorOption: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'link']]
        }
      }
    }
  },
  watch: {
    'dialog.contactForm.backgroundColor'() {
      this.dialog.contactForm.sendButtonBackgroundColor = this.contactFormSendButtonBackgroundColor
    },
    selectedAppointment() {
      if (this.selectedAppointment) {
        this.dialog.contactForm.selectedAppointment = this.selectedAppointment.value
      }
    },
    showContactFormPreview() {
      if (this.showContactFormPreview) {
        this.showSendMessagePreview = false
        this.showBookingSlotPreview = false
      }
    },
    showSendMessagePreview() {
      if (this.showSendMessagePreview) {
        this.showContactFormPreview = false
        this.showBookingSlotPreview = false
      }
      if (!this.showSendMessagePreview && !this.showBookingSlotPreview) {
        this.showContactFormPreview = true
      }
    },
    showBookingSlotPreview() {
      if (this.showBookingSlotPreview) {
        this.showContactFormPreview = false
        this.showSendMessagePreview = false
      }
      if (!this.showSendMessagePreview && !this.showBookingSlotPreview) {
        this.showContactFormPreview = true
      }
    },
    'dialog.contactForm.isBookingEnabled'() {
      if (this.dialog.contactForm.isBookingEnabled) {
        this.dialog.contactForm.fields.name.enabled = true
        this.dialog.contactForm.fields.name.required = true

        this.dialog.contactForm.fields.email.enabled = true
        this.dialog.contactForm.fields.email.required = true

        this.dialog.contactForm.fields.date.enabled = true
        this.dialog.contactForm.fields.date.required = true

        this.dialog.contactForm.fields.message.enabled = true
        this.dialog.contactForm.fields.message.required = true
      }
    },
    'dialog.contactForm.fields.name.enabled'() {
      if (!this.dialog.contactForm.fields.name.enabled) {
        this.dialog.contactForm.fields.name.required = false
      }
    },
    'dialog.contactForm.fields.phone.enabled'() {
      if (!this.dialog.contactForm.fields.phone.enabled) {
        this.dialog.contactForm.fields.phone.required = false
      }
    },

    'dialog.contactForm.fields.email.enabled'() {
      if (!this.dialog.contactForm.fields.email.enabled) {
        this.dialog.contactForm.fields.email.required = false
      }
    },
    'dialog.contactForm.fields.date.enabled'() {
      if (!this.dialog.contactForm.fields.date.enabled) {
        this.dialog.contactForm.fields.date.required = false
      }
    },
    'dialog.contactForm.fields.message.enabled'() {
      if (!this.dialog.contactForm.fields.message.enabled) {
        this.dialog.contactForm.fields.message.required = false
      }
    },
    'dialog.contactForm.privacyInformationHtml'() {
      this.dialog.contactForm.privacyInformationHtml = this.decodeHTML()
    }
  },
  methods: {
    decodeHTML() {
      const txt = document.createElement('textarea')
      txt.innerHTML = this.dialog.contactForm.privacyInformationHtml
      return txt.value
    },
    lightenDarkenColor(color, percent) {
      if (color[0] === '#') {
        color = color.slice(1)
      }

      const num = parseInt(color, 16)
      const amt = Math.round(2.55 * percent)
      const R = (num >> 16) + amt
      const B = ((num >> 8) & 0x00ff) + amt
      const G = (num & 0x0000ff) + amt
      /* eslint-disable no-mixed-operators */
      return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255))
        .toString(16)
        .slice(1)}`
    }
  }
}
</script>
<style lang="scss">
.configure-dialog-step-six {
  .contact-form-scroll-area {
    height: 550px;
  }

  .scrollbar-thumb {
    background: var(--contact-form-darken-background) !important;
  }

  &-checkbox input:checked + .vs-checkbox {
    border-width: 2px !important;
    border-style: solid !important;
    border-color: var(--contact-form-font-color) !important;
    background: var(--contact-form-darken-background) !important;
  }

  .ql-editor {
    min-height: 100px;
    font-family: 'Lato';
  }

  .headline {
    color: #262629;
    font-weight: 600;
    font-size: 18px;
  }

  .close-box {
    width: 35px;
    height: 35px;
    background-color: rgba(var(--vs-secondary), 1);
    border-radius: 4px;
  }

  .vs-switch {
    min-width: 45px !important;
  }

  .vs-checkbox {
    width: 20px;
    height: 20px;
  }

  .vs-icon {
    font-size: 0.7rem;
  }

  .pa-color-picker {
    width: 310px;
    min-width: 310px;
    max-width: 100%;
    height: 40px;
    cursor: pointer;
  }

  .flex-view {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .contact-form-fields {
    &-item {
      min-width: 310px;
      height: 45px;
      margin: 5px;
      border: 1px solid #cbcbcb;
      border-radius: 6px;
      align-items: center;
      padding: 8px;

      &-required {
        text-transform: lowercase;
      }
    }
  }

  .feedback-message-container {
    width: 100%;
    height: auto;

    margin-top: 200px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;

    padding: 10px;
    font-size: 14px;
    line-height: 18px;

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
    }

    &-copy {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .message-container {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 14px;
    line-height: 18px;

    &-privacy-policy {
      color: var(--contact-form-font-color);

      a {
        color: var(--contact-form-font-color);
        text-decoration: underline;
      }
    }

    &-title {
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      padding-top: 20px;
    }

    &-copy {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      text-align: justify;
    }

    &-upper {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
      text-align: center;
    }

    &-fields {
      display: flex;
      height: 45px;
      background-color: rgba(230, 236, 241, 0.6);
      border-radius: 6px;
      padding-top: 14px;
      padding-left: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    .ht-100 {
      height: 100px;
    }

    &-action-button {
      height: 35px;
      border-radius: 6px;

      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      cursor: pointer;
    }

    &-action-button:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    }

    &-cancel-button {
      height: 35px;
      background: transparent;
      border-radius: 6px;

      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      cursor: pointer;
    }

    &-cancel-button:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }
  }

  &__select-link-message {
    padding: 10px;
    color: rgb(59, 134, 247);
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    &__icon {
      margin-right: 5px;
    }
  }
}
</style>
