<template>
  <div class="configure-dialog-step-three" :key="tabKey">
    <div class="flex-box-column-start">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont">{{ $t('vue.customiseQRConnect') }}</div>
        </vs-col>
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="translation--container">
            <div class="mr-4"><info-icon fill="#01AAF4" color="#fff" :width="20" :height="20" /></div>
            <div v-html="$t('info.translation')"></div>
          </div>
        </vs-col>
      </div>
    </div>
    <div class="flex flex-row">
      <form class="flex flex-column w-full mt-2 mr-4" style="max-width: 630px">
        <display-options :videoKey="videoKey" />
        <div class="flex flex-row">
          <div class="flex flex-column">
            <div class="mb-2">{{ $t('vue.displayContactFormAfterSeconds') }}</div>
            <div class="flex w-full">
              <div class="w-full">
                <vs-input
                  name="contactFormTimeout"
                  :data-vv-as="' '"
                  type="number"
                  v-validate="'min_value:15|max_value:60'"
                  v-model="dialog.contactFormTimeout"
                />
                <span class="text-danger text-sm" v-show="errors.has('contactFormTimeout')">{{ errors.first('contactFormTimeout') }}</span>
              </div>
              <div class="ml-2">
                <vx-tooltip :text="$t('info.afterHowManySecondContactFormDisplay')" position="top">
                  <img :src="infoImage" />
                </vx-tooltip>
              </div>
            </div>
          </div>
        </div>

        <div v-if="dialog.type === 'popup'" class="vx-col w-full mt-5">
          <vs-input class="w-full" :label="$t('inputs.title')" v-model="dialog.title" maxlength="70" />
        </div>
        <div class="vs-col w-full mt-5" v-if="dialog.type === 'popup'">
          <vs-textarea counter="300" height="100px" :label="$t('vue.popupText')" :counter-danger.sync="counterDangerPopupText" v-model="dialog.text" />
        </div>
        <div class="vs-col w-full mt-5" v-if="dialog.type === 'button'">
          <vs-textarea counter="300" height="100px" :label="$t('vue.speechBubbleText')" :counter-danger.sync="counterDangerPopupText" v-model="dialog.text" />
        </div>
        <div class="vs-col w-full" v-if="dialog.type === 'button'">
          <vs-input :label="$t('inputs.callToAction')" class="w-full" v-model="dialog.confirmButtonText" />
        </div>
        <div class="vs-col w-full md:w-1/12" v-if="dialog.type === 'popup'">&nbsp;</div>
        <div class="vs-col w-full md:w-5/12" v-if="dialog.type === 'popup'">
          <vs-input :label="$t('inputs.rejectButton')" class="w-full" v-model="dialog.cancelButtonText" />
        </div>
        <div class="mt-4 step-headline">
          {{ $t('vue.step2WelcomeScreen') }}
        </div>
        <div class="mt-2 mb-2">{{ $t('vue.step2WelcomeText') }}</div>
        <div class="w-full">
          <quill-editor v-model="dialog.textStep2" ref="quillEditorA" :options="editorOption"> </quill-editor>
        </div>

        <div class="flex flex-row justify-center items-center mt-4">
          <div @click="toggleMoreInformation">
            <toggle-switch-icon :enabled="dialog.isMoreInformationEnabled" :width="40" :height="40" />
          </div>

          <div class="mr-2 mt-1">{{ $t('vue.customizableMoreInformation') }}</div>
          <vx-tooltip :text="$t('info.moreInformation')" position="top">
            <img :src="infoImage" />
          </vx-tooltip>
        </div>
        <div v-if="dialog.isMoreInformationEnabled" class="mt-4 w-full">
          <vs-input
            :label="$t('vue.textMoreInformation')"
            class="w-full"
            name="moreInformationButtonText"
            v-validate="'required'"
            v-model="dialog.moreInformationButtonText"
          />
          <span class="text-danger text-sm">{{ errors.first('moreInformationButtonText') }}</span>
        </div>
        <div class="mt-4 w-full">
          <vs-input
            :label="$t('vue.linkMoreInformation')"
            class="w-full"
            name="moreInformationLink"
            v-validate="'required|isURLValid'"
            :disabled="!dialog.isMoreInformationEnabled"
            v-model="dialog.moreInformationLink"
            @blur="checkMoreInformationLink"
          />
          <span v-if="dialog.isMoreInformationEnabled" class="text-danger text-sm">{{ errors.first('moreInformationLink') }}</span>
        </div>

        <div class="mt-4">
          <div class="mt-2">
            <label class="call-to-action">{{ $t('vue.buttonColor') }}</label>
          </div>
          <input class="pa-color-picker" type="color" v-model="dialog.step2ButtonColor" name="confirmButtonColor" />
        </div>
        <div class="mt-4">
          <div class="mt-2">
            <label class="call-to-action">{{ $t('vue.fontColor') }}</label>
          </div>
          <input class="pa-color-picker" type="color" v-model="dialog.step2TextColor" name="step2TextColor" />
        </div>

        <div class="flex flex-column mt-8">
          <div class="flex flex-col logo-image-holder">
            <div class="flex">
              <div class="flex flex-col">
                <div class="logo-image-img-wrapper">
                  <img class="logo-image-img w-full h-full pointer" slot="initial" crossorigin="anonymous" :src="dialog.logoImage || defaultLogoImage" />
                </div>
                <vs-button
                  v-if="dialog.logoImage"
                  class="delete-btn-padding mt-6 ml-auto mr-auto"
                  type="border"
                  color="danger"
                  size="large"
                  @click="removeLogoImage"
                  icon-pack="material-icons"
                  icon="photo"
                  icon-after
                >
                  {{ $t('vue.delete') }}
                </vs-button>
              </div>
              <div>
                <div class="flex items-center logo-image-upload-wrapper">
                  <label for="svg-file-input" class="upload-photo mr-2 sm:mb-0 mb-2 upload p-0 pointer logo-image-upload">
                    <input
                      type="file"
                      id="svg-file-input"
                      style="display: none"
                      accept="image/png, image/jpeg, image/svg+xml"
                      @change="selectLogoImage($event)"
                      @click="onFileInputClick"
                    />
                    <div class="con-upload">
                      <div class="con-img-upload mt-0">
                        <div class="con-input-upload">
                          <span class="text-input" style="color: #000">{{ $t('vue.selectPicture') }}</span>
                          <button disabled="disabled" type="button" title="Upload" class="btn-upload-all vs-upload--button-upload">
                            <i translate="translate" class="material-icons notranslate"> cloud_upload </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </label>
                  <span style="max-width: 116px; padding-left: 3px; font-size: 0.75rem">{{ $t('vue.uploadSVGSmallButtonIconText') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <welcome-screen-preview
        id="text-and-dialog-type"
        :uploadType="dialog.useOneMediaForAllSteps ? 'allMedia' : 'welcomeScreenMedia'"
        :uploadSubtype="dialog.useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'welcomeScreenMedia' : ''"
        :currentStep="currentStep"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import VuePlainClipboard from 'vue-quill-plain-clipboard'
Quill.register('modules/clipboard', VuePlainClipboard, true)

import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import DisplayOptions from '@/components/campaign/DisplayOptions.vue'
import WelcomeScreenPreview from '@/components/campaign/media/WelcomeScreenPreview'
import InfoIcon from '@/components/icons/InfoIcon.vue'

// VeeValidate
import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '../../validations/validationDictionary'

export default {
  components: {
    quillEditor,
    ToggleSwitchIcon,
    DisplayOptions,
    WelcomeScreenPreview,
    InfoIcon
  },
  props: {
    videoKey: {
      type: String,
      required: true
    },
    currentStep: {
      type: Number
    }
  },

  data() {
    return {
      uploadTask: null,
      counterDangerPopupText: false,
      defaultLogoImage: require('@/assets/images/elements/LetsConnectLogo.svg'),
      defaultContactFormTimeout: 60,
      videoURL: null,
      webm: 'video/webm',
      mp4: 'video/mp4',
      editorOption: {
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline', { size: ['small', false, 'large', 'huge'] }], ['link'], [{ color: [] }, { background: [] }], ['clean']]
          }
        }
      },
      tabKey: Math.random().toString(36).substring(2, 15)
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'campaign',
      dialogId: 'campaignId'
    }),
    embedVideoURL() {
      return this.transcodedURL ? this.transcodedURL : this.videoURL ? this.videoURL : this.defaultVideoURL
    },
    step2ButtonColor() {
      return {
        backgroundColor: this.dialog.step2ButtonColor
      }
    },
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    },
    moreInformationColor() {
      return {
        border: `1px solid ${this.dialog.step2ButtonColor}`,
        color: this.dialog.step2ButtonColor
      }
    },
    step2TextColor() {
      return {
        color: this.dialog.step2TextColor
      }
    }
  },
  watch: {
    'dialog.textStep2'() {
      this.dialog.textStep2 = this.decodeHTML()
    }
  },
  beforeDestroy() {
    this.$serverBus.$off('validate-step-3')
    this.$serverBus.$off('update-tab-key')
    this.$serverBus.$off('pa-dialog-video-updated')
    this.$serverBus.$off('pa-dialog-video-removed')
  },
  beforeMount() {
    this.tabKey = Math.random().toString(36).substring(2, 15)
  },
  mounted() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
    if (this.dialog && (this.dialog.contactFormTimeout === 0 || !this.dialog.contactFormTimeout)) {
      this.dialog.contactFormTimeout = this.defaultContactFormTimeout
    }

    if (this.dialog && this.dialog.transcodedURL) {
      this.transcodedURL = this.dialog.transcodedURL
    } else {
      this.transcodedURL = null
    }

    this.$serverBus.$on('validate-step-3', () => {
      this.$validator.validateAll()
    })

    this.$serverBus.$on('pa-dialog-video-updated', ({ videoURL, transcodedURL }) => {
      this.videoURL = videoURL
      this.transcodedURL = transcodedURL
    })

    this.$serverBus.$on('pa-dialog-video-removed', () => {
      this.videoURL = null
      this.transcodedURL = null
    })

    this.$serverBus.$on('update-tab-key', () => {
      this.tabKey = Math.random().toString(36).substring(2, 15)
    })
  },
  methods: {
    decodeHTML() {
      const txt = document.createElement('textarea')
      txt.innerHTML = this.dialog.textStep2
      return txt.value
    },
    toggleMoreInformation() {
      if (this.dialog.isMoreInformationEnabled) {
        this.dialog.moreInformationLink = ''
      }
      this.dialog.isMoreInformationEnabled = !this.dialog.isMoreInformationEnabled
    },
    checkMoreInformationLink() {
      if (!/^https?:\/\//i.test(this.dialog.moreInformationLink)) {
        this.dialog.moreInformationLink = `https://${this.dialog.moreInformationLink}`
      }
    },
    async removeLogoImage() {
      this.$vs.loading()

      const storageService = this.$firebase.storage()

      try {
        const dialogRef = await this.$db.collection('campaigns').doc(this.dialogId)

        await dialogRef.set({ logoImage: null }, { merge: true })

        await storageService.refFromURL(this.dialog.logoImage).delete()
        this.$emit('dialog-updated', {
          ...this.dialog,
          logoImage: null
        })
      } catch (err) {
        let message = err.message

        if (err.code === 'storage/object-not-found') {
          message = this.$i18n.t('vue.objectDoesNotExists')
        }
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      this.$vs.loading.close()
      this.$emit('dialog-updated', {
        ...this.dialog,
        logoImage: null
      })
    },
    async selectLogoImage(event) {
      const { files } = event.target

      if (files && files.length) {
        await this.uploadLogoImage(files)
      }
    },
    async uploadLogoImage(files) {
      const vm = this
      vm.$vs.loading()

      const storagePath = `images/campaigns/logo/${this.dialogId}`

      try {
        const storageService = this.$firebase.storage()
        const storageRef = storageService.ref()

        let uploadTask = null

        uploadTask = storageRef.child(storagePath).put(files[0])
        this.uploadTask = true

        uploadTask.on(
          this.$firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            this.progressUpload = progress
          },
          function (error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                vm.text = 'fields.storage/unauthorized'
                break
              case 'storage/canceled':
                // User canceled the upload
                vm.text = 'fields.storage/canceled'
                break
              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                vm.text = 'fields.storage/unknown'
                break
            }

            vm.$vs.notify({
              time: 8800,
              title: vm.$i18n.t('vue.error'),
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })

            vm.uploadTask = false
          },
          async () => {
            // Upload completed successfully, now we can get the download URL
            this.uploadTask = false
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

            const dialogRef = await this.$db.collection('campaigns').doc(this.dialogId)

            dialogRef.set({ logoImage: downloadURL }, { merge: true })

            this.$emit('dialog-updated', {
              ...this.dialog,
              logoImage: downloadURL
            })

            this.$vs.loading.close()

            this.$vs.notify({
              time: 8800,
              title: vm.$i18n.t('vue.success'),
              text: vm.$i18n.t('vue.photoSavedSuccessfully'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
          }
        )
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },
    onFileInputClick(event) {
      /* makes it possible to send the same file twice,
      one after another
      (the browser caches the change event for performance purposes) */
      event.target.value = ''
    }
  }
}
</script>

<style lang="scss">
.translation {
  &--container {
    background-color: #f4fbff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 54px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border-radius: 6px;
    padding: 5px 15px;
    margin: 10px 0px;
  }
  &--link:before {
    content: '';
    display: inline-block;
    background: url('/img/icons/globe.svg') no-repeat;
    width: 16px;
    height: 16px;
    margin: 0 4px 0 0;
  }
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-size-small {
  font-size: 0.75em;
}
.ql-showHtml:after {
  content: '[source]';
}
button.ql-showHtml {
  width: 100% !important;
}
.configure-dialog-step-three {
  .flex-view {
    display: flex;
    @media only screen and (max-width: 1920px) {
      flex-direction: column !important;
    }
    @media only screen and (min-width: 1921px) {
      flex-direction: row !important;
    }
  }

  .pa-button-container {
    min-width: 430px;
  }

  .step-headline {
    color: #262629;
    font-weight: 600;
    font-size: 18px;
  }
  .quill-editor {
    height: 150px;
    .ql-toolbar {
      max-height: 42px;
    }
    .ql-container {
      max-height: 108px;
      .ql-editor {
        font-family: 'Lato';
      }
    }
  }

  .flex-align-right {
    margin-left: auto;
  }
  .pa-color-picker {
    width: 310px;
    min-width: 310px;
    max-width: 100%;
    height: 40px;
    cursor: pointer;
  }
  .preview-button-card {
    &-right {
      height: 566px;
      width: 280px;
      position: relative;
      border-radius: 13px;

      &-image {
        justify-content: center;
        display: flex;
        flex-direction: row;
        border-radius: 13px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
          border-radius: 13px;
        }
        img {
          min-width: 280px;
          min-height: 566px;
          border-radius: 13px;
          object-fit: cover;
        }
      }

      &-video {
        justify-content: center;
        display: flex;
        flex-direction: row;
        border-radius: 13px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
          border-radius: 13px;
        }
      }

      &-content {
        position: absolute;
        bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-step2 {
          padding: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          display: inline-block !important;
          margin-bottom: 20px;
        }

        &-action-button {
          width: 75px;
          height: 75px;
          background: #3b86f7;
          border-radius: 13px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;

          color: #fff;
          cursor: pointer;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        }
        &-action-button:hover {
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
        }

        &-more-information {
          width: 253px;
          height: 35px;
          left: 18px;
          top: 708px;
          border: 1px solid #3b86f7;
          box-sizing: border-box;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          border-radius: 6px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          background: linear-gradient(179.98deg, rgba(0, 0, 0, 0) 0.27%, rgba(0, 0, 0, 0.6) 99.99%);
          cursor: pointer;
        }
        &-more-information:hover {
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  &-image-bk {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      border-radius: 13px;
    }
  }
  &-video-bk {
    position: relative;
    height: 180px;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      border-radius: 13px;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &-image-cover {
    object-fit: cover;
    width: 280px !important;
    height: 180px !important;
    border-radius: 13px !important;
  }
  .button-card-container {
    max-width: 280px !important;
    max-height: 180px !important;
    object-fit: cover;
    border-radius: 13px;
    position: relative;

    video {
      border-radius: 5px;
      object-fit: cover;
      width: 280px;
      height: 180px;
      position: relative;
      top: 0px;
      left: 0px;
    }

    &-online-container {
      z-index: 999;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 2px;
      left: 5px;
      &-online {
        width: 6px;
        height: 6px;
        background-color: #1c8439;
        border-radius: 20px;
      }
    }
    &-close-container {
      z-index: 999;
      width: 18px;
      height: 18px;
      background-color: rgba(var(--vs-secondary), 1);
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 3px;
    }
  }
}

.logo-image {
  &-holder {
    @media (max-width: 991px) {
      align-items: center;
      justify-content: center;
    }
  }
  &-img-wrapper {
    border: 1px solid #e6ecf1;
    box-sizing: border-box;
    border-radius: 5px;
    min-width: 125px;
    width: 125px;
    height: 100px;
    padding: 4px 11px;
  }
  &-upload {
    min-width: 125px;
    margin-right: 15px;
    margin-left: 15px;
    @media (max-width: 1440px) {
      margin-bottom: 1.25rem !important;
    }
    &-wrapper {
      @media (max-width: 1440px) {
        flex-direction: column;
      }
    }
  }
}
</style>
