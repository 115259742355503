var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configure-dialog-step-five" }, [
    _c("div", { staticClass: "flex-box-column-start" }, [
      _c(
        "div",
        { staticClass: "vx-row w-full" },
        [
          _c(
            "vs-col",
            {
              staticClass: "flex-box-row-start w-full",
              attrs: { "vs-w": "12" },
            },
            [
              _c("div", { staticClass: "headingfont mr-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.endScreenOnly"))),
              ]),
              _c(
                "vx-tooltip",
                {
                  attrs: {
                    text: _vm.$t("info.getFeedbackFromCustomer"),
                    position: "top",
                  },
                },
                [_c("img", { attrs: { src: _vm.infoImage } })]
              ),
            ],
            1
          ),
          _c(
            "vs-col",
            {
              staticClass: "flex-box-row-start w-full",
              attrs: { "vs-w": "12" },
            },
            [
              _c("div", { staticClass: "translation--container" }, [
                _c(
                  "div",
                  { staticClass: "mr-4" },
                  [
                    _c("info-icon", {
                      attrs: {
                        fill: "#01AAF4",
                        color: "#fff",
                        width: 20,
                        height: 20,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.$t("info.translation")) },
                }),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "flex-view" }, [
      _c(
        "div",
        {
          staticClass: "mt-5 mr-6 flex flex-column flex-grow",
          staticStyle: { "max-width": "630px" },
        },
        [
          _c("div", [_vm._v(_vm._s(_vm.$t("vue.sessionEndScreenLabelTitle")))]),
          _c("vs-input", {
            staticClass: "w-full mr-2",
            model: {
              value: _vm.dialog.sessionEndScreen.titleClosingMessage,
              callback: function ($$v) {
                _vm.$set(
                  _vm.dialog.sessionEndScreen,
                  "titleClosingMessage",
                  $$v
                )
              },
              expression: "dialog.sessionEndScreen.titleClosingMessage",
            },
          }),
          _c("div", { staticClass: "mt-4" }, [
            _vm._v(_vm._s(_vm.$t("vue.sessionEndScreenLabelCopy"))),
          ]),
          _c("vs-textarea", {
            staticClass: "w-full p-1",
            attrs: { counter: "300", height: "100px" },
            model: {
              value: _vm.dialog.sessionEndScreen.copyClosingMessage,
              callback: function ($$v) {
                _vm.$set(_vm.dialog.sessionEndScreen, "copyClosingMessage", $$v)
              },
              expression: "dialog.sessionEndScreen.copyClosingMessage",
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "feedback-form-colors flex flex-row flex-wrap justify-between w-full",
            },
            [
              _c("div", { staticClass: "flex flex-column flex-grow" }, [
                _c("div", [_vm._v(_vm._s(_vm.$t("vue.backgroundColor")))]),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.dialog.sessionEndScreen.backgroundColor,
                        expression: "dialog.sessionEndScreen.backgroundColor",
                      },
                    ],
                    staticClass: "pa-color-picker",
                    attrs: {
                      type: "color",
                      name: "sessionEndScreenConfirmButtonColor",
                    },
                    domProps: {
                      value: _vm.dialog.sessionEndScreen.backgroundColor,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.dialog.sessionEndScreen,
                          "backgroundColor",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "flex flex-column flex-grow" }, [
                _c("div", [_vm._v(_vm._s(_vm.$t("vue.fontColor")))]),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.dialog.sessionEndScreen.fontColor,
                        expression: "dialog.sessionEndScreen.fontColor",
                      },
                    ],
                    staticClass: "pa-color-picker",
                    attrs: { type: "color", name: "sessionEndScreenFontColor" },
                    domProps: { value: _vm.dialog.sessionEndScreen.fontColor },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.dialog.sessionEndScreen,
                          "fontColor",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "flex flex-row items-center" },
            [
              _c(
                "div",
                { on: { click: _vm.toggleShowFeedbackForm } },
                [
                  _c("toggle-switch-icon", {
                    attrs: {
                      enabled: _vm.dialog.isShowFeedbackForm,
                      width: 40,
                      height: 40,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mx-2", staticStyle: { "font-size": "1.2rem" } },
                [_vm._v(_vm._s(_vm.$t("vue.feedbackForm")))]
              ),
              _vm.HAS_ACCESS
                ? _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("vue.feedbackFormTooltip"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImage } })]
                  )
                : _c(
                    "vx-tooltip",
                    {
                      staticStyle: { height: "20px" },
                      attrs: {
                        text: _vm.$t("upgrade-messages.locked-feature"),
                        position: "top",
                      },
                    },
                    [_c("small-lock-icon")],
                    1
                  ),
            ],
            1
          ),
          _vm.dialog.isShowFeedbackForm
            ? [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    label: _vm.$t("vue.feedbackFormQuestion") + " 1",
                    placeholder: _vm.$t("vue.feedbackFormQuestion") + " 1",
                    name: "questionFirst",
                    "data-vv-as": _vm.$t("vue.feedbackFormQuestion") + " 1",
                    "validate-on": "blur",
                  },
                  model: {
                    value: _vm.dialog.feedbackFormQuestionFirst,
                    callback: function ($$v) {
                      _vm.$set(_vm.dialog, "feedbackFormQuestionFirst", $$v)
                    },
                    expression: "dialog.feedbackFormQuestionFirst",
                  },
                }),
                _vm.dialog.isShowFeedbackForm
                  ? _c("span", { staticClass: "text-danger text-sm mt-2" }, [
                      _vm._v(_vm._s(_vm.errors.first("questionFirst"))),
                    ])
                  : _vm._e(),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full mt-3",
                  attrs: {
                    label: _vm.$t("vue.feedbackFormQuestion") + " 2",
                    placeholder: _vm.$t("vue.feedbackFormQuestion") + " 2",
                    name: "questionSecond",
                    "data-vv-as": _vm.$t("vue.feedbackFormQuestion") + " 2",
                    "validate-on": "blur",
                  },
                  model: {
                    value: _vm.dialog.feedbackFormQuestionSecond,
                    callback: function ($$v) {
                      _vm.$set(_vm.dialog, "feedbackFormQuestionSecond", $$v)
                    },
                    expression: "dialog.feedbackFormQuestionSecond",
                  },
                }),
                _vm.dialog.isShowFeedbackForm
                  ? _c("span", { staticClass: "text-danger text-sm mt-2" }, [
                      _vm._v(_vm._s(_vm.errors.first("questionSecond"))),
                    ])
                  : _vm._e(),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "w-full mt-3",
                  attrs: {
                    label: _vm.$t("vue.feedbackFormQuestion") + " 3",
                    placeholder: _vm.$t("vue.feedbackFormQuestion") + " 3",
                    name: "questionThird",
                    "data-vv-as": _vm.$t("vue.feedbackFormQuestion") + " 3",
                    "validate-on": "blur",
                  },
                  model: {
                    value: _vm.dialog.feedbackFormQuestionThird,
                    callback: function ($$v) {
                      _vm.$set(_vm.dialog, "feedbackFormQuestionThird", $$v)
                    },
                    expression: "dialog.feedbackFormQuestionThird",
                  },
                }),
                _vm.dialog.isShowFeedbackForm
                  ? _c("span", { staticClass: "text-danger text-sm mt-2" }, [
                      _vm._v(_vm._s(_vm.errors.first("questionThird"))),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "flex flex-row items-center" }, [
                  _c(
                    "div",
                    { on: { click: _vm.toggleSendFeedbackViaMessage } },
                    [
                      _c("toggle-switch-icon", {
                        attrs: {
                          enabled: _vm.dialog.isSendFeedbackViaMessage,
                          width: 40,
                          height: 40,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mx-2",
                      staticStyle: { "font-size": "1.2rem" },
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.feedbackSendViaMessage")))]
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-column relative",
          staticStyle: { "margin-left": "auto" },
        },
        [
          _c(
            "div",
            {
              staticClass: "mb-3",
              staticStyle: { "align-self": "flex-start" },
            },
            [_vm._v(_vm._s(_vm.$t("vue.preview")))]
          ),
          _c(
            "div",
            {
              staticClass: "flex justify-center items-center relative",
              style: _vm.feedbackFormStyle,
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    right: "20px",
                    top: "20px",
                    cursor: "pointer",
                  },
                },
                [_c("close-icon", { attrs: { width: 16, height: 16 } })],
                1
              ),
              _c("div", { staticClass: "endscreen-feedback__container" }, [
                _c(
                  "div",
                  { staticClass: "endscreen-feedback__container-head" },
                  [
                    _c("div", { staticClass: "endscreen-feedback__title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.dialog.sessionEndScreen.titleClosingMessage
                          ) +
                          "\n            "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-4 endscreen-feedback__subtitle" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.dialog.sessionEndScreen.copyClosingMessage
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm.dialog.isShowFeedbackForm
                  ? _c(
                      "div",
                      { staticClass: "endscreen-feedback__form" },
                      [
                        _vm.dialog.feedbackFormQuestionFirst
                          ? _c(
                              "div",
                              { staticClass: "endscreen-feedback__field" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "endscreen-feedback__field-title",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dialog.feedbackFormQuestionFirst
                                      )
                                    ),
                                  ]
                                ),
                                _c("rating-stars", {
                                  attrs: {
                                    grade: 5,
                                    maxStars: 5,
                                    disabled: true,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dialog.feedbackFormQuestionSecond
                          ? _c(
                              "div",
                              { staticClass: "endscreen-feedback__field" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "endscreen-feedback__field-title",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dialog.feedbackFormQuestionSecond
                                      )
                                    ),
                                  ]
                                ),
                                _c("rating-stars", {
                                  attrs: {
                                    grade: 0,
                                    maxStars: 5,
                                    disabled: true,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dialog.feedbackFormQuestionThird
                          ? _c(
                              "div",
                              { staticClass: "endscreen-feedback__field" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "endscreen-feedback__field-title",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dialog.feedbackFormQuestionThird
                                      )
                                    ),
                                  ]
                                ),
                                _c("rating-stars", {
                                  attrs: {
                                    grade: 0,
                                    maxStars: 5,
                                    disabled: true,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.dialog.isSendFeedbackViaMessage
                          ? _c(
                              "div",
                              { staticClass: "endscreen-feedback__message" },
                              [
                                _c("div", { staticClass: "upper-case" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("inputs.message")) + "..."
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.dialog.isShowFeedbackForm &&
                        _vm.isAnyFeedbackOptionActive
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "endscreen-feedback__action-button flex items-center justify-center",
                                  style:
                                    _vm.feedbackFormSendButtonBackgroundStyle,
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("vue.sendMessage")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "endscreen-feedback__cancel-button mt-4 flex items-center justify-center",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("vue.close")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c("powered-by"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }