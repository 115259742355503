<template>
  <div class="configure-dialog-step-five">
    <div class="flex-box-column-start">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont mr-2">{{ $t('vue.endScreenOnly') }}</div>
          <vx-tooltip :text="$t('info.getFeedbackFromCustomer')" position="top">
            <img :src="infoImage" />
          </vx-tooltip>
        </vs-col>

        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="translation--container">
            <div class="mr-4"><info-icon fill="#01AAF4" color="#fff" :width="20" :height="20" /></div>
            <div v-html="$t('info.translation')"></div>
          </div>
        </vs-col>
      </div>
    </div>
    <div class="flex-view">
      <div class="mt-5 mr-6 flex flex-column flex-grow" style="max-width: 630px">
        <div>{{ $t('vue.sessionEndScreenLabelTitle') }}</div>
        <vs-input class="w-full mr-2" v-model="dialog.sessionEndScreen.titleClosingMessage" />
        <div class="mt-4">{{ $t('vue.sessionEndScreenLabelCopy') }}</div>
        <vs-textarea counter="300" height="100px" v-model="dialog.sessionEndScreen.copyClosingMessage" class="w-full p-1" />

        <div class="feedback-form-colors flex flex-row flex-wrap justify-between w-full">
          <div class="flex flex-column flex-grow">
            <div>{{ $t('vue.backgroundColor') }}</div>
            <div>
              <input class="pa-color-picker" type="color" v-model="dialog.sessionEndScreen.backgroundColor" name="sessionEndScreenConfirmButtonColor" />
            </div>
          </div>
          <div class="flex flex-column flex-grow">
            <div>{{ $t('vue.fontColor') }}</div>
            <div><input class="pa-color-picker" type="color" v-model="dialog.sessionEndScreen.fontColor" name="sessionEndScreenFontColor" /></div>
          </div>
        </div>
        <div class="flex flex-row items-center">
          <div @click="toggleShowFeedbackForm">
            <toggle-switch-icon :enabled="dialog.isShowFeedbackForm" :width="40" :height="40" />
          </div>
          <div class="mx-2" style="font-size: 1.2rem">{{ $t('vue.feedbackForm') }}</div>
          <vx-tooltip v-if="HAS_ACCESS" :text="$t('vue.feedbackFormTooltip')" position="top">
            <img :src="infoImage" />
          </vx-tooltip>
          <vx-tooltip v-else :text="$t('upgrade-messages.locked-feature')" position="top" style="height: 20px">
            <small-lock-icon />
          </vx-tooltip>
        </div>
        <template v-if="dialog.isShowFeedbackForm">
          <vs-input
            :label="`${$t('vue.feedbackFormQuestion')} 1`"
            :placeholder="`${$t('vue.feedbackFormQuestion')} 1`"
            class="w-full"
            name="questionFirst"
            v-validate="'required'"
            :data-vv-as="`${$t('vue.feedbackFormQuestion')} 1`"
            validate-on="blur"
            v-model="dialog.feedbackFormQuestionFirst"
          />
          <span v-if="dialog.isShowFeedbackForm" class="text-danger text-sm mt-2">{{ errors.first('questionFirst') }}</span>
          <vs-input
            :label="`${$t('vue.feedbackFormQuestion')} 2`"
            :placeholder="`${$t('vue.feedbackFormQuestion')} 2`"
            class="w-full mt-3"
            name="questionSecond"
            v-validate="'required'"
            :data-vv-as="`${$t('vue.feedbackFormQuestion')} 2`"
            validate-on="blur"
            v-model="dialog.feedbackFormQuestionSecond"
          />
          <span v-if="dialog.isShowFeedbackForm" class="text-danger text-sm mt-2">{{ errors.first('questionSecond') }}</span>
          <vs-input
            :label="`${$t('vue.feedbackFormQuestion')} 3`"
            :placeholder="`${$t('vue.feedbackFormQuestion')} 3`"
            class="w-full mt-3"
            name="questionThird"
            v-validate="'required'"
            :data-vv-as="`${$t('vue.feedbackFormQuestion')} 3`"
            validate-on="blur"
            v-model="dialog.feedbackFormQuestionThird"
          />
          <span v-if="dialog.isShowFeedbackForm" class="text-danger text-sm mt-2">{{ errors.first('questionThird') }}</span>
          <div class="flex flex-row items-center">
            <div @click="toggleSendFeedbackViaMessage">
              <toggle-switch-icon :enabled="dialog.isSendFeedbackViaMessage" :width="40" :height="40" />
            </div>
            <div class="mx-2" style="font-size: 1.2rem">{{ $t('vue.feedbackSendViaMessage') }}</div>
          </div>
        </template>
      </div>
      <div class="flex flex-column relative" style="margin-left: auto">
        <div class="mb-3" style="align-self: flex-start">{{ $t('vue.preview') }}</div>
        <div class="flex justify-center items-center relative" :style="feedbackFormStyle">
          <div style="position: absolute; right: 20px; top: 20px; cursor: pointer">
            <close-icon :width="16" :height="16"></close-icon>
          </div>
          <div class="endscreen-feedback__container">
            <div class="endscreen-feedback__container-head">
              <div class="endscreen-feedback__title">
                {{ dialog.sessionEndScreen.titleClosingMessage }}
              </div>
              <div class="mt-4 endscreen-feedback__subtitle">
                <span>{{ dialog.sessionEndScreen.copyClosingMessage }}</span>
              </div>
            </div>
            <div class="endscreen-feedback__form" v-if="dialog.isShowFeedbackForm">
              <div class="endscreen-feedback__field" v-if="dialog.feedbackFormQuestionFirst">
                <div class="endscreen-feedback__field-title">{{ dialog.feedbackFormQuestionFirst }}</div>
                <rating-stars :grade="5" :maxStars="5" :disabled="true" />
              </div>
              <div class="endscreen-feedback__field" v-if="dialog.feedbackFormQuestionSecond">
                <div class="endscreen-feedback__field-title">{{ dialog.feedbackFormQuestionSecond }}</div>
                <rating-stars :grade="0" :maxStars="5" :disabled="true" />
              </div>
              <div class="endscreen-feedback__field" v-if="dialog.feedbackFormQuestionThird">
                <div class="endscreen-feedback__field-title">{{ dialog.feedbackFormQuestionThird }}</div>
                <rating-stars :grade="0" :maxStars="5" :disabled="true" />
              </div>

              <div class="endscreen-feedback__message" v-if="dialog.isSendFeedbackViaMessage">
                <div class="upper-case">{{ $t('inputs.message') }}...</div>
              </div>
              <template v-if="dialog.isShowFeedbackForm && isAnyFeedbackOptionActive">
                <div class="endscreen-feedback__action-button flex items-center justify-center" :style="feedbackFormSendButtonBackgroundStyle">
                  {{ $t('vue.sendMessage') }}
                </div>
                <div class="endscreen-feedback__cancel-button mt-4 flex items-center justify-center">
                  {{ $t('vue.close') }}
                </div>
              </template>
            </div>
          </div>
        </div>

        <powered-by />
      </div>
    </div>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

import RatingStars from '@/components/rating-stars/RatingStars.vue'
import { mapGetters } from 'vuex'
import CloseIcon from '../icons/CloseIcon.vue'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import PoweredBy from '@/components/dialog/PoweredBy.vue'
import SmallLockIcon from '@/components/icons/SmallLockIcon.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'

export default {
  components: {
    CloseIcon,
    ToggleSwitchIcon,
    RatingStars,
    PoweredBy,
    SmallLockIcon,
    InfoIcon
  },
  data() {
    return {
      logo: require('@/assets/images/logo/logo.svg')
    }
  },
  computed: {
    ...mapGetters({
      hasWhiteLabel: 'hasWhiteLabel',
      company: 'company',
      dialog: 'campaign'
    }),

    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('contact-form') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    feedbackFormStyle() {
      return {
        width: '280px',
        height: '586px',
        backgroundColor: this.dialog.sessionEndScreen.backgroundColor,
        color: this.dialog.sessionEndScreen.fontColor,
        backgroundPosition: '-280px 0px',
        borderRadius: this.hasWhiteLabel ? '13px' : '13px 13px 0 0'
      }
    },
    isAnyFeedbackOptionActive() {
      return (
        this.dialog.feedbackFormQuestionFirst ||
        this.dialog.feedbackFormQuestionSecond ||
        this.dialog.feedbackFormQuestionThird ||
        this.dialog.isSendFeedbackViaMessage
      )
    },
    feedbackFormSendButtonBackgroundStyle() {
      return {
        marginTop: '10px',
        backgroundColor: this.feedbackFormSendButtonBackgroundColor
      }
    },
    feedbackFormSendButtonBackgroundColor() {
      return this.lightenDarkenColor(this.dialog.sessionEndScreen.backgroundColor, -16)
    }
  },
  watch: {
    dialog: {
      deep: true,
      handler() {
        this.$emit('dialog-updated', this.dialog)
      }
    }
  },
  mounted() {
    this.$serverBus.$on('validate-step-5', () => {
      this.$validator.validateAll()
    })

    if (!this.HAS_ACCESS && this.dialog.isShowFeedbackForm) {
      this.dialog.isShowFeedbackForm = false
    }
  },
  beforeDestroy() {
    this.$serverBus.$off('validate-step-5')
  },
  methods: {
    toggleShowFeedbackForm() {
      if (!this.HAS_ACCESS) {
        return
      }
      this.dialog.isShowFeedbackForm = !this.dialog.isShowFeedbackForm
    },
    toggleSendFeedbackViaMessage() {
      this.dialog.isSendFeedbackViaMessage = !this.dialog.isSendFeedbackViaMessage
    },
    lightenDarkenColor(color, percent) {
      if (color[0] === '#') {
        color = color.slice(1)
      }

      const num = parseInt(color, 16)
      const amt = Math.round(2.55 * percent)
      const R = (num >> 16) + amt
      const B = ((num >> 8) & 0x00ff) + amt
      const G = (num & 0x0000ff) + amt
      /* eslint-disable no-mixed-operators */
      return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255))
        .toString(16)
        .slice(1)}`
    }
  }
}
</script>

<style lang="scss">
.configure-dialog-step-five {
  .pa-color-picker {
    width: 310px;
    min-width: 310px;
    max-width: 100%;
    height: 40px;
    cursor: pointer;
  }

  .flex-view {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .message-container {
    width: 100%;
    height: auto;

    margin-top: 200px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;

    padding: 10px;
    font-size: 14px;
    line-height: 18px;

    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
    }

    &-copy span {
      white-space: pre-wrap;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.endscreen-feedback {
  &__container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 120px 30px 20px 30px;
    font-size: 14px;
    line-height: 18px;
    overflow-x: hidden;
    overflow-y: auto;

    &-head {
      margin-bottom: 40px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
  }

  &__subtitle {
    white-space: pre-wrap;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    display: flex;
  }

  &__field {
    margin-bottom: 30px;

    &-title {
      margin-bottom: 20px;
      font-weight: 600;
      color: #fff;
      font-size: 16px;
    }
  }

  &__message {
    display: flex;
    height: 100px;
    width: 100%;
    background-color: rgba(230, 236, 241, 0.8);
    border-radius: 6px;
    color: #12598d;
    padding: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  &__action-button,
  &__cancel-button {
    height: 35px;
    border-radius: 6px;

    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    }
  }

  &__cancel-button {
    box-shadow: unset;
    color: #12598d;
    background: transparent;
  }
}
</style>
