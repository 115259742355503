<template>
  <request-options :currentStep="currentStep"></request-options>
</template>
<script>
import RequestOptions from '@/components/campaign/RequestOptions.vue'
export default {
  name: 'QRConnectRequestOptions',
  components: {
    RequestOptions
  },
  props: {
    currentStep: {
      type: Number
    }
  }
}
</script>
