var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configure-dialog-step-six" }, [
    _c("div", { staticClass: "flex-box-column-start" }, [
      _c(
        "div",
        { staticClass: "vx-row w-full" },
        [
          _c(
            "vs-col",
            {
              staticClass: "flex-box-row-start w-full",
              attrs: { "vs-w": "12" },
            },
            [
              _c("div", { staticClass: "headingfont mr-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.contactForm"))),
              ]),
              _c(
                "vx-tooltip",
                {
                  attrs: { text: _vm.$t("info.contactForm"), position: "top" },
                },
                [_c("img", { attrs: { src: _vm.infoImage } })]
              ),
            ],
            1
          ),
          _c(
            "vs-col",
            {
              staticClass: "flex-box-row-start w-full",
              attrs: { "vs-w": "12" },
            },
            [
              _c("div", { staticClass: "translation--container" }, [
                _c(
                  "div",
                  { staticClass: "mr-4" },
                  [
                    _c("info-icon", {
                      attrs: {
                        fill: "#01AAF4",
                        color: "#fff",
                        width: 20,
                        height: 20,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.$t("info.translation")) },
                }),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "flex-view" }, [
      _c(
        "div",
        {
          staticClass: "mt-5 mr-6 flex flex-column flex-grow",
          staticStyle: { "max-width": "630px" },
        },
        [
          _c("div", [_vm._v(_vm._s(_vm.$t("vue.messageIfNobodyAvailable")))]),
          _c("vs-textarea", {
            staticClass: "w-full p-1",
            attrs: { counter: "300", height: "100px", placeholder: "" },
            model: {
              value: _vm.dialog.contactForm.message,
              callback: function ($$v) {
                _vm.$set(_vm.dialog.contactForm, "message", $$v)
              },
              expression: "dialog.contactForm.message",
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "feedback-form-colors flex flex-row flex-wrap justify-between w-full",
            },
            [
              _c("div", { staticClass: "flex flex-column flex-grow" }, [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("vue.contactFormBackgroundColor"))),
                ]),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.dialog.contactForm.backgroundColor,
                        expression: "dialog.contactForm.backgroundColor",
                      },
                    ],
                    staticClass: "pa-color-picker",
                    attrs: {
                      type: "color",
                      name: "feedbackFormConfirmButtonColor",
                    },
                    domProps: { value: _vm.dialog.contactForm.backgroundColor },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.dialog.contactForm,
                          "backgroundColor",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "flex flex-column flex-grow" }, [
                _c("div", [_vm._v(_vm._s(_vm.$t("vue.fontColor")))]),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.dialog.contactForm.fontColor,
                        expression: "dialog.contactForm.fontColor",
                      },
                    ],
                    staticClass: "pa-color-picker",
                    attrs: { type: "color", name: "feedbackFormFontColor" },
                    domProps: { value: _vm.dialog.contactForm.fontColor },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.dialog.contactForm,
                          "fontColor",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "mt-4 flex items-center" }, [
            _c(
              "div",
              { staticClass: "mr-2" },
              [
                _c("vs-switch", {
                  attrs: {
                    color: "#275D73",
                    disabled: !_vm.HAS_SCHEDULING_ACCESS,
                  },
                  model: {
                    value: _vm.dialog.contactForm.isBookingEnabled,
                    callback: function ($$v) {
                      _vm.$set(_vm.dialog.contactForm, "isBookingEnabled", $$v)
                    },
                    expression: "dialog.contactForm.isBookingEnabled",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "mr-2",
                staticStyle: { "align-self": "flex-end" },
              },
              [_vm._v(_vm._s(_vm.$t("vue.meetingSlotBooking")))]
            ),
            _c(
              "div",
              [
                _vm.HAS_SCHEDULING_ACCESS
                  ? _c(
                      "vx-tooltip",
                      {
                        attrs: {
                          text: _vm.$t("info.meetingSlotBooking"),
                          position: "top",
                        },
                      },
                      [_c("img", { attrs: { src: _vm.infoImage } })]
                    )
                  : _c(
                      "vx-tooltip",
                      {
                        staticStyle: { height: "20px" },
                        attrs: {
                          text: _vm.$t("upgrade-messages.locked-feature"),
                          position: "top",
                        },
                      },
                      [_c("small-lock-icon")],
                      1
                    ),
              ],
              1
            ),
          ]),
          _vm.dialog.contactForm.isBookingEnabled && _vm.HAS_SCHEDULING_ACCESS
            ? _c(
                "div",
                { staticClass: "mt-4 flex items-center" },
                [
                  _c("div", { staticClass: "mr-2" }, [
                    _vm._v(_vm._s(_vm.$t("vue.bookableAppointments"))),
                  ]),
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("info.bookableAppointments"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImage } })]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.dialog.contactForm.isBookingEnabled && _vm.HAS_SCHEDULING_ACCESS
            ? _c("v-select", {
                staticClass: "mb-4 md:mb-0 w-full",
                attrs: {
                  options: _vm.appointments,
                  clearable: false,
                  dir: _vm.$vs.rtl ? "rtl" : "ltr",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(option.label) +
                                "\n          "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "no-options",
                      fn: function () {
                        return [_vm._v(_vm._s(""))]
                      },
                      proxy: true,
                    },
                    {
                      key: "list-footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "configure-dialog-step-six__select-link-message",
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({
                                    name: "company-setup",
                                    params: { action: "create-appointment" },
                                  })
                                },
                              },
                            },
                            [
                              _c("CalendarIcon", {
                                staticClass:
                                  "configure-dialog-step-six__select-link-message__icon",
                                attrs: {
                                  color: "#3B86F7",
                                  width: 13,
                                  height: 13,
                                },
                              }),
                              _vm._v(
                                "\n\n            " +
                                  _vm._s(
                                    _vm.$t("vue.createNewBookableAppointments")
                                  ) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1644341396
                ),
                model: {
                  value: _vm.selectedAppointment,
                  callback: function ($$v) {
                    _vm.selectedAppointment = $$v
                  },
                  expression: "selectedAppointment",
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "mt-4" }, [
            _vm._v(_vm._s(_vm.$t("vue.contactFormFields"))),
          ]),
          _c(
            "div",
            {
              staticClass:
                "contact-form-fields flex flex-row flex-wrap justify-between w-full",
            },
            [
              _c("div", { staticClass: "mt-2 flex flex-column" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "contact-form-fields-item flex-grow flex items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "contact-form-fields-item-text" },
                      [_vm._v(_vm._s(_vm.$t("vue.name")))]
                    ),
                    !_vm.dialog.contactForm.isBookingEnabled
                      ? _c("div", { staticStyle: { "margin-left": "auto" } }, [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.dialog.contactForm.fields.name.enabled =
                                    !_vm.dialog.contactForm.fields.name.enabled
                                },
                              },
                            },
                            [
                              _c("toggle-switch-icon", {
                                attrs: {
                                  enabled:
                                    _vm.dialog.contactForm.fields.name.enabled,
                                  width: 40,
                                  height: 40,
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        attrs: {
                          disabled: _vm.dialog.contactForm.isBookingEnabled,
                        },
                        model: {
                          value: _vm.dialog.contactForm.fields.name.required,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dialog.contactForm.fields.name,
                              "required",
                              $$v
                            )
                          },
                          expression: "dialog.contactForm.fields.name.required",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "contact-form-fields-item-required" },
                          [_vm._v(_vm._s(_vm.$t("vue.requiredInformation")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "mt-2 flex flex-column" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "contact-form-fields-item flex-grow flex items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "contact-form-fields-item-text" },
                      [_vm._v(_vm._s(_vm.$t("vue.email")))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.dialog.contactForm.fields.email.required,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dialog.contactForm.fields.email,
                              "required",
                              $$v
                            )
                          },
                          expression:
                            "dialog.contactForm.fields.email.required",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "contact-form-fields-item-required" },
                          [_vm._v(_vm._s(_vm.$t("vue.requiredInformation")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.dialog.contactForm.isBookingEnabled
                ? _c("div", { staticClass: "mt-2 flex flex-column" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "contact-form-fields-item flex-grow flex items-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "contact-form-fields-item-text" },
                          [_vm._v(_vm._s(_vm.$t("vue.date")))]
                        ),
                        !_vm.dialog.contactForm.isBookingEnabled
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "auto" } },
                              [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.dialog.contactForm.fields.date.enabled =
                                          !_vm.dialog.contactForm.fields.date
                                            .enabled
                                      },
                                    },
                                  },
                                  [
                                    _c("toggle-switch-icon", {
                                      attrs: {
                                        enabled:
                                          _vm.dialog.contactForm.fields.date
                                            .enabled,
                                        width: 40,
                                        height: 40,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            attrs: {
                              disabled: _vm.dialog.contactForm.isBookingEnabled,
                            },
                            model: {
                              value:
                                _vm.dialog.contactForm.fields.date.required,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dialog.contactForm.fields.date,
                                  "required",
                                  $$v
                                )
                              },
                              expression:
                                "dialog.contactForm.fields.date.required",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "contact-form-fields-item-required",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("vue.requiredInformation"))
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              !_vm.dialog.contactForm.isBookingEnabled
                ? _c("div", { staticClass: "mt-2 flex flex-column" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "contact-form-fields-item flex-grow flex items-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "contact-form-fields-item-text" },
                          [_vm._v(_vm._s(_vm.$t("vue.phone")))]
                        ),
                        _c("div", { staticStyle: { "margin-left": "auto" } }, [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.dialog.contactForm.fields.phone.enabled =
                                    !_vm.dialog.contactForm.fields.phone.enabled
                                },
                              },
                            },
                            [
                              _c("toggle-switch-icon", {
                                attrs: {
                                  enabled:
                                    _vm.dialog.contactForm.fields.phone.enabled,
                                  width: 40,
                                  height: 40,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            model: {
                              value:
                                _vm.dialog.contactForm.fields.phone.required,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dialog.contactForm.fields.phone,
                                  "required",
                                  $$v
                                )
                              },
                              expression:
                                "dialog.contactForm.fields.phone.required",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "contact-form-fields-item-required",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("vue.requiredInformation"))
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "mt-2 flex flex-column" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "contact-form-fields-item flex-grow flex items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "contact-form-fields-item-text" },
                      [_vm._v(_vm._s(_vm.$t("inputs.message")))]
                    ),
                    _c("div", { staticStyle: { "margin-left": "auto" } }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialog.contactForm.fields.message.enabled =
                                !_vm.dialog.contactForm.fields.message.enabled
                            },
                          },
                        },
                        [
                          _c("toggle-switch-icon", {
                            attrs: {
                              enabled:
                                _vm.dialog.contactForm.fields.message.enabled,
                              width: 40,
                              height: 40,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        model: {
                          value: _vm.dialog.contactForm.fields.message.required,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dialog.contactForm.fields.message,
                              "required",
                              $$v
                            )
                          },
                          expression:
                            "dialog.contactForm.fields.message.required",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "contact-form-fields-item-required" },
                          [_vm._v(_vm._s(_vm.$t("vue.requiredInformation")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "mt-4 flex flex-row items-center justify-center" },
            [
              _c("div", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("vue.privacyInformation"))),
              ]),
              _c(
                "div",
                { staticClass: "ml-2" },
                [
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("info.privacyInformation"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImage } })]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "mt-4 flex items-center" }, [
            _c("div", { staticClass: "mr-2" }, [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog.contactForm.isPrivacyInformationEnabled =
                        !_vm.dialog.contactForm.isPrivacyInformationEnabled
                    },
                  },
                },
                [
                  _c("toggle-switch-icon", {
                    attrs: {
                      enabled:
                        _vm.dialog.contactForm.isPrivacyInformationEnabled,
                      width: 40,
                      height: 40,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("vue.enablePrivacyInformation"))),
            ]),
          ]),
          _vm.dialog.contactForm.isPrivacyInformationEnabled
            ? _c(
                "div",
                { staticClass: "mt-4 mr-2 w-full" },
                [
                  _c("quill-editor", {
                    ref: "quillEditorA",
                    attrs: { options: _vm.editorOption },
                    model: {
                      value: _vm.dialog.contactForm.privacyInformationHtml,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dialog.contactForm,
                          "privacyInformationHtml",
                          $$v
                        )
                      },
                      expression: "dialog.contactForm.privacyInformationHtml",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "mt-4 flex flex-row items-center justify-center" },
            [
              _c("div", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("vue.sendMessageEndMessage"))),
              ]),
              _c(
                "div",
                { staticClass: "ml-2" },
                [
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("info.sendMessageEndMessage"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImage } })]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "mt-4 flex items-center" }, [
            _c("div", { staticClass: "mr-2" }, [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.showSendMessagePreview = !_vm.showSendMessagePreview
                    },
                  },
                },
                [
                  _c("toggle-switch-icon", {
                    attrs: {
                      enabled: _vm.showSendMessagePreview,
                      width: 40,
                      height: 40,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mr-2" }, [
              _vm._v(_vm._s(_vm.$t("vue.showPreview"))),
            ]),
          ]),
          _c("div", { staticClass: "mt-4" }, [
            _vm._v(_vm._s(_vm.$t("inputs.title"))),
          ]),
          _c("vs-input", {
            staticClass: "w-full mr-2",
            model: {
              value: _vm.dialog.contactForm.sendMessageTitle,
              callback: function ($$v) {
                _vm.$set(_vm.dialog.contactForm, "sendMessageTitle", $$v)
              },
              expression: "dialog.contactForm.sendMessageTitle",
            },
          }),
          _c("div", { staticClass: "mt-4" }, [
            _vm._v(_vm._s(_vm.$t("inputs.message"))),
          ]),
          _c("vs-textarea", {
            staticClass: "w-full p-1",
            attrs: { counter: "300", height: "100px" },
            model: {
              value: _vm.dialog.contactForm.sendMessageCopy,
              callback: function ($$v) {
                _vm.$set(_vm.dialog.contactForm, "sendMessageCopy", $$v)
              },
              expression: "dialog.contactForm.sendMessageCopy",
            },
          }),
          _vm.dialog.contactForm.isBookingEnabled
            ? _c(
                "div",
                {
                  staticClass: "mt-4 flex flex-row items-center justify-center",
                },
                [
                  _c("div", { staticClass: "headline" }, [
                    _vm._v(_vm._s(_vm.$t("vue.bookingSlotEndMessage"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-2" },
                    [
                      _c(
                        "vx-tooltip",
                        {
                          attrs: {
                            text: _vm.$t("info.bookingSlotEndMessage"),
                            position: "top",
                          },
                        },
                        [_c("img", { attrs: { src: _vm.infoImage } })]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.dialog.contactForm.isBookingEnabled
            ? _c("div", { staticClass: "mt-4 flex items-center" }, [
                _c("div", { staticClass: "mr-2" }, [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showBookingSlotPreview =
                            !_vm.showBookingSlotPreview
                        },
                      },
                    },
                    [
                      _c("toggle-switch-icon", {
                        attrs: {
                          enabled: _vm.showBookingSlotPreview,
                          width: 40,
                          height: 40,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "mr-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.showPreview"))),
                ]),
              ])
            : _vm._e(),
          _vm.dialog.contactForm.isBookingEnabled
            ? _c("div", { staticClass: "mt-4" }, [
                _vm._v(_vm._s(_vm.$t("inputs.title"))),
              ])
            : _vm._e(),
          _vm.dialog.contactForm.isBookingEnabled
            ? _c("vs-input", {
                staticClass: "w-full mr-2",
                model: {
                  value: _vm.dialog.contactForm.bookingSlotTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.dialog.contactForm, "bookingSlotTitle", $$v)
                  },
                  expression: "dialog.contactForm.bookingSlotTitle",
                },
              })
            : _vm._e(),
          _vm.dialog.contactForm.isBookingEnabled
            ? _c("div", { staticClass: "mt-4" }, [
                _vm._v(_vm._s(_vm.$t("inputs.message"))),
              ])
            : _vm._e(),
          _vm.dialog.contactForm.isBookingEnabled
            ? _c("vs-textarea", {
                staticClass: "w-full p-1",
                attrs: { counter: "300", height: "100px" },
                model: {
                  value: _vm.dialog.contactForm.bookingSlotCopy,
                  callback: function ($$v) {
                    _vm.$set(_vm.dialog.contactForm, "bookingSlotCopy", $$v)
                  },
                  expression: "dialog.contactForm.bookingSlotCopy",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-column",
          staticStyle: { "margin-left": "auto" },
        },
        [
          _c(
            "div",
            {
              staticClass: "mb-3",
              staticStyle: { "align-self": "flex-start" },
            },
            [_vm._v(_vm._s(_vm.$t("vue.preview")))]
          ),
          _vm.showContactFormPreview
            ? _c(
                "div",
                { staticClass: "relative flex flex-column" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex justify-center items-center",
                      style: _vm.contactFormBackgroundStyle,
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "message-container",
                          style: _vm.contactFormStyle,
                        },
                        [
                          _c(
                            "scroll-view",
                            {
                              ref: "chatScrollArea",
                              staticClass: "contact-form-scroll-area",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    right: "0px",
                                    top: "0px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "close-box flex justify-center items-center",
                                      style: _vm.closeButton,
                                    },
                                    [
                                      _c("close-icon", {
                                        attrs: {
                                          width: 16,
                                          height: 16,
                                          color:
                                            _vm.dialog.contactForm.fontColor,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "message-container-title" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("vue.contactForm")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "mt-4 message-container-copy" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.dialog.contactForm.message) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex mt-4 justify-center items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "message-container-upper" },
                                    [_vm._v(_vm._s(_vm.$t("vue.sendAMessage")))]
                                  ),
                                  _vm.dialog.contactForm.isBookingEnabled
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "ml-2 mr-2 message-container-upper pointer",
                                          staticStyle: { "margin-top": "5px" },
                                          on: {
                                            click: function ($event) {
                                              _vm.isBookingEnabled =
                                                !_vm.isBookingEnabled
                                            },
                                          },
                                        },
                                        [
                                          _vm.isBookingEnabled
                                            ? _c("switch-on-icon", {
                                                attrs: {
                                                  color: "#12598D",
                                                  width: 41,
                                                  height: 20,
                                                },
                                              })
                                            : _vm._e(),
                                          !_vm.isBookingEnabled
                                            ? _c("switch-off-icon", {
                                                attrs: {
                                                  color: "#B2B2B2",
                                                  width: 41,
                                                  height: 20,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dialog.contactForm.isBookingEnabled
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "message-container-upper",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("vue.bookAMeeting"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.dialog.contactForm.fields.name
                                          .enabled,
                                      expression:
                                        "dialog.contactForm.fields.name.enabled",
                                    },
                                  ],
                                  staticClass: "mt-4 message-container-fields",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mr-2",
                                      staticStyle: { "margin-top": "1px" },
                                    },
                                    [
                                      _c("user-icon", {
                                        attrs: {
                                          width: 15,
                                          height: 15,
                                          color: "#262629",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "upper-case" }, [
                                    _vm._v(_vm._s(_vm.$t("vue.name"))),
                                  ]),
                                  _vm.dialog.contactForm.fields.name.required
                                    ? _c("span", { staticClass: "ml-1" }, [
                                        _vm._v("*"),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm.dialog.contactForm.fields.email.enabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 message-container-fields",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mr-2",
                                          staticStyle: { "margin-top": "1px" },
                                        },
                                        [
                                          _c("mail-icon", {
                                            attrs: {
                                              width: 15,
                                              height: 15,
                                              color: "#262629",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "upper-case" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("vue.emailAddress"))
                                        ),
                                      ]),
                                      _vm.dialog.contactForm.fields.email
                                        .required
                                        ? _c("span", { staticClass: "ml-1" }, [
                                            _vm._v("*"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.dialog.contactForm.fields.date.enabled &&
                              _vm.dialog.contactForm.isBookingEnabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 message-container-fields",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mr-2",
                                          staticStyle: { "margin-top": "1px" },
                                        },
                                        [
                                          _c("calendar-icon", {
                                            attrs: {
                                              width: 15,
                                              height: 15,
                                              color: "#262629",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "upper-case" }, [
                                        _vm._v(_vm._s(_vm.$t("vue.date"))),
                                      ]),
                                      _vm.dialog.contactForm.fields.date
                                        .required
                                        ? _c("span", { staticClass: "ml-1" }, [
                                            _vm._v("*"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.dialog.contactForm.fields.phone.enabled &&
                              !_vm.dialog.contactForm.isBookingEnabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 message-container-fields",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mr-2",
                                          staticStyle: { "margin-top": "1px" },
                                        },
                                        [
                                          _c("VerticalPhoneIcon", {
                                            attrs: {
                                              width: 15,
                                              height: 15,
                                              color: "#262629",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "upper-case" }, [
                                        _vm._v(_vm._s(_vm.$t("vue.phone"))),
                                      ]),
                                      _vm.dialog.contactForm.fields.phone
                                        .required
                                        ? _c("span", { staticClass: "ml-1" }, [
                                            _vm._v("*"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.dialog.contactForm.fields.message.enabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 message-container-fields ht-100",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mr-2",
                                          staticStyle: { "margin-top": "1px" },
                                        },
                                        [
                                          _c("message-square-icon", {
                                            attrs: {
                                              width: 15,
                                              height: 15,
                                              color: "#262629",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "upper-case" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("inputs.message"))
                                        ),
                                      ]),
                                      _vm.dialog.contactForm.fields.message
                                        .required
                                        ? _c("span", { staticClass: "ml-1" }, [
                                            _vm._v("*"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.dialog.contactForm.isPrivacyInformationEnabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "flex flex-row",
                                      staticStyle: { "margin-top": "40px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("vs-checkbox", {
                                            staticClass:
                                              "configure-dialog-step-six-checkbox",
                                            style: {
                                              "--contact-form-darken-background":
                                                _vm.dialog.contactForm
                                                  .sendButtonBackgroundColor,
                                              "--contact-form-font-color":
                                                _vm.dialog.contactForm
                                                  .fontColor,
                                            },
                                            model: {
                                              value: _vm.hasAgreedToPrivacy,
                                              callback: function ($$v) {
                                                _vm.hasAgreedToPrivacy = $$v
                                              },
                                              expression: "hasAgreedToPrivacy",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "message-container-privacy-policy",
                                        style: {
                                          "--contact-form-font-color":
                                            _vm.dialog.contactForm.fontColor,
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.dialog.contactForm
                                              .privacyInformationHtml
                                          ),
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "message-container-action-button flex items-center justify-center",
                                  style:
                                    _vm.contactFormSendButtonBackgroundStyle,
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.isBookingEnabled
                                          ? _vm.$t("vue.bookMeeting")
                                          : _vm.$t("vue.sendMessage")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("powered-by"),
                ],
                1
              )
            : _vm._e(),
          _vm.showSendMessagePreview
            ? _c(
                "div",
                {
                  staticClass: "flex flex-column relative",
                  staticStyle: { "margin-left": "auto" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "20px",
                        top: "20px",
                        cursor: "pointer",
                      },
                    },
                    [_c("close-icon", { attrs: { width: 16, height: 16 } })],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex justify-center items-center",
                      style: _vm.contactFormFeedbackStyle,
                    },
                    [
                      _c("div", { staticClass: "feedback-message-container" }, [
                        _c(
                          "div",
                          { staticClass: "feedback-message-container-title" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.dialog.contactForm.sendMessageTitle
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-4 feedback-message-container-copy",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.dialog.contactForm.sendMessageCopy) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("powered-by"),
                ],
                1
              )
            : _vm._e(),
          _vm.showBookingSlotPreview
            ? _c(
                "div",
                {
                  staticClass: "flex flex-column relative",
                  staticStyle: { "margin-left": "auto" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "20px",
                        top: "20px",
                        cursor: "pointer",
                      },
                    },
                    [_c("close-icon", { attrs: { width: 16, height: 16 } })],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex justify-center items-center",
                      style: _vm.contactFormFeedbackStyle,
                    },
                    [
                      _c("div", { staticClass: "feedback-message-container" }, [
                        _c(
                          "div",
                          { staticClass: "feedback-message-container-title" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.dialog.contactForm.bookingSlotTitle
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-4feedback-message-container-copy",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.dialog.contactForm.bookingSlotCopy) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c("powered-by"),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }