<template>
  <div class="configure-dialog-step-four">
    <vs-popup fullscreen :title="$t('vue.cropImage')" :active.sync="showCroppa" button-close-hidden class="image-popup">
      <div class="relative">
        <div disabled class="popover-action flex justify-end items-center">
          <div class="popover-action-button mr-2" @click="onCroppaSave">
            <check-icon color="#00ff00" :width="35" :height="35"></check-icon>
          </div>
          <div class="popover-action-button ml-2" @click="showCroppa = false">
            <close-icon color="#ff0000" :width="35" :height="35"></close-icon>
          </div>
        </div>
        <div class="croppa-uploaded-pics">
          <croppa
            v-show="waitingRoomBackgroundModel.img"
            v-model="waitingRoomBackgroundModel"
            initial-size="cover"
            placeholder="Click here"
            :show-remove-button="false"
            canvas-color="transparent"
            prevent-white-space
            :initial-image="waitingRoomBackground"
            accept=".jpeg,.jpg,.gif,.png"
            :disable-drag-to-move="false"
            :file-size-limit="2048000"
            :placeholder-font-size="14"
            :disabled="false"
            @file-type-mismatch="onFileTypeMismatch"
            @file-size-exceed="onFileSizeExceed"
            @new-image-drawn="onNewImageDrawn"
            @image-remove="onImageRemoved"
            @file-choose="onFileChoose"
            class="resizable-croppa"
            :width="1024"
            :height="800"
            :quality="2"
          >
          </croppa>
        </div>
      </div>
    </vs-popup>
    <div class="flex-box-column-start">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont mr-2">{{ $t('vue.waitingRoomSetupHeader') }}</div>
          <vx-tooltip :text="$t('info.waitingRoomSetupHeader')" position="top">
            <img :src="infoImage" />
          </vx-tooltip>
        </vs-col>
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="translation--container">
            <div class="mr-4"><info-icon fill="#01AAF4" color="#fff" :width="20" :height="20" /></div>
            <div v-html="$t('info.translation')"></div>
          </div>
        </vs-col>
      </div>
    </div>

    <div class="flex-view">
      <div class="mr-5 flex flex-column" style="width: 100%; max-width: 630px">
        <div class="mt-4 flex w-full">
          <div class="mb-2">{{ $t('vue.waitingRoomLabel') }}</div>
        </div>
        <div><quill-editor v-model="dialog.waitingRoomMessage" ref="quillEditorA" :options="editorOption"> </quill-editor></div>
        <div class="configure-dialog-step-four-colors flex flex-row flex-wrap justify-between w-full mt-4">
          <div class="flex flex-column flex-grow">
            <div>{{ $t('vue.backgroundColor') }}</div>
            <div>
              <input
                class="pa-color-picker"
                type="color"
                v-model="dialog.waitingRoomContentChatMessage.backgroundColor"
                name="waitingRoomContentChatMessageBackground"
              />
            </div>
          </div>
          <div class="flex flex-column flex-grow">
            <div>{{ $t('vue.fontColor') }}</div>
            <div>
              <input
                class="pa-color-picker"
                type="color"
                v-model="dialog.waitingRoomContentChatMessage.fontColor"
                name="waitingRoomContentChatMessageFontColor"
              />
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="flex-center">
            <div v-if="!dialog.waitingRoomBackground" class="flex flex-row">
              <div>
                <img :src="waitingRoomBackground" width="125" height="105" class="background-image fit-cover" />
              </div>
              <div class="ml-2">
                <vs-button
                  type="none"
                  class="upload-photo mr-2 sm:mb-0 mb-2 upload p-0"
                  @click="
                    showCroppa = true
                    selectFile()
                  "
                >
                  <div class="con-upload">
                    <div class="con-img-upload">
                      <div class="con-input-upload">
                        <span class="text-input" style="color: #000">{{ uploadPhotoButtonText }}</span>
                        <button disabled="disabled" type="button" title="Upload" class="btn-upload-all vs-upload--button-upload">
                          <i translate="translate" class="material-icons notranslate"> cloud_upload </i>
                        </button>
                      </div>
                    </div>
                  </div>
                </vs-button>
              </div>
            </div>
            <div class="flex flex-row items-center" v-else>
              <div>
                <img
                  slot="initial"
                  crossorigin="anonymous"
                  class="background-image fit-cover pointer"
                  :key="imageKey"
                  :src="waitingRoomBackground"
                  @click="showCroppa = true"
                />
              </div>
              <div class="ml-2">
                <vs-button
                  class="delete-btn-padding"
                  type="border"
                  color="danger"
                  size="large"
                  @click="onRemovePhoto()"
                  icon-pack="material-icons"
                  icon="photo"
                  icon-after
                >
                  {{ $t('vue.delete') }}
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-column waiting-room">
        <div class="mb-3" style="align-self: flex-start">{{ $t('vue.preview') }}</div>
        <div class="waiting-room-preview">
          <div class="waiting-room-preview-image">
            <img :src="waitingRoomBackground" :key="imageKey" />
          </div>
          <div class="waiting-room-content">
            <div class="waiting-room-content-chat-container" v-html="dialog.waitingRoomMessage" :style="waitingRoomContentChatMessageStyle"></div>
            <div
              class="waiting-room-content-tool-bar"
              :style="{
                '--waiting-room-toolbar-color': dialog.step2ButtonColor
              }"
            ></div>
            <div class="waiting-room-content-chat-input flex items-center">
              <div>{{ $t('vue.writeMessage') }}</div>
              <div class="flex items-center" style="margin-left: auto">
                <send-icon class="waiting-room-content-chat-input-button-icon" :width="20" :height="20"></send-icon>
              </div>
            </div>
            <powered-by />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import SendIcon from '../icons/SendIcon.vue'
import CheckIcon from '../icons/CheckIcon.vue'
import CloseIcon from '../icons/CloseIcon.vue'
import PoweredBy from '@/components/dialog/PoweredBy.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'

export default {
  components: {
    quillEditor,
    SendIcon,
    CheckIcon,
    CloseIcon,
    PoweredBy,
    InfoIcon
  },
  data() {
    return {
      defaultWaitingRoomBackground: require('@/assets/images/dialog/waiting-room-background.jpeg'),
      defaultWaitingRoomContentChatMessageBackgroundColor: '#C4C4C4',
      defaultWaitingRoomContentChatMessageFontColor: '#12598d',
      logo: require('@/assets/images/logo/logo.svg'),
      editorOption: {
        modules: {
          toolbar: [['bold', 'italic', 'underline']]
        }
      },
      waitingRoomBackgroundModel: {},
      isPhotoSelected: false,
      showCroppa: false,
      uploadPhotoButtonText: this.$i18n.t('vue.selectPicture'),
      progressUpload: 0,
      imageKey: Math.random().toString(36).substring(2, 15)
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'campaign',
      dialogId: 'campaignId'
    }),
    waitingRoomBackground() {
      return this.dialog && this.dialog.waitingRoomBackground ? this.dialog.waitingRoomBackground : this.defaultWaitingRoomBackground
    },
    isDefaultPhoto() {
      return this.dialog && !this.dialog.waitingRoomBackground
    },
    waitingRoomStyle() {
      return {
        width: '375px',
        height: '780px',
        backgroundImage: `url('${this.waitingRoomBackground}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '1197px 780px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        backgroundPosition: '-280px 0px'
      }
    },
    waitingRoomContentChatMessageStyle() {
      return {
        backgroundColor: this.dialog.waitingRoomContentChatMessage.backgroundColor || this.defaultWaitingRoomContentChatMessageBackgroundColor,
        color: this.dialog.waitingRoomContentChatMessage.fontColor || this.defaultWaitingRoomContentChatMessageFontColor
      }
    }
  },
  watch: {
    'dialog.waitingRoomMessage'() {
      this.dialog.waitingRoomMessage = this.decodeHTML()
    }
  },
  methods: {
    decodeHTML() {
      const txt = document.createElement('textarea')
      txt.innerHTML = this.dialog.waitingRoomMessage
      return txt.value
    },
    onCroppaInit() {
      //const canvas = this.waitingRoomBackgroundModel.getCanvas()
      //canvas.style.height = 'auto'
      //canvas.style.width = '100%'
    },
    async onCroppaSave() {
      this.showCroppa = false
      this.uploadCroppedImage()
    },
    onFileTypeMismatch() {
      this.isPhotoSelected = false
      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.fileTypeNotSupported'),
        text: this.$i18n.t('vue.fileTypeNotSupportedMessage'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    onFileSizeExceed() {
      this.isPhotoSelected = false
      this.waitingRoomBackgroundModel.refresh()
      this.showCroppa = false
      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.fileSizeLimitExceeded'),
        text: this.$i18n.t('vue.maximumSize'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    onNewImageDrawn() {
      if (this.isDefaultPhoto) {
        this.uploadPhotoButtonText = this.$i18n.t('vue.selectPicture')
      } else {
        this.uploadPhotoButtonText = this.$i18n.t('vue.uploadPicture')
      }
    },
    onImageRemoved() {
      this.uploadPhotoButtonText = this.$i18n.t('vue.selectPicture')
    },
    onFileChoose() {
      this.isPhotoSelected = true
    },
    async selectFile() {
      if ((this.isPhotoSelected || this.dialog.waitingRoomBackground) && !this.isDefaultPhoto) {
        await this.uploadCroppedImage()
      } else {
        this.waitingRoomBackgroundModel.chooseFile()
      }
    },
    async onRemovePhoto() {
      const vm = this
      vm.$vs.loading()
      this.waitingRoomBackgroundModel.remove()
      const storageService = this.$firebase.storage()

      try {
        await storageService.refFromURL(this.dialog.waitingRoomBackground).delete()
        const dialogRef = await this.$db.collection('campaigns').doc(this.dialogId)
        await dialogRef.set({ waitingRoomBackground: null }, { merge: true })
      } catch (err) {
        let message = err.message
        if (err.code === 'storage/object-not-found') {
          message = vm.$i18n.t('vue.objectDoesNotExists')
        }
        const dialogRef = await this.$db.collection('campaigns').doc(this.dialogId)
        await dialogRef.set({ waitingRoomBackground: null }, { merge: true })

        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        vm.$vs.loading.close()
      }
      vm.$vs.loading.close()
    },
    async uploadCroppedImage() {
      const vm = this
      vm.$vs.loading()
      vm.isPhotoSelected = false
      try {
        this.waitingRoomBackgroundModel.generateBlob(
          async (blob) => {
            const storageService = this.$firebase.storage()
            const storageRef = storageService.ref()
            const file = blob

            const metadata = {
              contentType: 'image/png',
              cacheControl: 'max-age=604800, public'
            }

            let uploadTask = null
            uploadTask = storageRef.child(`images/campaigns/${this.dialogId}_waiting-room`).put(file, metadata)
            this.uploadTask = true

            uploadTask.on(
              this.$firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                this.progressUpload = progress
              },
              function (error) {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    vm.text = 'fields.storage/unauthorized'
                    break
                  case 'storage/canceled':
                    // User canceled the upload
                    vm.text = 'fields.storage/canceled'
                    break
                  case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    vm.text = 'fields.storage/unknown'
                    break
                }

                vm.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.error'),
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })

                vm.uploadTask = false
              },
              async () => {
                // Upload completed successfully, now we can get the download URL
                this.uploadTask = false
                const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

                const dialogRef = await this.$db.collection('campaigns').doc(this.dialogId)
                dialogRef.set({ waitingRoomBackground: downloadURL }, { merge: true })

                this.$emit('dialog-updated', {
                  ...this.dialog,
                  waitingRoomBackground: downloadURL
                })
                this.imageKey = Math.random().toString(36).substring(2, 15)
                this.$vs.loading.close()
                this.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.success'),
                  text: vm.$i18n.t('vue.photoSavedSuccessfully'),
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })
              }
            )
          },
          'image/jpg',
          0.8
        ) // 80% compressed jpeg file
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        /* eslint-disable no-console */
        console.error(err.message)
      }
    }
  }
}
</script>

<style lang="scss">
.croppa-uploaded-pics {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: center;
}
.image-popup {
  z-index: 55000 !important;
  .vs-popup {
    width: fit-content !important;
    height: fit-content !important;

    &--header {
      display: none !important;
    }
  }
}
.popover-action {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  &-button {
    top: 15px;
    width: 50px;
    height: 50px;
    z-index: 999;
    border-radius: 6px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
  }

  &-button:hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.7);
  }
}
.configure-dialog-step-four {
  &-colors {
    .pa-color-picker {
      width: 310px;
      min-width: 310px;
      max-width: 100%;
      height: 40px;
      cursor: pointer;
    }
  }
  .waiting-room {
    @media only screen and (max-width: 769px) {
      margin-left: 0px;
      margin-top: 10px;
    }
    @media only screen and (min-width: 770px) {
      margin-left: auto;
    }

    &-preview {
      border-radius: 13px;
      height: 586px;
      width: 280px;
      position: relative;
      &-image {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        position: relative;
        border-radius: 13px;

        img {
          border-radius: 13px;
          min-width: 280px;
          min-height: 586px;
          object-fit: cover;
        }
      }
    }
    &-content {
      position: absolute;
      bottom: 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;

      &-chat-container {
        min-height: 120px;
        background: #c4c4c4;
        border-radius: 6px;
        margin-top: auto;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: justify;

        font-style: normal;
        padding: 10px;
        font-size: 14px;
        line-height: 18px;
        color: rgba(var(--vs-secondary), 1);
      }

      &-chat-input {
        height: 45px;
        width: 100%;
        color: #979797;
        padding: 10px;
        background: white;

        &-button-icon {
          color: rgba(var(--vs-secondary), 1);
        }
      }

      &-tool-bar {
        height: 40px;
        width: 100%;
        background: var(--waiting-room-toolbar-color);
      }
    }
  }

  .fit-cover {
    object-fit: cover;
  }

  .background-image {
    width: 125px;
    height: 105px;
    border-radius: 6px;
  }
  .flex-view {
    display: flex;

    @media only screen and (max-width: 769px) {
      flex-direction: row !important;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @media only screen and (min-width: 770px) {
      flex-direction: row !important;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .ql-editor {
    min-height: 200px;
    font-family: 'Lato';
  }
}
</style>
