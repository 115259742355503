var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full ml-4 flex justify-end" }, [
    _c("div", { staticClass: "flex flex-column" }, [
      _c(
        "div",
        { staticClass: "mb-3", staticStyle: { "align-self": "flex-start" } },
        [_vm._v(_vm._s(_vm.$t("vue.preview")))]
      ),
      _c("div", { staticClass: "preview-button-card" }, [
        _c("div", { staticClass: "flex flex-view" }, [
          _c("div", { staticClass: "flex flex-column" }, [
            _c("div", { staticClass: "preview-button-card-right" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dialog && !_vm.dialog.showVideo,
                      expression: "dialog && !dialog.showVideo",
                    },
                  ],
                  staticClass: "preview-button-card-right-image",
                },
                [_c("img", { attrs: { src: _vm.imageURL } })]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dialog && _vm.dialog.showVideo,
                      expression: "dialog && dialog.showVideo",
                    },
                  ],
                  staticClass: "preview-button-card-right-video",
                },
                [
                  _vm.embedVideoURL
                    ? _c(
                        "video",
                        {
                          ref: "embedVideo",
                          staticStyle: {
                            "border-radius": "5px",
                            "object-fit": "cover",
                            width: "280px",
                            height: "566px",
                            position: "relative",
                            top: "0",
                            left: "0",
                          },
                          attrs: { playsinline: "", autoplay: "", loop: "" },
                          domProps: { muted: !_vm.dialog.activateVideoSound },
                        },
                        [
                          _vm.embedVideoURL.indexOf(".webm") > -1
                            ? _c("source", {
                                attrs: {
                                  src: _vm.embedVideoURL,
                                  type: _vm.webm,
                                },
                              })
                            : _vm._e(),
                          _vm.embedVideoURL.indexOf(".mp4") > -1
                            ? _c("source", {
                                attrs: {
                                  src: _vm.embedVideoURL,
                                  type: _vm.mp4,
                                },
                              })
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "preview-button-card-right-content",
                  style: _vm.step2TextColor,
                },
                [
                  _c("div", {
                    staticClass: "flex preview-button-card-right-content-step2",
                    staticStyle: {
                      width: "280px",
                      "justify-content": "flex-start",
                    },
                    domProps: { innerHTML: _vm._s(_vm.dialog.textStep2) },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "flex justify-evenly",
                      staticStyle: {
                        width: "253px",
                        "justify-content": "space-evenly",
                      },
                    },
                    [
                      !_vm.dialog.communicationOptions ||
                      _vm.dialog.communicationOptions.chat ||
                      _vm.currentStep === 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "preview-button-card-right-content-action-button flex flex-column justify-center items-center",
                              style: _vm.step2ButtonColor,
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("message-square-icon", {
                                    attrs: { width: 20, height: 20 },
                                  }),
                                ],
                                1
                              ),
                              _c("div", [_vm._v("Chat")]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.dialog.communicationOptions ||
                      _vm.dialog.communicationOptions.audio ||
                      _vm.currentStep === 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "preview-button-card-right-content-action-button flex flex-column justify-center items-center",
                              style: _vm.step2ButtonColor,
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("vertical-phone-icon", {
                                    attrs: { width: 20, height: 20 },
                                  }),
                                ],
                                1
                              ),
                              _c("div", [_vm._v("Phone")]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.dialog.communicationOptions ||
                      _vm.dialog.communicationOptions.video ||
                      _vm.currentStep === 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "preview-button-card-right-content-action-button flex flex-column justify-center items-center",
                              style: _vm.step2ButtonColor,
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("video-call-icon", {
                                    attrs: { width: 20, height: 20 },
                                  }),
                                ],
                                1
                              ),
                              _c("div", [_vm._v("Video")]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm.dialog.isMoreInformationEnabled
                    ? _c(
                        "div",
                        {
                          staticClass: "flex justify-center items-center",
                          staticStyle: { width: "278px" },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.dialog.moreInformationLink,
                                target: "_blank",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "preview-button-card-right-content-more-information flex justify-center items-center",
                                  style: _vm.moreInformationColor,
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.moreInformationButtonText) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }