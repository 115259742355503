var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    [
      !_vm.hasDialogLoaded
        ? _c("vs-progress", { attrs: { indeterminate: "", color: "primary" } })
        : _c(
            "div",
            { staticClass: "configure-dialog" },
            [
              _c(
                "form-wizard",
                {
                  ref: "formWizard",
                  attrs: {
                    color: "rgba(var(--vs-primary), 1)",
                    title: "",
                    subtitle: "",
                    nextButtonText: _vm.$t("vue.next"),
                    backButtonText: _vm.$t("vue.back"),
                    finishButtonText: _vm.$t("inputs.submit"),
                    shape: "circle",
                  },
                  on: {
                    "on-complete": _vm.onComplete,
                    "on-change": _vm.onStepChanged,
                  },
                },
                [
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: _vm.$t("vue.QRConnectSettings"),
                        icon: "feather icon-settings",
                        "before-change": _vm.validateStep1,
                        lazy: true,
                      },
                    },
                    [_c("QRConnectSettings")],
                    1
                  ),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: _vm.$t("vue.adjustQRConnect"),
                        icon: "feather icon-image",
                        "before-change": _vm.validateStep3,
                      },
                    },
                    [
                      _vm.currentStep === 1
                        ? _c("AdjustYourQRConnect", {
                            attrs: {
                              currentStep: _vm.currentStep,
                              "video-key": _vm.videoKey,
                              lazy: true,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: _vm.$t("vue.requestOptions"),
                        icon: "feather icon-clock",
                        lazy: true,
                      },
                    },
                    [
                      _vm.currentStep === 2
                        ? _c("QRConnectRequestOptions", {
                            attrs: { currentStep: _vm.currentStep },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: _vm.$t("vue.waitingRoom"),
                        icon: "feather icon-clock",
                        lazy: true,
                      },
                    },
                    [_c("QRConnectWaitingRoom")],
                    1
                  ),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: _vm.$t("vue.endScreen"),
                        icon: "feather icon-star",
                        lazy: true,
                      },
                    },
                    [_c("QRConnectSessionEndScreen")],
                    1
                  ),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: _vm.$t("vue.contactForm"),
                        icon: "feather icon-message-square",
                        lazy: true,
                      },
                    },
                    [_c("QRConnectContactForm")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }