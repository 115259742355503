var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configure-dialog-step-four" }, [
    _c("div", { staticClass: "flex-box-column-start" }, [
      _c(
        "div",
        { staticClass: "vx-row w-full" },
        [
          _c(
            "vs-col",
            {
              staticClass: "flex-box-row-start w-full",
              attrs: { "vs-w": "12" },
            },
            [
              _c("div", { staticClass: "headingfont mr-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.communicationOptions"))),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "request-options mt-4 flex flex-row" }, [
      _c(
        "form",
        { staticClass: "flex flex-column vx-col lg:w-7/12 w-full mt-2 mr-4" },
        [
          _c("div", { staticClass: "mt-4 flex w-full" }, [
            _c("div", { staticClass: "mb-2" }, [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("vue.communicationOptionsLabel"))),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex-view" }, [
            _c(
              "div",
              {
                staticClass: "flex-view-button",
                class: {
                  "flex-view-button--disabled":
                    _vm.dialog.communicationOptions &&
                    !_vm.dialog.communicationOptions.chat,
                },
                on: {
                  click: function ($event) {
                    return _vm.updateCommunicationOptions("chat")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { "margin-right": "10px", height: "18px" } },
                  [
                    _c("chat-icon", {
                      attrs: {
                        width: 18,
                        height: 18,
                        color:
                          _vm.dialog.communicationOptions &&
                          !_vm.dialog.communicationOptions.chat
                            ? "#b2b2b2"
                            : "#ffffff",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("vue.chat"))),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flex-view-button",
                class: {
                  "flex-view-button--disabled":
                    _vm.dialog.communicationOptions &&
                    !_vm.dialog.communicationOptions.audio,
                },
                on: {
                  click: function ($event) {
                    return _vm.updateCommunicationOptions("audio")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { "margin-right": "10px", height: "18px" } },
                  [
                    _c("vertical-phone-icon", {
                      attrs: {
                        width: 18,
                        height: 18,
                        color:
                          _vm.dialog.communicationOptions &&
                          !_vm.dialog.communicationOptions.audio
                            ? "#b2b2b2"
                            : "#ffffff",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("vue.phone"))),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flex-view-button",
                class: {
                  "flex-view-button--disabled":
                    _vm.dialog.communicationOptions &&
                    !_vm.dialog.communicationOptions.video,
                },
                on: {
                  click: function ($event) {
                    return _vm.updateCommunicationOptions("video")
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { "margin-right": "10px", height: "20px" } },
                  [
                    _c("video-call-icon", {
                      attrs: {
                        width: 20,
                        height: 20,
                        color:
                          _vm.dialog.communicationOptions &&
                          !_vm.dialog.communicationOptions.video
                            ? "#b2b2b2"
                            : "#ffffff",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("vue.video"))),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "mt-4 flex w-full" }, [
            _c("div", { staticClass: "mb-2" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.$t("vue.activateLeadFormBeforeConversation"))
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex-view" }, [
            _c("div", { staticClass: "mt-4 flex items-center" }, [
              _c("div", { staticClass: "mr-2" }, [
                _c(
                  "div",
                  { on: { click: _vm.enableLeadFormBeforeConversation } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: {
                        enabled: _vm.dialog.isShowLeadFormBeforeConversation,
                        width: 40,
                        height: 40,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.yes"))),
              ]),
            ]),
          ]),
          _vm.dialog.isShowLeadFormBeforeConversation
            ? [
                _c("div", { staticClass: "mt-4 flex w-full" }, [
                  _c("div", { staticClass: "mb-2" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("vue.enablePrivacyInformation"))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "mt-4 flex items-center" }, [
                  _c("div", { staticClass: "mr-2" }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialog.leadForm.isPrivacyInformationEnabled =
                              !_vm.dialog.leadForm.isPrivacyInformationEnabled
                          },
                        },
                      },
                      [
                        _c("toggle-switch-icon", {
                          attrs: {
                            enabled:
                              _vm.dialog.leadForm.isPrivacyInformationEnabled,
                            width: 40,
                            height: 40,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "mr-2" }, [
                    _vm._v(_vm._s(_vm.$t("vue.yes"))),
                  ]),
                ]),
                _vm.dialog.leadForm.isPrivacyInformationEnabled
                  ? _c(
                      "div",
                      { staticClass: "mt-4 mr-2 w-full" },
                      [
                        _c("quill-editor", {
                          ref: "quillEditorA",
                          attrs: { options: _vm.editorOption },
                          model: {
                            value: _vm.dialog.leadForm.privacyInformationHtml,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dialog.leadForm,
                                "privacyInformationHtml",
                                $$v
                              )
                            },
                            expression:
                              "dialog.leadForm.privacyInformationHtml",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("div", { staticClass: "vx-col lg:w-5/12 w-full mt-2 ml-4" }, [
        _c(
          "div",
          { staticClass: "flex flex-column" },
          [
            _c("welcome-screen-preview", {
              attrs: {
                id: "request-options",
                uploadType: _vm.dialog.useOneMediaForAllSteps
                  ? "allMedia"
                  : "welcomeScreenMedia",
                uploadSubtype:
                  _vm.dialog.useOneMediaForAllSteps &&
                  typeof _vm.dialog.allMedia === "object" &&
                  _vm.dialog.allMedia !== null
                    ? "welcomeScreenMedia"
                    : "",
                currentStep: _vm.currentStep,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }