<template>
  <div class="step-one flex-box-column-start" v-if="dialog">
    <div class="flex-box-column-start w-full">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont">{{ $t('vue.setupQRConnect') }}</div>
        </vs-col>
      </div>
    </div>
    <div class="vx-row w-full mt-5">
      <vs-row class="mt-2">
        <vs-col vs-type="flex" vs-w="12">
          <div class="flex flex-col w-full" style="max-width: 650px">
            <label>{{ $t('vue.QRConnectName') }}</label>
            <div class="flex-box-row-start">
              <div class="flex flex-col w-full">
                <vs-input
                  class="w-full mr-2"
                  v-model="dialog.name"
                  v-validate.immediate="`required|min:3|isValidCampaignName:${JSON.stringify({ oldVal: oldCampaignName, campaigns: campaignNames })}`"
                  :placeholder="$t('vue.QRConnectName')"
                  :data-vv-as="$t('vue.QRConnectName')"
                  validate-on="blur"
                  name="campaignName"
                />
                <span class="w-full text-danger text-sm" v-show="errors.has('campaignName')">{{ errors.first('campaignName') }}</span>
              </div>
              <vx-tooltip :text="$t('vue.addCampaignName')" position="top" class="ml-3">
                <img :src="infoImage" />
              </vx-tooltip>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <vs-row class="mt-5">
        <vs-col vs-sm="12" vs-lg="12" style="max-width: 650px">
          <span>{{ $t('routes.users') }}</span>
          <div class="flex items-center">
            <multiselect
              v-model="dialog.recipients"
              :options="currentCompanyUsers"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :placeholder="$t('vue.pickSome')"
              :selectLabel="$t('vue.pressEnterToSelect')"
              :selectedLabel="$t('vue.selectedLabel')"
              :deselectLabel="$t('vue.pressEnterToRemove')"
              label="name"
              track-by="name"
              :preselect-first="false"
            >
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} {{ values.length > 1 ? $t('vue.usersSelected') : $t('vue.userSelected') }}</span
                >
              </template>
            </multiselect>
            <vx-tooltip :text="$t('vue.addCampaignAgents')" position="top" class="ml-3">
              <img :src="infoImage" />
            </vx-tooltip>
          </div>
        </vs-col>
      </vs-row>
      <vs-row v-if="HAS_LEADOVERVIEW_ACCESS">
        <vs-col vs-sm="12" vs-lg="12" style="max-width: 650px">
          <div class="flex flex-row items-center mt-4">
            <div @click="toggleIsShowedUpContactFormWhenNoAgentOnline">
              <toggle-switch-icon :enabled="dialog.isShowedUpContactFormWhenNoAgentOnline" :width="40" :height="40" />
            </div>
            <div class="settings__item__caption">{{ $t('info.directlyShowUpContactFormWhenNoAgentIsOnline') }}</div>
          </div>
        </vs-col>
      </vs-row>
      <vs-row v-if="isConnectedWithCallCenter">
        <vs-col vs-sm="12" vs-lg="12" style="max-width: 650px">
          <div class="flex flex-row items-center mt-4">
            <div @click="toggleIsNotifyCallCenter">
              <toggle-switch-icon :enabled="dialog.isNotifyCallCenterEnabled" :width="40" :height="40" />
            </div>

            <div class="mr-2 mt-1">
              {{ $t('vue.shouldNotifyCallCenterAgents') }}
              <a href="#" @click="onCallCenterNameClick">{{ currentActiveCallCenter && currentActiveCallCenter.callCenterName }}</a>
            </div>
            <vx-tooltip :text="$t('vue.shouldNotifyCallCenterAgentsInfo')" position="top">
              <img :src="infoImage" />
            </vx-tooltip>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'

export default {
  name: 'QRConnectSettings',
  components: {
    Multiselect,
    ToggleSwitchIcon
  },
  data() {
    return {
      oldCampaignName: this.diaog && this.dialog.name ? this.dialog.name : ''
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      users: 'users',
      companyData: 'company',
      callCenterSettings: 'call_center_settings',
      campaigns: 'campaigns',
      dialog: 'campaign',
      dialogId: 'campaignId'
    }),
    HAS_LEADOVERVIEW_ACCESS() {
      if (!this.companyData) {
        return false
      }
      let claims = this.companyData.claims || []
      const custom_claims = this.companyData.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('contact-form') && this.companyData.expiryDate && dayjs().isBefore(dayjs.unix(this.companyData.expiryDate.seconds))
    },
    campaignNames() {
      if (!this.campaigns) {
        return []
      }
      return this.campaigns.map((x) => {
        return x.name
      })
    },
    currentActiveCallCenter() {
      if (this.companyData && this.callCenterSettings && this.callCenterSettings.length) {
        return this.callCenterSettings.find((el) => el.uidCallCenterSettings === this.companyData.callCenterSettingsId)
      }
      return null
    },
    isConnectedWithCallCenter() {
      if (this.companyData && this.companyData.isConnectedWithCallCenter) {
        return true
      }
      return false
    },
    currentCompanyUsers() {
      if (this.users && this.users.length && this.activeUserInfo) {
        return this.users.filter((user) => user.company === this.activeUserInfo.company)
      }
      return null
    }
  },
  async mounted() {
    this.$serverBus.$on('validate-step-1', () => {
      this.$validator.validateAll()
    })

    this.oldCampaignName = this.dialog && this.dialog.name ? this.dialog.name : ''

    if (!this.HAS_LEADOVERVIEW_ACCESS && this.dialog && this.dialog.isShowedUpContactFormWhenNoAgentOnline) {
      await this.toggleIsShowedUpContactFormWhenNoAgentOnline()
    }
  },
  methods: {
    async toggleIsShowedUpContactFormWhenNoAgentOnline() {
      await this.$vs.loading()

      const isShowedUpContactFormWhenNoAgentOnline = !!this.dialog.isShowedUpContactFormWhenNoAgentOnline
      this.dialog.isShowedUpContactFormWhenNoAgentOnline = !isShowedUpContactFormWhenNoAgentOnline

      await this.$db
        .collection('campaigns')
        .doc(this.dialogId)
        .set({ isShowedUpContactFormWhenNoAgentOnline: this.dialog.isShowedUpContactFormWhenNoAgentOnline }, { merge: true })

      await this.$vs.loading.close()
    },
    validateForm() {
      return this.errors.any()
    },
    toggleIsNotifyCallCenter() {
      this.dialog.isNotifyCallCenterEnabled = !this.dialog.isNotifyCallCenterEnabled
    },
    onCallCenterNameClick(e) {
      e.preventDefault()
      const data = this.currentActiveCallCenter
      this.$router.push({ name: 'company-setup', params: { action: 'show-call-center-settings', data } })
    }
  }
}
</script>

<style lang="scss">
.language-javascript {
  max-width: 600px;
}
</style>
<style lang="scss" scoped></style>
