var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "configure-dialog-step-four" },
    [
      _c(
        "vs-popup",
        {
          staticClass: "image-popup",
          attrs: {
            fullscreen: "",
            title: _vm.$t("vue.cropImage"),
            active: _vm.showCroppa,
            "button-close-hidden": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.showCroppa = $event
            },
          },
        },
        [
          _c("div", { staticClass: "relative" }, [
            _c(
              "div",
              {
                staticClass: "popover-action flex justify-end items-center",
                attrs: { disabled: "" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "popover-action-button mr-2",
                    on: { click: _vm.onCroppaSave },
                  },
                  [
                    _c("check-icon", {
                      attrs: { color: "#00ff00", width: 35, height: 35 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "popover-action-button ml-2",
                    on: {
                      click: function ($event) {
                        _vm.showCroppa = false
                      },
                    },
                  },
                  [
                    _c("close-icon", {
                      attrs: { color: "#ff0000", width: 35, height: 35 },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "croppa-uploaded-pics" },
              [
                _c("croppa", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.waitingRoomBackgroundModel.img,
                      expression: "waitingRoomBackgroundModel.img",
                    },
                  ],
                  staticClass: "resizable-croppa",
                  attrs: {
                    "initial-size": "cover",
                    placeholder: "Click here",
                    "show-remove-button": false,
                    "canvas-color": "transparent",
                    "prevent-white-space": "",
                    "initial-image": _vm.waitingRoomBackground,
                    accept: ".jpeg,.jpg,.gif,.png",
                    "disable-drag-to-move": false,
                    "file-size-limit": 2048000,
                    "placeholder-font-size": 14,
                    disabled: false,
                    width: 1024,
                    height: 800,
                    quality: 2,
                  },
                  on: {
                    "file-type-mismatch": _vm.onFileTypeMismatch,
                    "file-size-exceed": _vm.onFileSizeExceed,
                    "new-image-drawn": _vm.onNewImageDrawn,
                    "image-remove": _vm.onImageRemoved,
                    "file-choose": _vm.onFileChoose,
                  },
                  model: {
                    value: _vm.waitingRoomBackgroundModel,
                    callback: function ($$v) {
                      _vm.waitingRoomBackgroundModel = $$v
                    },
                    expression: "waitingRoomBackgroundModel",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "flex-box-column-start" }, [
        _c(
          "div",
          { staticClass: "vx-row w-full" },
          [
            _c(
              "vs-col",
              {
                staticClass: "flex-box-row-start w-full",
                attrs: { "vs-w": "12" },
              },
              [
                _c("div", { staticClass: "headingfont mr-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.waitingRoomSetupHeader"))),
                ]),
                _c(
                  "vx-tooltip",
                  {
                    attrs: {
                      text: _vm.$t("info.waitingRoomSetupHeader"),
                      position: "top",
                    },
                  },
                  [_c("img", { attrs: { src: _vm.infoImage } })]
                ),
              ],
              1
            ),
            _c(
              "vs-col",
              {
                staticClass: "flex-box-row-start w-full",
                attrs: { "vs-w": "12" },
              },
              [
                _c("div", { staticClass: "translation--container" }, [
                  _c(
                    "div",
                    { staticClass: "mr-4" },
                    [
                      _c("info-icon", {
                        attrs: {
                          fill: "#01AAF4",
                          color: "#fff",
                          width: 20,
                          height: 20,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.$t("info.translation")) },
                  }),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flex-view" }, [
        _c(
          "div",
          {
            staticClass: "mr-5 flex flex-column",
            staticStyle: { width: "100%", "max-width": "630px" },
          },
          [
            _c("div", { staticClass: "mt-4 flex w-full" }, [
              _c("div", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.waitingRoomLabel"))),
              ]),
            ]),
            _c(
              "div",
              [
                _c("quill-editor", {
                  ref: "quillEditorA",
                  attrs: { options: _vm.editorOption },
                  model: {
                    value: _vm.dialog.waitingRoomMessage,
                    callback: function ($$v) {
                      _vm.$set(_vm.dialog, "waitingRoomMessage", $$v)
                    },
                    expression: "dialog.waitingRoomMessage",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "configure-dialog-step-four-colors flex flex-row flex-wrap justify-between w-full mt-4",
              },
              [
                _c("div", { staticClass: "flex flex-column flex-grow" }, [
                  _c("div", [_vm._v(_vm._s(_vm.$t("vue.backgroundColor")))]),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.dialog.waitingRoomContentChatMessage
                              .backgroundColor,
                          expression:
                            "dialog.waitingRoomContentChatMessage.backgroundColor",
                        },
                      ],
                      staticClass: "pa-color-picker",
                      attrs: {
                        type: "color",
                        name: "waitingRoomContentChatMessageBackground",
                      },
                      domProps: {
                        value:
                          _vm.dialog.waitingRoomContentChatMessage
                            .backgroundColor,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.dialog.waitingRoomContentChatMessage,
                            "backgroundColor",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "flex flex-column flex-grow" }, [
                  _c("div", [_vm._v(_vm._s(_vm.$t("vue.fontColor")))]),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.dialog.waitingRoomContentChatMessage.fontColor,
                          expression:
                            "dialog.waitingRoomContentChatMessage.fontColor",
                        },
                      ],
                      staticClass: "pa-color-picker",
                      attrs: {
                        type: "color",
                        name: "waitingRoomContentChatMessageFontColor",
                      },
                      domProps: {
                        value:
                          _vm.dialog.waitingRoomContentChatMessage.fontColor,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.dialog.waitingRoomContentChatMessage,
                            "fontColor",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "mt-4" }, [
              _c("div", { staticClass: "flex-center" }, [
                !_vm.dialog.waitingRoomBackground
                  ? _c("div", { staticClass: "flex flex-row" }, [
                      _c("div", [
                        _c("img", {
                          staticClass: "background-image fit-cover",
                          attrs: {
                            src: _vm.waitingRoomBackground,
                            width: "125",
                            height: "105",
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "ml-2" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass:
                                "upload-photo mr-2 sm:mb-0 mb-2 upload p-0",
                              attrs: { type: "none" },
                              on: {
                                click: function ($event) {
                                  _vm.showCroppa = true
                                  _vm.selectFile()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "con-upload" }, [
                                _c("div", { staticClass: "con-img-upload" }, [
                                  _c(
                                    "div",
                                    { staticClass: "con-input-upload" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-input",
                                          staticStyle: { color: "#000" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.uploadPhotoButtonText)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-upload-all vs-upload--button-upload",
                                          attrs: {
                                            disabled: "disabled",
                                            type: "button",
                                            title: "Upload",
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons notranslate",
                                              attrs: { translate: "translate" },
                                            },
                                            [_vm._v(" cloud_upload ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _c("div", { staticClass: "flex flex-row items-center" }, [
                      _c("div", [
                        _c("img", {
                          key: _vm.imageKey,
                          staticClass: "background-image fit-cover pointer",
                          attrs: {
                            slot: "initial",
                            crossorigin: "anonymous",
                            src: _vm.waitingRoomBackground,
                          },
                          on: {
                            click: function ($event) {
                              _vm.showCroppa = true
                            },
                          },
                          slot: "initial",
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "ml-2" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "delete-btn-padding",
                              attrs: {
                                type: "border",
                                color: "danger",
                                size: "large",
                                "icon-pack": "material-icons",
                                icon: "photo",
                                "icon-after": "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onRemovePhoto()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("vue.delete")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
              ]),
            ]),
          ]
        ),
        _c("div", { staticClass: "flex flex-column waiting-room" }, [
          _c(
            "div",
            {
              staticClass: "mb-3",
              staticStyle: { "align-self": "flex-start" },
            },
            [_vm._v(_vm._s(_vm.$t("vue.preview")))]
          ),
          _c("div", { staticClass: "waiting-room-preview" }, [
            _c("div", { staticClass: "waiting-room-preview-image" }, [
              _c("img", {
                key: _vm.imageKey,
                attrs: { src: _vm.waitingRoomBackground },
              }),
            ]),
            _c(
              "div",
              { staticClass: "waiting-room-content" },
              [
                _c("div", {
                  staticClass: "waiting-room-content-chat-container",
                  style: _vm.waitingRoomContentChatMessageStyle,
                  domProps: {
                    innerHTML: _vm._s(_vm.dialog.waitingRoomMessage),
                  },
                }),
                _c("div", {
                  staticClass: "waiting-room-content-tool-bar",
                  style: {
                    "--waiting-room-toolbar-color": _vm.dialog.step2ButtonColor,
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "waiting-room-content-chat-input flex items-center",
                  },
                  [
                    _c("div", [_vm._v(_vm._s(_vm.$t("vue.writeMessage")))]),
                    _c(
                      "div",
                      {
                        staticClass: "flex items-center",
                        staticStyle: { "margin-left": "auto" },
                      },
                      [
                        _c("send-icon", {
                          staticClass:
                            "waiting-room-content-chat-input-button-icon",
                          attrs: { width: 20, height: 20 },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("powered-by"),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }