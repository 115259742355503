var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialog
    ? _c("div", { staticClass: "step-one flex-box-column-start" }, [
        _c("div", { staticClass: "flex-box-column-start w-full" }, [
          _c(
            "div",
            { staticClass: "vx-row w-full" },
            [
              _c(
                "vs-col",
                {
                  staticClass: "flex-box-row-start w-full",
                  attrs: { "vs-w": "12" },
                },
                [
                  _c("div", { staticClass: "headingfont" }, [
                    _vm._v(_vm._s(_vm.$t("vue.setupQRConnect"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "vx-row w-full mt-5" },
          [
            _c(
              "vs-row",
              { staticClass: "mt-2" },
              [
                _c("vs-col", { attrs: { "vs-type": "flex", "vs-w": "12" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "flex flex-col w-full",
                      staticStyle: { "max-width": "650px" },
                    },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("vue.QRConnectName"))),
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex-box-row-start" },
                        [
                          _c(
                            "div",
                            { staticClass: "flex flex-col w-full" },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate.immediate",
                                    value:
                                      "required|min:3|isValidCampaignName:" +
                                      JSON.stringify({
                                        oldVal: _vm.oldCampaignName,
                                        campaigns: _vm.campaignNames,
                                      }),
                                    expression:
                                      "`required|min:3|isValidCampaignName:${JSON.stringify({ oldVal: oldCampaignName, campaigns: campaignNames })}`",
                                    modifiers: { immediate: true },
                                  },
                                ],
                                staticClass: "w-full mr-2",
                                attrs: {
                                  placeholder: _vm.$t("vue.QRConnectName"),
                                  "data-vv-as": _vm.$t("vue.QRConnectName"),
                                  "validate-on": "blur",
                                  name: "campaignName",
                                },
                                model: {
                                  value: _vm.dialog.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dialog, "name", $$v)
                                  },
                                  expression: "dialog.name",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("campaignName"),
                                      expression: "errors.has('campaignName')",
                                    },
                                  ],
                                  staticClass: "w-full text-danger text-sm",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("campaignName"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "vx-tooltip",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                text: _vm.$t("vue.addCampaignName"),
                                position: "top",
                              },
                            },
                            [_c("img", { attrs: { src: _vm.infoImage } })]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "vs-row",
              { staticClass: "mt-5" },
              [
                _c(
                  "vs-col",
                  {
                    staticStyle: { "max-width": "650px" },
                    attrs: { "vs-sm": "12", "vs-lg": "12" },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("routes.users")))]),
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.currentCompanyUsers,
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            "preserve-search": true,
                            placeholder: _vm.$t("vue.pickSome"),
                            selectLabel: _vm.$t("vue.pressEnterToSelect"),
                            selectedLabel: _vm.$t("vue.selectedLabel"),
                            deselectLabel: _vm.$t("vue.pressEnterToRemove"),
                            label: "name",
                            "track-by": "name",
                            "preselect-first": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function (ref) {
                                  var values = ref.values
                                  var isOpen = ref.isOpen
                                  return [
                                    values.length && !isOpen
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "multiselect__single",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(values.length) +
                                                " " +
                                                _vm._s(
                                                  values.length > 1
                                                    ? _vm.$t(
                                                        "vue.usersSelected"
                                                      )
                                                    : _vm.$t("vue.userSelected")
                                                )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1523340776
                          ),
                          model: {
                            value: _vm.dialog.recipients,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialog, "recipients", $$v)
                            },
                            expression: "dialog.recipients",
                          },
                        }),
                        _c(
                          "vx-tooltip",
                          {
                            staticClass: "ml-3",
                            attrs: {
                              text: _vm.$t("vue.addCampaignAgents"),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImage } })]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm.HAS_LEADOVERVIEW_ACCESS
              ? _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      {
                        staticStyle: { "max-width": "650px" },
                        attrs: { "vs-sm": "12", "vs-lg": "12" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex flex-row items-center mt-4" },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click:
                                    _vm.toggleIsShowedUpContactFormWhenNoAgentOnline,
                                },
                              },
                              [
                                _c("toggle-switch-icon", {
                                  attrs: {
                                    enabled:
                                      _vm.dialog
                                        .isShowedUpContactFormWhenNoAgentOnline,
                                    width: 40,
                                    height: 40,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "settings__item__caption" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "info.directlyShowUpContactFormWhenNoAgentIsOnline"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isConnectedWithCallCenter
              ? _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      {
                        staticStyle: { "max-width": "650px" },
                        attrs: { "vs-sm": "12", "vs-lg": "12" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex flex-row items-center mt-4" },
                          [
                            _c(
                              "div",
                              { on: { click: _vm.toggleIsNotifyCallCenter } },
                              [
                                _c("toggle-switch-icon", {
                                  attrs: {
                                    enabled:
                                      _vm.dialog.isNotifyCallCenterEnabled,
                                    width: 40,
                                    height: 40,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "mr-2 mt-1" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("vue.shouldNotifyCallCenterAgents")
                                  ) +
                                  "\n            "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.onCallCenterNameClick },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currentActiveCallCenter &&
                                        _vm.currentActiveCallCenter
                                          .callCenterName
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: _vm.$t(
                                    "vue.shouldNotifyCallCenterAgentsInfo"
                                  ),
                                  position: "top",
                                },
                              },
                              [_c("img", { attrs: { src: _vm.infoImage } })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }