<template>
  <div class="w-full mt-5 mb-8" style="max-width: 350px">
    <vs-popup fullscreen :title="$t('vue.cropImage')" :active.sync="showCroppa" button-close-hidden class="image-popup">
      <div class="relative">
        <div disabled class="popover-action flex justify-end items-center">
          <div class="popover-action-button mr-2" @click="onCroppaSave">
            <check-icon color="#00ff00" :width="35" :height="35"></check-icon>
          </div>
          <div class="popover-action-button ml-2" @click="showCroppa = false">
            <close-icon color="#ff0000" :width="35" :height="35"></close-icon>
          </div>
        </div>

        <croppa
          v-show="myPhoto.img"
          v-model="myPhoto"
          placeholder="Click here"
          :show-remove-button="false"
          canvas-color="transparent"
          prevent-white-space
          initial-size="cover"
          :initial-image="dialog && dialog.imageURL ? dialog.imageURL : imageURL"
          :image-border-radius="10"
          accept=".jpeg,.jpg,.gif,.png"
          :disable-drag-to-move="false"
          :file-size-limit="2048000"
          :placeholder-font-size="14"
          :disabled="false"
          @file-type-mismatch="onFileTypeMismatch"
          @file-size-exceed="onFileSizeExceed"
          @new-image-drawn="onNewImageDrawn"
          @image-remove="onImageRemoved"
          @file-choose="onFileChoose"
          class="resizable-croppa"
          :quality="2"
          :width="1024"
          :height="800"
        >
        </croppa>
      </div>
    </vs-popup>

    <div>
      <div v-if="showActivateVideoSound" class="flex flex-row justify-start items-center">
        <div @click="toggleActivateVideoSound">
          <toggle-switch-icon :enabled="activateVideoSound" />
        </div>
        <div>
          <volume-icon :height="12" class="ml-2" :stroke="activateVideoSound ? '#3B86F7' : '#d3d3d3'"></volume-icon>
        </div>
        <div class="ml-2">{{ $t('vue.activateVideoSound') }}</div>
        <div class="ml-2" style="height: 17px">
          <vx-tooltip :text="$t('info.activateVideoSound')">
            <img :src="infoImage" width="17" height="17" />
          </vx-tooltip>
        </div>
      </div>
    </div>

    <div class="w-full flex items-start justify-between">
      <div class="flex flex-col flex-center pointer text-center" @click="updateShowVideo(true)">
        <div v-if="dialog.showVideo" class="pa-dialog-selection-container selected border-box pa-dialog-type">
          <div class="flex justify-content-center align-items-center ht-100 relative">
            <vs-avatar icon="icon-check" class="pa-check-icon-dialog-type" icon-pack="feather" />
            <img src="@/assets/images/elements/videoSelected.svg" class="pa-selection-popup relative" />
          </div>
          <span class="pa-dialog-text is-selected"> {{ $t('vue.videoDialogText') }} </span>
        </div>
        <div v-else class="pa-dialog-selection-container border-box pa-dialog-type">
          <div class="flex justify-content-center align-items-center ht-100 relative">
            <img src="@/assets/images/elements/video.svg" class="pa-selection-popup relative" />
          </div>
          <span class="pa-dialog-text is-not-selected"> {{ $t('vue.videoDialogText') }} </span>
        </div>
        <div class="flex-center mt-4">
          <span style="max-width: 135px; padding-right: 3px; font-size: 0.75rem">{{ $t('vue.allowedFileTypesVideo') }}</span>
        </div>
      </div>
      <div class="flex flex-col flex-center pointer text-center" @click="updateShowVideo(false)">
        <div v-if="!dialog.showVideo" class="pa-dialog-selection-container border-box pa-dialog-type selected">
          <div class="flex justify-content-center align-items-center ht-100 relative">
            <vs-avatar icon="icon-check" class="pa-check-icon-dialog-type" icon-pack="feather" />
            <img src="@/assets/images/elements/pictureSelected.svg" class="pa-selection-popup relative" />
          </div>
          <span class="pa-dialog-text is-selected"> {{ $t('vue.pictureDialogText') }}</span>
        </div>
        <div v-else class="pa-dialog-selection-container pointer border-box pa-dialog-type">
          <div class="flex justify-content-center align-items-center ht-100">
            <img src="@/assets/images/elements/picture.svg" class="pa-selection-popup relative" />
          </div>
          <span class="pa-dialog-text is-not-selected"> {{ $t('vue.pictureDialogText') }}</span>
        </div>
        <div class="flex-center mt-4">
          <span style="max-width: 135px; padding-left: 3px; font-size: 0.75rem"> {{ $t('vue.allowedFileTypesPicture') }}</span>
          <vx-tooltip :text="$t('vue.recommendedFileTypesPictureRatio')" position="top" class="inline-flex relative ml-1">
            <img :src="infoImage" />
          </vx-tooltip>
        </div>
      </div>
    </div>
    <div class="flex w-full mt-4 justify-between items-start">
      <div class="flex-center">
        <div class="flex-box-column-start" v-if="(!videoURL || (videoURL == defaultVideoURL && !transcodedURL)) && !uploadingVideo && !transcodingVideo">
          <vs-button type="none" @click="$refs.uploadInput.click()" class="upload p-0">
            <div class="con-upload">
              <div class="con-img-upload" style="margin-top: 0">
                <div class="con-input-upload">
                  <span class="text-input" style="color: #000"> {{ $t('vue.uploadVideo') }}</span>
                  <button disabled="disabled" type="button" title="Upload" class="btn-upload-all vs-upload--button-upload">
                    <i translate="translate" class="material-icons notranslate"> cloud_upload </i>
                  </button>
                </div>
              </div>
            </div>
          </vs-button>
          <div v-if="this.dialog.showVideo && !videoURL && !transcodedURL" style="color: red" class="mt-5 mb-5">
            {{ $t('vue.requiredVideo') }}
          </div>
        </div>

        <div v-if="(videoURL && videoURL !== defaultVideoURL) || transcodedURL" class="flex-box-column-start">
          <div class="mb-5">
            <video :key="videoKey" class="video-item" ref="embedVideo" playsinline autoplay muted loop>
              <source v-if="embedVideoURL.indexOf('.webm') > -1" :src="embedVideoURL" :type="webm" />
              <source v-if="embedVideoURL.indexOf('.mp4') > -1" :src="embedVideoURL" :type="mp4" />
              {{ $t('vue.browserDoesNotSupportVideoTag') }}
            </video>
          </div>
          <vs-button
            class="delete-btn-padding"
            type="border"
            color="danger"
            size="large"
            @click="deleteVideo()"
            icon-pack="material-icons"
            icon="videocam_off"
            icon-after
          >
            {{ $t('vue.delete') }}
          </vs-button>
          <div v-if="transcodingVideo" class="flex-box-column-start">
            <vs-progress class="mt-5" indeterminate color="warning">primary</vs-progress>
            <div style="font-size: 0.75rem">{{ $t('vue.transcodingVideo') }}</div>
          </div>
        </div>
        <input style="display: none" type="file" name="file" ref="uploadInput" accept="video/*" :multiple="false" @change="detectVideoFile($event)" />
        <div v-if="uploadingVideo" class="flex items-center justify-center flex-col" style="width: 150px">
          <div>
            <vs-progress class="mt-5" style="min-width: 100px" :percent="videoProgressUpload + 10" color="primary">primary</vs-progress>
          </div>
          <div style="font-size: 0.75rem">{{ $t('vue.uploadingVideo') }}</div>
        </div>
      </div>
      <div class="flex-center">
        <div v-if="!this.imageURL || this.imageURL === this.defaultImageURL">
          <img :src="this.defaultImageURL" v-if="!dialog.showVideo && (!imageURL || imageURL !== defaultImageURL)" width="80" height="80" />
          <vs-button
            type="none"
            class="upload-photo mr-2 sm:mb-0 mb-2 upload p-0"
            @click="
              showCroppa = true
              selectFile()
            "
          >
            <div class="con-upload">
              <div class="con-img-upload" style="margin-top: 0">
                <div class="con-input-upload">
                  <span class="text-input" style="color: #000">{{ uploadPhotoButtonText }}</span>
                  <button disabled="disabled" type="button" title="Upload" class="btn-upload-all vs-upload--button-upload">
                    <i translate="translate" class="material-icons notranslate"> cloud_upload </i>
                  </button>
                </div>
              </div>
            </div>
          </vs-button>
        </div>
        <div class="flex-box-column-start" v-show="this.imageURL && this.imageURL != this.defaultImageURL">
          <div class="mb-5">
            <img
              slot="initial"
              crossorigin="anonymous"
              class="image-cover pointer"
              :src="imageURL"
              v-if="this.imageURL && this.imageURL != this.defaultImageURL"
              @click="showCroppa = true"
            />
          </div>
          <vs-button
            v-if="this.imageURL && this.imageURL != this.defaultImageURL"
            class="delete-btn-padding"
            type="border"
            color="danger"
            size="large"
            @click="removePhoto()"
            icon-pack="material-icons"
            icon="photo"
            icon-after
          >
            {{ $t('vue.delete') }}
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import imageCompression from 'browser-image-compression'

import CheckIcon from '@/components/icons/CheckIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'
import VolumeIcon from '@/components/icons/VolumeIcon.vue'

export default {
  components: {
    CheckIcon,
    CloseIcon,
    ToggleSwitchIcon,
    VolumeIcon
  },
  props: {
    videoKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      videoTranscodeStatusRef: null,
      backgroundImg: require('@/assets/images/elements/dialog-background.svg'),
      imageURL: require('@/assets/images/dialog/contact-form-background.jpeg'),
      defaultImageURL: require('@/assets/images/dialog/contact-form-background.jpeg'),
      showCroppa: false,
      myPhoto: {},
      videoURL: null,
      transcodedURL: null,
      isPhotoSelected: false,
      uploadingVideo: false,
      transcodingVideo: false,
      uploadPhotoButtonText: this.$i18n.t('vue.selectPicture'),
      videoFileName: '',
      webm: 'video/webm',
      mp4: 'video/mp4',
      progressUpload: 0,
      videoUploadTask: false,
      videoProgressUpload: 0,
      activateVideoSound: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      dialog: 'campaign',
      dialogId: 'campaignId'
    }),
    showActivateVideoSound() {
      if (!this.dialog.showVideo) return false
      return true
    },
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    },
    embedVideoURL() {
      return this.transcodedURL ? this.transcodedURL : this.videoURL ? this.videoURL : null
    },
    isDefaultPhoto() {
      return !this.dialog.imageURL
    },
    viewport() {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      return {
        vw,
        vh
      }
    }
  },
  beforeMount() {
    if (this.dialog && this.dialog.activateVideoSound) {
      this.activateVideoSound = true
    }
    if (this.dialog && typeof this.dialog.activateVideoSound === 'undefined') {
      this.dialog.activateVideoSound = false
    }
  },
  mounted() {
    const vm = this

    if (vm.dialog && vm.dialog.imageURL) {
      vm.imageURL = vm.dialog.imageURL
    } else {
      vm.imageURL = vm.defaultImageURL
    }
    if (vm.dialog && vm.dialog.videoURL) {
      vm.videoURL = vm.dialog.videoURL
    } else {
      vm.videoURL = vm.defaultVideoURL
    }

    let rdVideoTranscodePath = ''
    rdVideoTranscodePath = `video-transcode/${vm.dialogId}`

    this.videoTranscodeStatusRef = this.$firebase.database().ref(rdVideoTranscodePath)
    this.videoTranscodeStatusRef.set({ success: false })
    this.videoTranscodeStatusRef.on('value', (snapshot) => {
      const val = snapshot.val()
      if (!val.success && val.error && !vm.displayedMessage) {
        vm.displayedMessage = true
        vm.transcodingVideo = false
        vm.transcodedURL = null
        if (val.error.indexOf('Cannot determine format of input stream') === -1) {
          vm.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.transcodingVideoError'),
            text: val.error,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
      if (val && val.started) {
        vm.transcodingVideo = true
      }
      if (val && val.success === true && val.signedUrl && vm.transcodingVideo) {
        vm.transcodingVideo = false
        vm.transcodedURL = val.signedUrl
        this.dialog.transcodedURL = val.signedUrl

        setTimeout(() => {
          vm.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.success'),
            text: vm.$i18n.t('vue.transcodedVideoSuccessfully'),
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
        }, 100)

        if (vm.$refs.embedVideo) {
          vm.$refs.embedVideo.currentTime = 0
          vm.$refs.embedVideo.load()
        }
      }
    })
  },
  beforeDestroy() {
    if (this.dialogId) {
      this.$firebase.database().ref(`video-transcode/${this.dialogId}`).off('value')
    }
    if (this.videoTranscodeStatusRef) {
      this.videoTranscodeStatusRef.off('value')
    }
  },
  methods: {
    toggleActivateVideoSound() {
      if (!this.dialog) return
      this.activateVideoSound = !this.activateVideoSound
      this.dialog.activateVideoSound = this.activateVideoSound
    },
    isVideoSoundActivated() {
      if (this.dialog && this.dialog.activateVideoSound) return true
      return false
    },
    async onCroppaSave() {
      this.showCroppa = false
      this.uploadCroppedImage()
    },
    async uploadCroppedImage() {
      const vm = this
      vm.$vs.loading()
      vm.isPhotoSelected = false
      let storagePath = ''
      storagePath = `images/campaigns/${this.dialogId}`

      try {
        this.myPhoto.generateBlob(
          async (blob) => {
            const storageService = this.$firebase.storage()
            const storageRef = storageService.ref()
            let file = blob

            const options = {
              useWebWorker: true,
              initialQuality: 1,
              alwaysKeepResolution: true
            }
            try {
              const compressedFile = await imageCompression(file, options)
              if (compressedFile) {
                file = compressedFile
              }
            } catch (error) {
              /* eslint-disable-next-line */
              console.log(error.message)
            }

            const metadata = {
              contentType: 'image/png',
              cacheControl: 'max-age=604800, public'
            }

            let uploadTask = null

            uploadTask = storageRef.child(storagePath).put(file, metadata)
            this.uploadTask = true

            uploadTask.on(
              this.$firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                this.progressUpload = progress
              },
              function (error) {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    vm.text = 'fields.storage/unauthorized'
                    break
                  case 'storage/canceled':
                    // User canceled the upload
                    vm.text = 'fields.storage/canceled'
                    break
                  case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    vm.text = 'fields.storage/unknown'
                    break
                }

                vm.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.error'),
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })

                vm.uploadTask = false
              },
              async () => {
                // Upload completed successfully, now we can get the download URL
                this.uploadTask = false
                const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
                const dialogRef = await this.$db.collection('campaigns').doc(this.dialogId)
                dialogRef.set({ imageURL: downloadURL }, { merge: true })
                vm.$serverBus.$emit('pa-dialog-image-updated', { imageURL: downloadURL })
                this.$vs.loading.close()
                this.imageURL = downloadURL
                this.dialog.imageURL = downloadURL
                this.$serverBus.$emit('update-tab-key')
                this.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.success'),
                  text: vm.$i18n.t('vue.photoSavedSuccessfully'),
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })
              }
            )
          },
          'image/jpg',
          0.8
        ) // 80% compressed jpeg file
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        /* eslint-disable no-console */
        console.error(err.message)
      }
    },
    async deleteVideo() {
      const vm = this
      vm.$vs.loading()
      const storageService = this.$firebase.storage()
      try {
        if (this.videoURL) {
          const dialogRef = await this.$db.collection('campaigns').doc(this.dialogId)
          await dialogRef.set({ videoURL: null, transcodedURL: null }, { merge: true })

          if (!this.dialog.duplicate_video) {
            await storageService.refFromURL(this.dialog.videoURL).delete()
          }

          this.dialog.videoURL = null
          this.videoURL = this.defaultVideoURL
          this.$serverBus.$emit('update-tab-key')
        }
      } catch (err) {
        if (err.code === 'storage/object-not-found') {
          const dialogRef = await this.$db.collection('campaigns').doc(this.dialogId)
          await dialogRef.set({ videoURL: null, transcodedURL: null }, { merge: true })
          this.dialog.videoURL = null
          this.videoURL = this.defaultVideoURL
        }
      }
      try {
        if (this.transcodedURL) {
          if (!this.dialog.duplicate_video) {
            await storageService.refFromURL(this.dialog.transcodedURL).delete()
          }

          const dialogRef = await this.$db.collection('campaigns').doc(this.dialogId)
          await dialogRef.set({ transcodedURL: null }, { merge: true })
          this.dialog.transcodedURL = null
          this.transcodedURL = null
        }
      } catch (err) {
        if (err.code === 'storage/object-not-found') {
          const dialogRef = await this.$db.collection('campaigns').doc(this.dialogId)
          await dialogRef.set({ transcodedURL: null }, { merge: true })
          this.dialog.transcodedURL = null
          this.transcodedURL = null
        }
      }
      vm.$vs.loading.close()
      vm.$serverBus.$emit('pa-dialog-video-removed')
    },
    onFileTypeMismatch() {
      this.isPhotoSelected = false
      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.fileTypeNotSupported'),
        text: this.$i18n.t('vue.fileTypeNotSupportedMessage'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    onFileSizeExceed() {
      this.isPhotoSelected = false
      this.myPhoto.refresh()
      this.showCroppa = false
      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.fileSizeLimitExceeded'),
        text: this.$i18n.t('vue.maximumSize'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    onNewImageDrawn() {
      if (this.isDefaultPhoto) {
        this.uploadPhotoButtonText = this.$i18n.t('vue.selectPicture')
      } else {
        this.uploadPhotoButtonText = this.$i18n.t('vue.uploadPicture')
      }
    },
    onImageRemoved() {
      this.uploadPhotoButtonText = this.$i18n.t('vue.selectPicture')
    },
    async removePhoto() {
      const vm = this
      vm.$vs.loading()
      this.myPhoto.remove()
      const storageService = this.$firebase.storage()
      try {
        if (!vm.dialog.duplicate_image) {
          await storageService.refFromURL(this.dialog.imageURL).delete()
        }
        this.imageURL = this.defaultImageURL
        this.dialog.imageURL = null
        const dialogRef = await vm.$db.collection('campaigns').doc(vm.dialogId)
        await dialogRef.set({ imageURL: null }, { merge: true })

        this.$serverBus.$emit('update-tab-key')
      } catch (err) {
        let message = err.message
        if (err.code === 'storage/object-not-found') {
          message = vm.$i18n.t('vue.objectDoesNotExists')
        }
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        this.$serverBus.$emit('update-tab-key')
      }
      vm.$vs.loading.close()
      vm.$serverBus.$emit('pa-dialog-image-removed')
    },

    async selectFile() {
      if ((this.isPhotoSelected || (this.imageURL && this.imageURL !== this.defaultImageURL)) && !this.isDefaultPhoto) {
        await this.uploadCroppedImage()
      } else {
        this.myPhoto.chooseFile()
      }
    },
    onFileChoose() {
      this.isPhotoSelected = true
    },
    detectVideoFile(e) {
      const vm = this
      const fileList = e.target.files || e.dataTransfer.files
      Array.from(Array(fileList.length).keys()).map((x) => {
        const file = fileList[x]
        if (file.size / (1024 * 1024) > 25) {
          this.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.fileSizeLimitExceeded'),
            text: vm.$i18n.t('vue.maximumVideoSize'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          return
        }
        if (file.type.indexOf('webm') === -1 && file.type.indexOf('mp4') === -1) {
          this.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.fileTypeNotSupported'),
            text: vm.$i18n.t('vue.videoFileTypeNotSupportedMessage'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          return
        }
        vm.displayedMessage = false
        vm.uploadVideo(file)
      })
    },
    uploadVideo(file) {
      const fileExtension = file && !!file.name ? file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2) : 'mp4'
      const fileContentType = file && !!file.type ? file.type : 'video/mp4'
      this.videoFileName = `${this.dialogId}.${fileExtension}`

      let storagePath = ''
      storagePath = 'videos/campaigns/'
      this.videoFileName = `${this.dialogId}.${fileExtension}`

      this.uploadingVideo = true
      const storageService = this.$firebase.storage()
      const storageRef = storageService.ref()
      const metadata = {
        contentType: fileContentType,
        cacheControl: 'max-age=604800, public'
      }

      metadata.customMetadata = {
        uid: this.activeUserInfo.uid,
        dialogId: this.dialogId,
        origin: 'campaigns'
      }

      this.videoUploadTask = storageRef.child(`${storagePath}/${this.videoFileName}`).put(file, metadata)
      this.$refs.uploadInput.value = ''
    },
    updateShowVideo(show) {
      this.dialog.showVideo = show
    },
    updateDialogType(newDialogType) {
      this.dialog.type = newDialogType
      this.$serverBus.$emit('update-tab-key')
    }
  },
  watch: {
    videoUploadTask() {
      const vm = this
      this.videoUploadTask.on(
        'state_changed',
        (sp) => {
          this.uploadingVideo = true
          this.videoProgressUpload = Math.floor((sp.bytesTransferred / sp.totalBytes) * 100)
        },
        null,
        () => {
          this.videoUploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            this.uploadingVideo = false
            this.videoURL = downloadURL
            this.dialog.videoURL = downloadURL
            this.transcodedURL = null
            await vm.$db.collection('campaigns').doc(this.dialogId).set({ videoURL: downloadURL, transcodedURL: null }, { merge: true })
            this.$serverBus.$emit('update-tab-key')
          })
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.display-options {
  &__small-button {
    &-col {
      margin-left: 9.2%;
      @media (max-width: 1440px) {
        margin-left: 0;
      }
    }
    &-holder {
      @media (max-width: 991px) {
        align-items: center;
        justify-content: center;
      }
    }
    &-preview-col {
      @media (max-width: 991px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-img-wrapper {
      border: 1px solid #e6ecf1;
      box-sizing: border-box;
      border-radius: 5px;
      min-width: 125px;
      width: 125px;
      height: 100px;
      padding: 4px 11px;
    }
    &-upload {
      min-width: 125px;
      margin-right: 15px;
      margin-left: 15px;
      @media (max-width: 1440px) {
        margin-bottom: 1.25rem !important;
      }
      &-wrapper {
        @media (max-width: 1440px) {
          flex-direction: column;
        }
      }
    }
  }
}
</style>
