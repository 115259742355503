var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.tabKey, staticClass: "configure-dialog-step-three" },
    [
      _c("div", { staticClass: "flex-box-column-start" }, [
        _c(
          "div",
          { staticClass: "vx-row w-full" },
          [
            _c(
              "vs-col",
              {
                staticClass: "flex-box-row-start w-full",
                attrs: { "vs-w": "12" },
              },
              [
                _c("div", { staticClass: "headingfont" }, [
                  _vm._v(_vm._s(_vm.$t("vue.customiseQRConnect"))),
                ]),
              ]
            ),
            _c(
              "vs-col",
              {
                staticClass: "flex-box-row-start w-full",
                attrs: { "vs-w": "12" },
              },
              [
                _c("div", { staticClass: "translation--container" }, [
                  _c(
                    "div",
                    { staticClass: "mr-4" },
                    [
                      _c("info-icon", {
                        attrs: {
                          fill: "#01AAF4",
                          color: "#fff",
                          width: 20,
                          height: 20,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.$t("info.translation")) },
                  }),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-row" },
        [
          _c(
            "form",
            {
              staticClass: "flex flex-column w-full mt-2 mr-4",
              staticStyle: { "max-width": "630px" },
            },
            [
              _c("display-options", { attrs: { videoKey: _vm.videoKey } }),
              _c("div", { staticClass: "flex flex-row" }, [
                _c("div", { staticClass: "flex flex-column" }, [
                  _c("div", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(_vm.$t("vue.displayContactFormAfterSeconds"))
                    ),
                  ]),
                  _c("div", { staticClass: "flex w-full" }, [
                    _c(
                      "div",
                      { staticClass: "w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "min_value:15|max_value:60",
                              expression: "'min_value:15|max_value:60'",
                            },
                          ],
                          attrs: {
                            name: "contactFormTimeout",
                            "data-vv-as": " ",
                            type: "number",
                          },
                          model: {
                            value: _vm.dialog.contactFormTimeout,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialog, "contactFormTimeout", $$v)
                            },
                            expression: "dialog.contactFormTimeout",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("contactFormTimeout"),
                                expression: "errors.has('contactFormTimeout')",
                              },
                            ],
                            staticClass: "text-danger text-sm",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.errors.first("contactFormTimeout"))
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-2" },
                      [
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.$t(
                                "info.afterHowManySecondContactFormDisplay"
                              ),
                              position: "top",
                            },
                          },
                          [_c("img", { attrs: { src: _vm.infoImage } })]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm.dialog.type === "popup"
                ? _c(
                    "div",
                    { staticClass: "vx-col w-full mt-5" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          label: _vm.$t("inputs.title"),
                          maxlength: "70",
                        },
                        model: {
                          value: _vm.dialog.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialog, "title", $$v)
                          },
                          expression: "dialog.title",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialog.type === "popup"
                ? _c(
                    "div",
                    { staticClass: "vs-col w-full mt-5" },
                    [
                      _c("vs-textarea", {
                        attrs: {
                          counter: "300",
                          height: "100px",
                          label: _vm.$t("vue.popupText"),
                          "counter-danger": _vm.counterDangerPopupText,
                        },
                        on: {
                          "update:counterDanger": function ($event) {
                            _vm.counterDangerPopupText = $event
                          },
                          "update:counter-danger": function ($event) {
                            _vm.counterDangerPopupText = $event
                          },
                        },
                        model: {
                          value: _vm.dialog.text,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialog, "text", $$v)
                          },
                          expression: "dialog.text",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialog.type === "button"
                ? _c(
                    "div",
                    { staticClass: "vs-col w-full mt-5" },
                    [
                      _c("vs-textarea", {
                        attrs: {
                          counter: "300",
                          height: "100px",
                          label: _vm.$t("vue.speechBubbleText"),
                          "counter-danger": _vm.counterDangerPopupText,
                        },
                        on: {
                          "update:counterDanger": function ($event) {
                            _vm.counterDangerPopupText = $event
                          },
                          "update:counter-danger": function ($event) {
                            _vm.counterDangerPopupText = $event
                          },
                        },
                        model: {
                          value: _vm.dialog.text,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialog, "text", $$v)
                          },
                          expression: "dialog.text",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialog.type === "button"
                ? _c(
                    "div",
                    { staticClass: "vs-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { label: _vm.$t("inputs.callToAction") },
                        model: {
                          value: _vm.dialog.confirmButtonText,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialog, "confirmButtonText", $$v)
                          },
                          expression: "dialog.confirmButtonText",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialog.type === "popup"
                ? _c("div", { staticClass: "vs-col w-full md:w-1/12" }, [
                    _vm._v(" "),
                  ])
                : _vm._e(),
              _vm.dialog.type === "popup"
                ? _c(
                    "div",
                    { staticClass: "vs-col w-full md:w-5/12" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { label: _vm.$t("inputs.rejectButton") },
                        model: {
                          value: _vm.dialog.cancelButtonText,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialog, "cancelButtonText", $$v)
                          },
                          expression: "dialog.cancelButtonText",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "mt-4 step-headline" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("vue.step2WelcomeScreen")) +
                    "\n      "
                ),
              ]),
              _c("div", { staticClass: "mt-2 mb-2" }, [
                _vm._v(_vm._s(_vm.$t("vue.step2WelcomeText"))),
              ]),
              _c(
                "div",
                { staticClass: "w-full" },
                [
                  _c("quill-editor", {
                    ref: "quillEditorA",
                    attrs: { options: _vm.editorOption },
                    model: {
                      value: _vm.dialog.textStep2,
                      callback: function ($$v) {
                        _vm.$set(_vm.dialog, "textStep2", $$v)
                      },
                      expression: "dialog.textStep2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "flex flex-row justify-center items-center mt-4",
                },
                [
                  _c(
                    "div",
                    { on: { click: _vm.toggleMoreInformation } },
                    [
                      _c("toggle-switch-icon", {
                        attrs: {
                          enabled: _vm.dialog.isMoreInformationEnabled,
                          width: 40,
                          height: 40,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mr-2 mt-1" }, [
                    _vm._v(_vm._s(_vm.$t("vue.customizableMoreInformation"))),
                  ]),
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.$t("info.moreInformation"),
                        position: "top",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.infoImage } })]
                  ),
                ],
                1
              ),
              _vm.dialog.isMoreInformationEnabled
                ? _c(
                    "div",
                    { staticClass: "mt-4 w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          label: _vm.$t("vue.textMoreInformation"),
                          name: "moreInformationButtonText",
                        },
                        model: {
                          value: _vm.dialog.moreInformationButtonText,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dialog,
                              "moreInformationButtonText",
                              $$v
                            )
                          },
                          expression: "dialog.moreInformationButtonText",
                        },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(
                          _vm._s(_vm.errors.first("moreInformationButtonText"))
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mt-4 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|isURLValid",
                        expression: "'required|isURLValid'",
                      },
                    ],
                    staticClass: "w-full",
                    attrs: {
                      label: _vm.$t("vue.linkMoreInformation"),
                      name: "moreInformationLink",
                      disabled: !_vm.dialog.isMoreInformationEnabled,
                    },
                    on: { blur: _vm.checkMoreInformationLink },
                    model: {
                      value: _vm.dialog.moreInformationLink,
                      callback: function ($$v) {
                        _vm.$set(_vm.dialog, "moreInformationLink", $$v)
                      },
                      expression: "dialog.moreInformationLink",
                    },
                  }),
                  _vm.dialog.isMoreInformationEnabled
                    ? _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("moreInformationLink"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "mt-4" }, [
                _c("div", { staticClass: "mt-2" }, [
                  _c("label", { staticClass: "call-to-action" }, [
                    _vm._v(_vm._s(_vm.$t("vue.buttonColor"))),
                  ]),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dialog.step2ButtonColor,
                      expression: "dialog.step2ButtonColor",
                    },
                  ],
                  staticClass: "pa-color-picker",
                  attrs: { type: "color", name: "confirmButtonColor" },
                  domProps: { value: _vm.dialog.step2ButtonColor },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.dialog,
                        "step2ButtonColor",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "mt-4" }, [
                _c("div", { staticClass: "mt-2" }, [
                  _c("label", { staticClass: "call-to-action" }, [
                    _vm._v(_vm._s(_vm.$t("vue.fontColor"))),
                  ]),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dialog.step2TextColor,
                      expression: "dialog.step2TextColor",
                    },
                  ],
                  staticClass: "pa-color-picker",
                  attrs: { type: "color", name: "step2TextColor" },
                  domProps: { value: _vm.dialog.step2TextColor },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.dialog,
                        "step2TextColor",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "flex flex-column mt-8" }, [
                _c("div", { staticClass: "flex flex-col logo-image-holder" }, [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "flex flex-col" },
                      [
                        _c("div", { staticClass: "logo-image-img-wrapper" }, [
                          _c("img", {
                            staticClass: "logo-image-img w-full h-full pointer",
                            attrs: {
                              slot: "initial",
                              crossorigin: "anonymous",
                              src: _vm.dialog.logoImage || _vm.defaultLogoImage,
                            },
                            slot: "initial",
                          }),
                        ]),
                        _vm.dialog.logoImage
                          ? _c(
                              "vs-button",
                              {
                                staticClass:
                                  "delete-btn-padding mt-6 ml-auto mr-auto",
                                attrs: {
                                  type: "border",
                                  color: "danger",
                                  size: "large",
                                  "icon-pack": "material-icons",
                                  icon: "photo",
                                  "icon-after": "",
                                },
                                on: { click: _vm.removeLogoImage },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("vue.delete")) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center logo-image-upload-wrapper",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "upload-photo mr-2 sm:mb-0 mb-2 upload p-0 pointer logo-image-upload",
                              attrs: { for: "svg-file-input" },
                            },
                            [
                              _c("input", {
                                staticStyle: { display: "none" },
                                attrs: {
                                  type: "file",
                                  id: "svg-file-input",
                                  accept:
                                    "image/png, image/jpeg, image/svg+xml",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.selectLogoImage($event)
                                  },
                                  click: _vm.onFileInputClick,
                                },
                              }),
                              _c("div", { staticClass: "con-upload" }, [
                                _c(
                                  "div",
                                  { staticClass: "con-img-upload mt-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "con-input-upload" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-input",
                                            staticStyle: { color: "#000" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("vue.selectPicture")
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._m(0),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "max-width": "116px",
                                "padding-left": "3px",
                                "font-size": "0.75rem",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("vue.uploadSVGSmallButtonIconText")
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("welcome-screen-preview", {
            attrs: {
              id: "text-and-dialog-type",
              uploadType: _vm.dialog.useOneMediaForAllSteps
                ? "allMedia"
                : "welcomeScreenMedia",
              uploadSubtype:
                _vm.dialog.useOneMediaForAllSteps &&
                typeof _vm.dialog.allMedia === "object" &&
                _vm.dialog.allMedia !== null
                  ? "welcomeScreenMedia"
                  : "",
              currentStep: _vm.currentStep,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn-upload-all vs-upload--button-upload",
        attrs: { disabled: "disabled", type: "button", title: "Upload" },
      },
      [
        _c(
          "i",
          {
            staticClass: "material-icons notranslate",
            attrs: { translate: "translate" },
          },
          [_vm._v(" cloud_upload ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }