<template>
  <waiting-room></waiting-room>
</template>
<script>
import WaitingRoom from '@/components/campaign/WaitingRoom.vue'
export default {
  name: 'QRConnectWaitingRoom',
  components: {
    WaitingRoom
  }
}
</script>
