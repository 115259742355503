<template>
  <div class="w-full ml-4 flex justify-end">
    <div class="flex flex-column">
      <div class="mb-3" style="align-self: flex-start">{{ $t('vue.preview') }}</div>
      <div class="preview-button-card">
        <div class="flex flex-view">
          <div class="flex flex-column">
            <div class="preview-button-card-right">
              <div class="preview-button-card-right-image" v-show="dialog && !dialog.showVideo">
                <img :src="imageURL" />
              </div>
              <div v-show="dialog && dialog.showVideo" class="preview-button-card-right-video">
                <video
                  v-if="embedVideoURL"
                  ref="embedVideo"
                  style="border-radius: 5px; object-fit: cover; width: 280px; height: 566px; position: relative; top: 0; left: 0"
                  playsinline
                  autoplay
                  :muted="!dialog.activateVideoSound"
                  loop
                >
                  <source v-if="embedVideoURL.indexOf('.webm') > -1" :src="embedVideoURL" :type="webm" />
                  <source v-if="embedVideoURL.indexOf('.mp4') > -1" :src="embedVideoURL" :type="mp4" />
                </video>
              </div>

              <div class="preview-button-card-right-content" :style="step2TextColor">
                <div class="flex preview-button-card-right-content-step2" style="width: 280px; justify-content: flex-start" v-html="dialog.textStep2"></div>

                <div class="flex justify-evenly" style="width: 253px; justify-content: space-evenly">
                  <div
                    v-if="!dialog.communicationOptions || dialog.communicationOptions.chat || currentStep === 1"
                    class="preview-button-card-right-content-action-button flex flex-column justify-center items-center"
                    :style="step2ButtonColor"
                  >
                    <div>
                      <message-square-icon :width="20" :height="20"></message-square-icon>
                    </div>
                    <div>Chat</div>
                  </div>
                  <div
                    v-if="!dialog.communicationOptions || dialog.communicationOptions.audio || currentStep === 1"
                    class="preview-button-card-right-content-action-button flex flex-column justify-center items-center"
                    :style="step2ButtonColor"
                  >
                    <div>
                      <vertical-phone-icon :width="20" :height="20"></vertical-phone-icon>
                    </div>
                    <div>Phone</div>
                  </div>

                  <div
                    v-if="!dialog.communicationOptions || dialog.communicationOptions.video || currentStep === 1"
                    class="preview-button-card-right-content-action-button flex flex-column justify-center items-center"
                    :style="step2ButtonColor"
                  >
                    <div>
                      <video-call-icon :width="20" :height="20"></video-call-icon>
                    </div>
                    <div>Video</div>
                  </div>
                </div>

                <div class="flex justify-center items-center" style="width: 278px" v-if="dialog.isMoreInformationEnabled">
                  <a :href="dialog.moreInformationLink" target="_blank">
                    <div class="preview-button-card-right-content-more-information flex justify-center items-center" :style="moreInformationColor">
                      {{ moreInformationButtonText }}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MessageSquareIcon from '@/components/icons/MessageSquareIcon.vue'
import VerticalPhoneIcon from '@/components/icons/VerticalPhoneIcon.vue'
import VideoCallIcon from '@/components/icons/VideoCallIcon.vue'

export default {
  name: 'CampaignWelcomeScreenPreview',
  components: {
    MessageSquareIcon,
    VerticalPhoneIcon,
    VideoCallIcon
  },
  props: {
    id: {
      type: String
    },
    uploadType: {
      type: String
    },
    uploadSubtype: {
      type: String
    },
    currentStep: {
      type: Number
    }
  },
  data() {
    return {
      webm: 'video/webm',
      mp4: 'video/mp4',
      defaultImageURL: require('@/assets/images/dialog/contact-form-background.jpeg')
    }
  },
  computed: {
    ...mapGetters({
      hasWhiteLabel: 'hasWhiteLabel',
      dialog: 'campaign'
    }),
    imageURL() {
      return this.dialog && this.dialog.imageURL ? this.dialog.imageURL : this.defaultImageURL
    },
    transcodedURL() {
      return this.dialog && this.dialog.transcodedURL ? this.dialog.transcodedURL : null
    },
    videoURL() {
      return this.dialog && this.dialog.videoURL ? this.dialog.videoURL : null
    },
    embedVideoURL() {
      return this.transcodedURL ? this.transcodedURL : this.videoURL ? this.videoURL : this.defaultVideoURL
    },
    defaultVideoURL() {
      return '/Mann-Winken-SD.mp4'
    },
    chatSelectionStyle() {
      if (this.dialog && (typeof this.dialog.activateFontShadow === 'undefined' || this.dialog.activateFontShadow)) {
        return { textShadow: '0px 0px 8px #000000' }
      }
      return null
    },
    showDialogDefaultMedia() {
      if (this.dialog && this.dialog.showDefaultMedia) {
        return true
      }
      return false
    },
    mediaKey() {
      const showVideo = this.dialog && this.dialog.showVideo
      return `${this.id}_${this.uploadType}_${this.showDialogDefaultMedia}_${showVideo}`
    },
    mediaImg() {
      if (this.dialog) {
        if (this.showDialogDefaultMedia) {
          return this.dialog.showVideo ? this.embedVideoURL : this.imageURL
        }
        if (this.uploadType) {
          if (this.uploadSubtype) {
            return this.dialog[this.uploadType][this.uploadSubtype]
          } else {
            return this.dialog[this.uploadType] ? this.dialog[this.uploadType] : this.imageURL
          }
        }
      }

      return this.imageURL
    },
    dialogSloganTitle() {
      return this.dialog && this.hasWhiteLabel ? this.dialog.sloganTitle : this.defaultSloganTitle
    },
    moreInformationColor() {
      return {
        border: `1px solid ${this.dialog.step2ButtonColor}`,
        color: this.dialog.step2ButtonColor
      }
    },
    moreInformationButtonText() {
      return this.dialog && this.dialog.isMoreInformationEnabled && this.dialog.moreInformationButtonText
        ? this.dialog.moreInformationButtonText
        : this.$i18n.t('vue.moreInformation')
    },
    step2ButtonColor() {
      return {
        backgroundColor: this.dialog.step2ButtonColor
      }
    },
    step2TextColor() {
      return {
        color: this.dialog.step2TextColor
      }
    },
    step2IconColor() {
      return {
        color: this.dialog.step2IconColor
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.close-box {
  width: 25px;
  height: 25px;
  border-radius: 4px;
}

.preview-button-card {
  &-right {
    height: 566px;
    width: 280px;
    position: relative;
    border-radius: 13px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px 0px;

    &-image {
      justify-content: center;
      display: flex;
      flex-direction: row;
      border-radius: 13px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.178125) 48.44%, rgba(0, 0, 0, 0.6) 100%);
        border-radius: 13px;
      }
      img {
        min-width: 280px;
        min-height: 566px;
        border-radius: 13px;
        object-fit: cover;
      }
    }

    &-video {
      justify-content: center;
      display: flex;
      flex-direction: row;
      border-radius: 13px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.178125) 48.44%, rgba(0, 0, 0, 0.6) 100%);
        border-radius: 13px;
      }
    }

    &-content {
      position: absolute;
      bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-step2 {
        padding: 10px 20px 20px 20px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        display: inline-block !important;
        margin-bottom: 20px;
        line-height: 1.2;
      }

      &-action-button {
        width: 100%;
        height: 75px;
        margin-top: 10px;
        margin-right: 10px;
        margin-bottom: 10px;

        background: #3b86f7;
        border-radius: 13px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        &:last-child {
          margin-right: 0px;
        }

        color: #fff;
        cursor: pointer;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
      }
      &-action-button:hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
      }

      &-more-information {
        width: 253px;
        height: 35px;
        left: 18px;
        top: 708px;
        border: 1px solid #3b86f7;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 6px;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        background: linear-gradient(179.98deg, rgba(0, 0, 0, 0) 0.27%, rgba(0, 0, 0, 0.6) 99.99%);
        cursor: pointer;
      }
      &-more-information:hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
      }

      &-powered-by-container {
        text-align: center;
        color: #fff;
        position: absolute;
        display: flex;
        bottom: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        &-header {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
