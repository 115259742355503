var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full mt-5 mb-8", staticStyle: { "max-width": "350px" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "image-popup",
          attrs: {
            fullscreen: "",
            title: _vm.$t("vue.cropImage"),
            active: _vm.showCroppa,
            "button-close-hidden": "",
          },
          on: {
            "update:active": function ($event) {
              _vm.showCroppa = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "relative" },
            [
              _c(
                "div",
                {
                  staticClass: "popover-action flex justify-end items-center",
                  attrs: { disabled: "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "popover-action-button mr-2",
                      on: { click: _vm.onCroppaSave },
                    },
                    [
                      _c("check-icon", {
                        attrs: { color: "#00ff00", width: 35, height: 35 },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "popover-action-button ml-2",
                      on: {
                        click: function ($event) {
                          _vm.showCroppa = false
                        },
                      },
                    },
                    [
                      _c("close-icon", {
                        attrs: { color: "#ff0000", width: 35, height: 35 },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("croppa", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.myPhoto.img,
                    expression: "myPhoto.img",
                  },
                ],
                staticClass: "resizable-croppa",
                attrs: {
                  placeholder: "Click here",
                  "show-remove-button": false,
                  "canvas-color": "transparent",
                  "prevent-white-space": "",
                  "initial-size": "cover",
                  "initial-image":
                    _vm.dialog && _vm.dialog.imageURL
                      ? _vm.dialog.imageURL
                      : _vm.imageURL,
                  "image-border-radius": 10,
                  accept: ".jpeg,.jpg,.gif,.png",
                  "disable-drag-to-move": false,
                  "file-size-limit": 2048000,
                  "placeholder-font-size": 14,
                  disabled: false,
                  quality: 2,
                  width: 1024,
                  height: 800,
                },
                on: {
                  "file-type-mismatch": _vm.onFileTypeMismatch,
                  "file-size-exceed": _vm.onFileSizeExceed,
                  "new-image-drawn": _vm.onNewImageDrawn,
                  "image-remove": _vm.onImageRemoved,
                  "file-choose": _vm.onFileChoose,
                },
                model: {
                  value: _vm.myPhoto,
                  callback: function ($$v) {
                    _vm.myPhoto = $$v
                  },
                  expression: "myPhoto",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("div", [
        _vm.showActivateVideoSound
          ? _c(
              "div",
              { staticClass: "flex flex-row justify-start items-center" },
              [
                _c(
                  "div",
                  { on: { click: _vm.toggleActivateVideoSound } },
                  [
                    _c("toggle-switch-icon", {
                      attrs: { enabled: _vm.activateVideoSound },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("volume-icon", {
                      staticClass: "ml-2",
                      attrs: {
                        height: 12,
                        stroke: _vm.activateVideoSound ? "#3B86F7" : "#d3d3d3",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.activateVideoSound"))),
                ]),
                _c(
                  "div",
                  { staticClass: "ml-2", staticStyle: { height: "17px" } },
                  [
                    _c(
                      "vx-tooltip",
                      { attrs: { text: _vm.$t("info.activateVideoSound") } },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.infoImage,
                            width: "17",
                            height: "17",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "w-full flex items-start justify-between" }, [
        _c(
          "div",
          {
            staticClass: "flex flex-col flex-center pointer text-center",
            on: {
              click: function ($event) {
                return _vm.updateShowVideo(true)
              },
            },
          },
          [
            _vm.dialog.showVideo
              ? _c(
                  "div",
                  {
                    staticClass:
                      "pa-dialog-selection-container selected border-box pa-dialog-type",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex justify-content-center align-items-center ht-100 relative",
                      },
                      [
                        _c("vs-avatar", {
                          staticClass: "pa-check-icon-dialog-type",
                          attrs: { icon: "icon-check", "icon-pack": "feather" },
                        }),
                        _c("img", {
                          staticClass: "pa-selection-popup relative",
                          attrs: {
                            src: require("@/assets/images/elements/videoSelected.svg"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "pa-dialog-text is-selected" }, [
                      _vm._v(" " + _vm._s(_vm.$t("vue.videoDialogText")) + " "),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "pa-dialog-selection-container border-box pa-dialog-type",
                  },
                  [
                    _vm._m(0),
                    _c(
                      "span",
                      { staticClass: "pa-dialog-text is-not-selected" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("vue.videoDialogText")) + " "
                        ),
                      ]
                    ),
                  ]
                ),
            _c("div", { staticClass: "flex-center mt-4" }, [
              _c(
                "span",
                {
                  staticStyle: {
                    "max-width": "135px",
                    "padding-right": "3px",
                    "font-size": "0.75rem",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("vue.allowedFileTypesVideo")))]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "flex flex-col flex-center pointer text-center",
            on: {
              click: function ($event) {
                return _vm.updateShowVideo(false)
              },
            },
          },
          [
            !_vm.dialog.showVideo
              ? _c(
                  "div",
                  {
                    staticClass:
                      "pa-dialog-selection-container border-box pa-dialog-type selected",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex justify-content-center align-items-center ht-100 relative",
                      },
                      [
                        _c("vs-avatar", {
                          staticClass: "pa-check-icon-dialog-type",
                          attrs: { icon: "icon-check", "icon-pack": "feather" },
                        }),
                        _c("img", {
                          staticClass: "pa-selection-popup relative",
                          attrs: {
                            src: require("@/assets/images/elements/pictureSelected.svg"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "pa-dialog-text is-selected" }, [
                      _vm._v(" " + _vm._s(_vm.$t("vue.pictureDialogText"))),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "pa-dialog-selection-container pointer border-box pa-dialog-type",
                  },
                  [
                    _vm._m(1),
                    _c(
                      "span",
                      { staticClass: "pa-dialog-text is-not-selected" },
                      [_vm._v(" " + _vm._s(_vm.$t("vue.pictureDialogText")))]
                    ),
                  ]
                ),
            _c(
              "div",
              { staticClass: "flex-center mt-4" },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "max-width": "135px",
                      "padding-left": "3px",
                      "font-size": "0.75rem",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("vue.allowedFileTypesPicture")))]
                ),
                _c(
                  "vx-tooltip",
                  {
                    staticClass: "inline-flex relative ml-1",
                    attrs: {
                      text: _vm.$t("vue.recommendedFileTypesPictureRatio"),
                      position: "top",
                    },
                  },
                  [_c("img", { attrs: { src: _vm.infoImage } })]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex w-full mt-4 justify-between items-start" },
        [
          _c("div", { staticClass: "flex-center" }, [
            (!_vm.videoURL ||
              (_vm.videoURL == _vm.defaultVideoURL && !_vm.transcodedURL)) &&
            !_vm.uploadingVideo &&
            !_vm.transcodingVideo
              ? _c(
                  "div",
                  { staticClass: "flex-box-column-start" },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "upload p-0",
                        attrs: { type: "none" },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.uploadInput.click()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "con-upload" }, [
                          _c(
                            "div",
                            {
                              staticClass: "con-img-upload",
                              staticStyle: { "margin-top": "0" },
                            },
                            [
                              _c("div", { staticClass: "con-input-upload" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-input",
                                    staticStyle: { color: "#000" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("vue.uploadVideo"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-upload-all vs-upload--button-upload",
                                    attrs: {
                                      disabled: "disabled",
                                      type: "button",
                                      title: "Upload",
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons notranslate",
                                        attrs: { translate: "translate" },
                                      },
                                      [_vm._v(" cloud_upload ")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    this.dialog.showVideo && !_vm.videoURL && !_vm.transcodedURL
                      ? _c(
                          "div",
                          {
                            staticClass: "mt-5 mb-5",
                            staticStyle: { color: "red" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("vue.requiredVideo")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            (_vm.videoURL && _vm.videoURL !== _vm.defaultVideoURL) ||
            _vm.transcodedURL
              ? _c(
                  "div",
                  { staticClass: "flex-box-column-start" },
                  [
                    _c("div", { staticClass: "mb-5" }, [
                      _c(
                        "video",
                        {
                          key: _vm.videoKey,
                          ref: "embedVideo",
                          staticClass: "video-item",
                          attrs: {
                            playsinline: "",
                            autoplay: "",
                            muted: "",
                            loop: "",
                          },
                          domProps: { muted: true },
                        },
                        [
                          _vm.embedVideoURL.indexOf(".webm") > -1
                            ? _c("source", {
                                attrs: {
                                  src: _vm.embedVideoURL,
                                  type: _vm.webm,
                                },
                              })
                            : _vm._e(),
                          _vm.embedVideoURL.indexOf(".mp4") > -1
                            ? _c("source", {
                                attrs: {
                                  src: _vm.embedVideoURL,
                                  type: _vm.mp4,
                                },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("vue.browserDoesNotSupportVideoTag")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "vs-button",
                      {
                        staticClass: "delete-btn-padding",
                        attrs: {
                          type: "border",
                          color: "danger",
                          size: "large",
                          "icon-pack": "material-icons",
                          icon: "videocam_off",
                          "icon-after": "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteVideo()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("vue.delete")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm.transcodingVideo
                      ? _c(
                          "div",
                          { staticClass: "flex-box-column-start" },
                          [
                            _c(
                              "vs-progress",
                              {
                                staticClass: "mt-5",
                                attrs: { indeterminate: "", color: "warning" },
                              },
                              [_vm._v("primary")]
                            ),
                            _c(
                              "div",
                              { staticStyle: { "font-size": "0.75rem" } },
                              [_vm._v(_vm._s(_vm.$t("vue.transcodingVideo")))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _c("input", {
              ref: "uploadInput",
              staticStyle: { display: "none" },
              attrs: {
                type: "file",
                name: "file",
                accept: "video/*",
                multiple: false,
              },
              on: {
                change: function ($event) {
                  return _vm.detectVideoFile($event)
                },
              },
            }),
            _vm.uploadingVideo
              ? _c(
                  "div",
                  {
                    staticClass: "flex items-center justify-center flex-col",
                    staticStyle: { width: "150px" },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "vs-progress",
                          {
                            staticClass: "mt-5",
                            staticStyle: { "min-width": "100px" },
                            attrs: {
                              percent: _vm.videoProgressUpload + 10,
                              color: "primary",
                            },
                          },
                          [_vm._v("primary")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticStyle: { "font-size": "0.75rem" } }, [
                      _vm._v(_vm._s(_vm.$t("vue.uploadingVideo"))),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "flex-center" }, [
            !this.imageURL || this.imageURL === this.defaultImageURL
              ? _c(
                  "div",
                  [
                    !_vm.dialog.showVideo &&
                    (!_vm.imageURL || _vm.imageURL !== _vm.defaultImageURL)
                      ? _c("img", {
                          attrs: {
                            src: this.defaultImageURL,
                            width: "80",
                            height: "80",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "vs-button",
                      {
                        staticClass:
                          "upload-photo mr-2 sm:mb-0 mb-2 upload p-0",
                        attrs: { type: "none" },
                        on: {
                          click: function ($event) {
                            _vm.showCroppa = true
                            _vm.selectFile()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "con-upload" }, [
                          _c(
                            "div",
                            {
                              staticClass: "con-img-upload",
                              staticStyle: { "margin-top": "0" },
                            },
                            [
                              _c("div", { staticClass: "con-input-upload" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-input",
                                    staticStyle: { color: "#000" },
                                  },
                                  [_vm._v(_vm._s(_vm.uploadPhotoButtonText))]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-upload-all vs-upload--button-upload",
                                    attrs: {
                                      disabled: "disabled",
                                      type: "button",
                                      title: "Upload",
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons notranslate",
                                        attrs: { translate: "translate" },
                                      },
                                      [_vm._v(" cloud_upload ")]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      this.imageURL && this.imageURL != this.defaultImageURL,
                    expression:
                      "this.imageURL && this.imageURL != this.defaultImageURL",
                  },
                ],
                staticClass: "flex-box-column-start",
              },
              [
                _c("div", { staticClass: "mb-5" }, [
                  this.imageURL && this.imageURL != this.defaultImageURL
                    ? _c("img", {
                        staticClass: "image-cover pointer",
                        attrs: {
                          slot: "initial",
                          crossorigin: "anonymous",
                          src: _vm.imageURL,
                        },
                        on: {
                          click: function ($event) {
                            _vm.showCroppa = true
                          },
                        },
                        slot: "initial",
                      })
                    : _vm._e(),
                ]),
                this.imageURL && this.imageURL != this.defaultImageURL
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "delete-btn-padding",
                        attrs: {
                          type: "border",
                          color: "danger",
                          size: "large",
                          "icon-pack": "material-icons",
                          icon: "photo",
                          "icon-after": "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removePhoto()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("vue.delete")) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex justify-content-center align-items-center ht-100 relative",
      },
      [
        _c("img", {
          staticClass: "pa-selection-popup relative",
          attrs: { src: require("@/assets/images/elements/video.svg") },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex justify-content-center align-items-center ht-100" },
      [
        _c("img", {
          staticClass: "pa-selection-popup relative",
          attrs: { src: require("@/assets/images/elements/picture.svg") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }