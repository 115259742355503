<template>
  <div class="configure-dialog-step-four">
    <div class="flex-box-column-start">
      <div class="vx-row w-full">
        <vs-col class="flex-box-row-start w-full" vs-w="12">
          <div class="headingfont mr-2">{{ $t('vue.communicationOptions') }}</div>
        </vs-col>
      </div>
    </div>

    <div class="request-options mt-4 flex flex-row">
      <form class="flex flex-column vx-col lg:w-7/12 w-full mt-2 mr-4">
        <div class="mt-4 flex w-full">
          <div class="mb-2">
            <strong>{{ $t('vue.communicationOptionsLabel') }}</strong>
          </div>
        </div>
        <div class="flex-view">
          <div class="flex-view-button" :class="{ 'flex-view-button--disabled': dialog.communicationOptions && !dialog.communicationOptions.chat }" @click="updateCommunicationOptions('chat')">
            <div style="margin-right: 10px; height: 18px">
              <chat-icon :width="18" :height="18" :color="dialog.communicationOptions && !dialog.communicationOptions.chat ? '#b2b2b2' : '#ffffff'" />
            </div>
            <div class="text">{{ $t('vue.chat') }}</div>
          </div>
          <div class="flex-view-button" :class="{ 'flex-view-button--disabled': dialog.communicationOptions && !dialog.communicationOptions.audio }" @click="updateCommunicationOptions('audio')">
            <div style="margin-right: 10px; height: 18px">
              <vertical-phone-icon :width="18" :height="18" :color="dialog.communicationOptions && !dialog.communicationOptions.audio ? '#b2b2b2' : '#ffffff'" />
            </div>
            <div class="text">{{ $t('vue.phone') }}</div>
          </div>
          <div class="flex-view-button" :class="{ 'flex-view-button--disabled': dialog.communicationOptions && !dialog.communicationOptions.video }" @click="updateCommunicationOptions('video')">
            <div style="margin-right: 10px; height: 20px">
              <video-call-icon :width="20" :height="20" :color="dialog.communicationOptions && !dialog.communicationOptions.video ? '#b2b2b2' : '#ffffff'" />
            </div>
            <div class="text">{{ $t('vue.video') }}</div>
          </div>
        </div>

        <div class="mt-4 flex w-full">
          <div class="mb-2">
            <strong>{{ $t('vue.activateLeadFormBeforeConversation') }}</strong>
          </div>
        </div>
        <div class="flex-view">
          <div class="mt-4 flex items-center">
            <div class="mr-2">

              <div @click="enableLeadFormBeforeConversation">
                <toggle-switch-icon :enabled="dialog.isShowLeadFormBeforeConversation" :width="40" :height="40" />
              </div>
            </div>
            <div class="mr-2">{{ $t('vue.yes') }}</div>
          </div>
        </div>

        <template v-if="dialog.isShowLeadFormBeforeConversation">
          <div class="mt-4 flex w-full">
            <div class="mb-2">
              <strong>{{ $t('vue.enablePrivacyInformation') }}</strong>
            </div>
          </div>

          <div class="mt-4 flex items-center">
            <div class="mr-2">
              <div @click="dialog.leadForm.isPrivacyInformationEnabled = !dialog.leadForm.isPrivacyInformationEnabled">
                <toggle-switch-icon :enabled="dialog.leadForm.isPrivacyInformationEnabled" :width="40" :height="40" />
              </div>
            </div>
            <div class="mr-2">{{ $t('vue.yes') }}</div>
          </div>

          <div class="mt-4 mr-2 w-full" v-if="dialog.leadForm.isPrivacyInformationEnabled">
            <quill-editor v-model="dialog.leadForm.privacyInformationHtml" ref="quillEditorA" :options="editorOption"> </quill-editor>
          </div>
        </template>
      </form>
      <div class="vx-col lg:w-5/12 w-full mt-2 ml-4">
        <div class="flex flex-column">
          <welcome-screen-preview id="request-options" :uploadType="dialog.useOneMediaForAllSteps ? 'allMedia' : 'welcomeScreenMedia'" :uploadSubtype="dialog.useOneMediaForAllSteps && typeof dialog.allMedia === 'object' && dialog.allMedia !== null ? 'welcomeScreenMedia' : ''" :currentStep="currentStep" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import { mapGetters, mapMutations } from 'vuex'
import ChatIcon from '@/components/icons/ChatIcon.vue'
import VerticalPhoneIcon from '@/components/icons/VerticalPhoneIcon.vue'
import VideoCallIcon from '@/components/icons/VideoCallIcon.vue'
import WelcomeScreenPreview from '@/components/campaign/media/WelcomeScreenPreview'
import ToggleSwitchIcon from '@/components/icons/ToggleSwitchIcon.vue'

export default {
  components: {
    quillEditor,
    ChatIcon,
    VerticalPhoneIcon,
    VideoCallIcon,
    WelcomeScreenPreview,
    ToggleSwitchIcon
  },
  props: {
    currentStep: {
      type: Number
    }
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'link']]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'campaign'
    })
  },

  beforeMount() {
    if (this.dialog && !this.dialog.communicationOptions) {
      this.dialog.communicationOptions = {
        chat: true,
        audio: true,
        video: true
      }
    }

    if (this.dialog && !this.dialog.isShowLeadFormBeforeConversation) {
      this.dialog.isShowLeadFormBeforeConversation = false
    }

    if (this.dialog && !this.dialog.leadForm) {
      this.dialog.leadForm = {
        fields: {
          name: {
            enabled: true,
            required: true
          },
          email: {
            enabled: true,
            required: true
          }
        },
        isPrivacyInformationEnabled: false
      }
    }
  },
  methods: {
    ...mapMutations({
      updateDialog: 'UPDATE_CAMPAIGN'
    }),
    updateCommunicationOptions(type) {
      const communicationOptions = { ...this.dialog.communicationOptions }
      communicationOptions[type] = !communicationOptions[type]

      if (!communicationOptions.chat && !communicationOptions.audio && !communicationOptions.video) {
        return
      }

      this.dialog.communicationOptions = communicationOptions
    },

    enableLeadFormBeforeConversation() {
      this.dialog.isShowLeadFormBeforeConversation = !this.dialog.isShowLeadFormBeforeConversation

      this.updateDialog({ ...this.dialog })
    }
  }
}
</script>

<style lang="scss">
.configure-dialog-step-four {
  .request-options {
    display: flex;
    @media only screen and (max-width: 680px) {
      flex-direction: column !important;
    }
    @media only screen and (min-width: 681px) {
      flex-direction: row !important;
    }
  }

  .flex-view {
    display: flex;
    width: 100%;

    @media only screen and (max-width: 1440px) {
      flex-direction: column !important;
    }
    @media only screen and (min-width: 1441px) {
      flex-direction: row !important;
    }

    &-button {
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
      width: 154px;
      height: 38px;
      background: #53a5f2;
      border: 1px solid #53a5f2;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .text {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
      }

      &--disabled {
        background: #ffffff;
        border: 1.5px solid #b2b2b2;

        .text {
          color: #b2b2b2;
        }
      }
    }

    @media only screen and (max-width: 769px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @media only screen and (min-width: 770px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .ql-editor {
    min-height: 200px;
    font-family: 'Lato';
  }
}
</style>
