<template>
  <vx-card>
    <vs-progress indeterminate color="primary" v-if="!hasDialogLoaded"></vs-progress>
    <div v-else class="configure-dialog">
      <form-wizard
        color="rgba(var(--vs-primary), 1)"
        title=""
        subtitle=""
        :nextButtonText="$t('vue.next')"
        :backButtonText="$t('vue.back')"
        :finishButtonText="$t('inputs.submit')"
        :ref="'formWizard'"
        shape="circle"
        @on-complete="onComplete"
        @on-change="onStepChanged"
      >
        <!-- tab 1 content -->
        <tab-content :title="$t('vue.QRConnectSettings')" class="mb-5" icon="feather icon-settings" :before-change="validateStep1" :lazy="true">
          <QRConnectSettings></QRConnectSettings>
        </tab-content>

        <!-- tab 2 content -->
        <tab-content :title="$t('vue.adjustQRConnect')" class="mb-5" icon="feather icon-image" :before-change="validateStep3">
          <AdjustYourQRConnect v-if="currentStep === 1" :currentStep="currentStep" :video-key="videoKey" :lazy="true"></AdjustYourQRConnect>
        </tab-content>

        <!-- tab 3 content -->
        <tab-content :title="$t('vue.requestOptions')" class="mb-5" icon="feather icon-clock" :lazy="true">
          <QRConnectRequestOptions v-if="currentStep === 2" :currentStep="currentStep"></QRConnectRequestOptions>
        </tab-content>

        <!-- tab 4 content -->
        <tab-content :title="$t('vue.waitingRoom')" class="mb-5" icon="feather icon-clock" :lazy="true">
          <QRConnectWaitingRoom></QRConnectWaitingRoom>
        </tab-content>

        <!-- tab 5 content -->
        <tab-content :title="$t('vue.endScreen')" class="mb-5" icon="feather icon-star" :lazy="true">
          <QRConnectSessionEndScreen></QRConnectSessionEndScreen>
        </tab-content>

        <!-- tab 6 content -->
        <tab-content :title="$t('vue.contactForm')" class="mb-5" icon="feather icon-message-square" :lazy="true">
          <QRConnectContactForm></QRConnectContactForm>
        </tab-content>
      </form-wizard>
    </div>
  </vx-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import QRConnectSettings from './QRConnectSettings.vue'
import AdjustYourQRConnect from './AdjustYourQRConnect.vue'
import QRConnectRequestOptions from './QRConnectRequestOptions.vue'
import QRConnectWaitingRoom from './QRConnectWaitingRoom.vue'
import QRConnectSessionEndScreen from './QRConnectSessionEndScreen.vue'
import QRConnectContactForm from './QRConnectContactForm.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

export default {
  components: {
    FormWizard,
    TabContent,
    QRConnectSettings,
    AdjustYourQRConnect,
    QRConnectRequestOptions,
    QRConnectWaitingRoom,
    QRConnectSessionEndScreen,
    QRConnectContactForm
  },
  watch: {
    'dialog.confirmButtonText'() {
      if (this.dialog.confirmButtonText.length > 20) {
        this.dialog.confirmButtonText = this.dialog.confirmButtonText.substring(0, 20)
      }
    },
    'dialog.cancelButtonText'() {
      if (this.dialog.cancelButtonText.length > 20) {
        this.dialog.cancelButtonText = this.dialog.cancelButtonText.substring(0, 20)
      }
    },
    hasDialogLoaded() {
      if (this.hasDialogLoaded) {
        setTimeout(() => {
          if (this.activateAllSteps) {
            const wizard = this.$refs.formWizard
            wizard.activateAll()
          }
        }, 500)
      }
    }
  },
  data() {
    return {
      currentStep: 0,
      hasDialogLoaded: false,
      unsubscribeCampaign: null,
      hasCompletedFormSubmission: false,
      loading: false,
      isDefaultPhoto: false,
      showImage: false,
      uploadingVideo: false,
      videoURL: null,
      transcodedURL: null,
      transcodingVideo: false,
      displayedMessage: false,
      switchVisibility: true,
      videoDialogImg: require('@/assets/images/elements/video.svg'),
      pictureDialogImg: require('@/assets/images/elements/picture.svg'),
      videoDialogImgSelected: require('@/assets/images/elements/videoSelected.svg'),
      pictureDialogImgSelected: require('@/assets/images/elements/pictureSelected.svg'),
      backgroundImg: require('@/assets/images/elements/dialog-background.svg'),
      defaultContactFormTimeout: 60,

      videoKey: Math.random().toString(36).substring(2, 15),
      activateAllSteps: true
    }
  },
  async beforeMount() {
    this.updateDialogId(this.$route.params.id)
  },
  async mounted() {
    this.subscribeCampaign()
  },
  beforeDestroy() {
    if (this.unsubscribeCampaign) {
      this.unsubscribeCampaign()
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      dialog: 'campaign',
      dialogId: 'campaignId'
    }),
    HAS_LEADOVERVIEW_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('contact-form') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    locale() {
      return this.$i18n.locale
    }
  },
  methods: {
    ...mapMutations({
      updateDialog: 'UPDATE_CAMPAIGN',
      updateDialogId: 'UPDATE_CAMPAIGN_ID'
    }),
    subscribeCampaign() {
      if (this.unsubscribeCampaign) {
        this.unsubscribeCampaign()
      }

      const campaignRef = this.$db.collection('campaigns').doc(this.dialogId)

      this.unsubscribeCampaign = campaignRef.onSnapshot((doc) => {
        const dialog = doc.data()

        if (dialog) {
          if (!dialog.created) {
            dialog.created = new Date()
          }
          if (!dialog.showVideo) {
            dialog.showVideo = false
          }
          if (!dialog.design) {
            dialog.design = 'pathadvice'
          }

          if (typeof dialog.isNotifyCallCenterEnabled === 'undefined') {
            dialog.isNotifyCallCenterEnabled = false
          }

          if (!dialog.type || dialog.type === 'popup') {
            dialog.type = 'button-card'
          }
          if (!dialog.createdBy) {
            dialog.createdBy = this.activeUserInfo.uid
          }

          /* STEP 2 Properties */
          if (typeof dialog.isMoreInformationEnabled === 'undefined') {
            dialog.isMoreInformationEnabled = false
          }

          if (typeof dialog.isAlwaysShowButtonEnabled === 'undefined') {
            dialog.isAlwaysShowButtonEnabled = false
          }

          if (typeof dialog.isMinimizeSmallButtonEnabled === 'undefined') {
            dialog.isMinimizeSmallButtonEnabled = false
          }

          if (typeof dialog.isSmallButtonPreviewVisible === 'undefined') {
            dialog.isSmallButtonPreviewVisible = false
          }

          if (dialog.contactFormTimeout === 0 || !dialog.contactFormTimeout) {
            dialog.contactFormTimeout = this.defaultContactFormTimeout
          }
          if (!dialog.textStep2) {
            dialog.textStep2 = this.translate('vue.textStep2DefaultText')
          }
          if (!dialog.step2ButtonColor) {
            dialog.step2ButtonColor = '#3B86F7'
            dialog.step2TextColor = '#ffffff'
          }

          if (!dialog.confirmButtonColor) {
            dialog.confirmButtonColor = '#238000'
          }
          if (!dialog.confirmButtonTextColor) {
            dialog.confirmButtonTextColor = '#ffffff'
          }
          /* STEP 2 Properties */

          if (!dialog.waitingRoomMessage) {
            dialog.waitingRoomMessage = this.translate('vue.waitingRoomDefaultMessage')
          }

          if (!dialog.leadForm) {
            dialog.leadForm = {
              enableLeadFormBeforeConversation: false,
              isPrivacyInformationEnabled: false,
              privacyInformationHtml: this.translate('vue.privacyInformationMessage')
            }
          }

          if (dialog.leadForm && typeof dialog.leadForm.privacyInformationHtml === 'undefined') {
            dialog.leadForm.privacyInformationHtml = this.translate('vue.privacyInformationMessage')
          }

          /* STEP 4 Waiting room content chat message properties */
          if (typeof dialog.waitingRoomContentChatMessage === 'undefined') {
            dialog.waitingRoomContentChatMessage = {
              backgroundColor: '#C4C4C4',
              fontColor: '#12598d'
            }
          }
          /* STEP 4 Waiting room content chat message properties */

          if (typeof dialog.sessionEndScreen === 'undefined') {
            dialog.sessionEndScreen = {
              titleClosingMessage: this.translate('vue.sessionEndScreenDefaultTitle'),
              copyClosingMessage: this.translate('vue.sessionEndScreenDefaultCopy'),
              backgroundColor: '#3B86F7',
              fontColor: '#FFFFFF'
            }
          }

          if (typeof dialog.isShowFeedbackForm === 'undefined') {
            dialog.isShowFeedbackForm = false
          }

          if (typeof dialog.isSendFeedbackViaMessage === 'undefined') {
            dialog.isSendFeedbackViaMessage = false
          }

          if (typeof dialog.feedbackFormQuestionFirst === 'undefined') {
            dialog.feedbackFormQuestionFirst = ''
          }

          if (typeof dialog.feedbackFormQuestionSecond === 'undefined') {
            dialog.feedbackFormQuestionSecond = ''
          }

          if (typeof dialog.feedbackFormQuestionThird === 'undefined') {
            dialog.feedbackFormQuestionThird = ''
          }

          if (typeof dialog.contactForm === 'undefined') {
            this.activateAllSteps = false
            dialog.contactForm = {
              message: this.translate('vue.contactFormText'),
              backgroundColor: '#3B86F7',
              fontColor: '#FFFFFF',
              isBookingEnabled: false,
              selectedAppointment: null,
              fields: {
                name: {
                  enabled: true,
                  required: true
                },
                email: {
                  enabled: true,
                  required: true
                },
                phone: {
                  enabled: true,
                  required: true
                },
                message: {
                  enabled: true,
                  required: true
                },
                date: {
                  enabled: false,
                  required: false
                }
              },
              sendMessageTitle: this.translate('vue.sendMessageDefaultTitle'),
              sendMessageCopy: this.translate('vue.sendMessageDefaultCopy'),
              bookingSlotTitle: this.translate('vue.bookingSlotDefaultTitle'),
              bookingSlotCopy: this.translate('vue.bookingSlotDefaultCopy'),
              isPrivacyInformationEnabled: false,
              privacyInformationHtml: this.translate('vue.privacyInformationText')
            }
          }
          /*
          Privacy Information added in later stage of development, so adding this check here
        */
          if (typeof dialog.contactForm.isPrivacyInformationEnabled === 'undefined') {
            dialog.contactForm.isPrivacyInformationEnabled = false
            dialog.contactForm.privacyInformationHtml = this.translate('vue.privacyInformationText')
          }

          dialog.default = true
          this.updateDialog(dialog)
          this.hasDialogLoaded = true
        }
      })
    },
    async onStepChanged() {
      if (!this.hasCompletedFormSubmission) {
        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

        await this.save()
      }
      setTimeout(() => {
        if (this.$refs && this.$refs.formWizard) {
          this.currentStep = this.$refs.formWizard.activeTabIndex
        }
      }, 300)
    },
    translate(code) {
      return this.$i18n.t(code)
    },
    validateStep1() {
      this.$serverBus.$emit('validate-step-1')
      if (this.errors.items.filter((el) => el.field === 'landingPage' || el.field === 'campaignName').length > 0 || this.dialog.recipients.length === 0) {
        return false
      }
      return true
    },
    validateStep2() {
      this.videoKey = Math.random().toString(36).substring(2, 15)
      return true
    },
    validateStep3() {
      this.$serverBus.$emit('validate-step-3')
      if (this.dialog.isMoreInformationEnabled) {
        const validateButtonText =
          typeof this.dialog.moreInformationButtonText !== 'undefined' &&
          this.dialog.moreInformationButtonText &&
          this.dialog.moreInformationButtonText.trim().length > 0

        const validateNotEmpty =
          typeof this.dialog.moreInformationLink !== 'undefined' && this.dialog.moreInformationLink && this.dialog.moreInformationLink.length > 0
        const validateURL =
          typeof this.dialog.moreInformationLink !== 'undefined' &&
          this.dialog.moreInformationLink &&
          /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(this.dialog.moreInformationLink)
        return validateNotEmpty && validateURL && validateButtonText
      }

      return true
    },
    validateStep5() {
      this.$serverBus.$emit('validate-step-5')
      if (this.dialog.isShowFeedbackForm) {
        const validateNotEmpty = this.dialog.feedbackFormQuestionFirst && this.dialog.feedbackFormQuestionSecond && this.dialog.feedbackFormQuestionThird
        return !!validateNotEmpty
      }
      return true
    },

    async onComplete() {
      this.hasCompletedFormSubmission = true
      await this.save()
      setTimeout(() => {
        this.$vs.loading.close()
        this.$vs.notify({
          title: this.translate('vue.success'),
          text: this.translate('vue.campaignSuccessfullySaved'),
          color: 'success',
          iconPack: 'feather',
          position: 'bottom-center',
          icon: 'icon-check-circle'
        })
        this.$router.push({ name: 'campaigns' })
      }, 300)
    },

    async save() {
      this.$vs.loading()
      this.loading = true
      const dialog = { ...this.dialog }

      if (dialog.contactFormTimeout === 0 || !dialog.contactFormTimeout) {
        dialog.contactFormTimeout = this.defaultContactFormTimeout
      }

      // if (this.dialog) {
      //   delete dialog.videoURL
      //   delete dialog.transcodedURL
      //   delete dialog.b2bOnly
      // }

      await this.$db
        .collection('campaigns')
        .doc(this.dialogId)
        .set(JSON.parse(JSON.stringify(dialog)), { merge: true })
      this.loading = false
      setTimeout(() => {
        this.$vs.loading.close()
      }, 300)
    },
    updateConfirmButtonColor(color) {
      this.dialog.confirmButtonColor = color.hex
    },
    updateConfirmButtonColorText(color) {
      this.dialog.confirmButtonTextColor = color.hex
    }
  }
}
</script>
<style lang="scss">
@import './configure-dialog.scss';
</style>
