<template>
  <session-end-screen></session-end-screen>
</template>
<script>
import SessionEndScreen from '@/components/campaign/SessionEndScreen.vue'
export default {
  name: 'QRConnectSessionEndScreen',
  components: {
    SessionEndScreen
  }
}
</script>
