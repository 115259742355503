<template>
  <div>
    <text-and-dialog-type v-if="dialog" :currentStep="currentStep" :videoKey="videoKey"></text-and-dialog-type>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import TextAndDialogType from '@/components/campaign/TextAndDialogType.vue'
export default {
  name: 'AdjustYourQRConnect',
  components: {
    TextAndDialogType
  },
  props: {
    videoKey: {
      type: String,
      required: true
    },
    currentStep: {
      type: Number
    }
  },
  computed: {
    ...mapGetters({
      dialog: 'campaign'
    })
  },
  mounted() {
    this.$serverBus.$on('pa-dialog-image-updated', (dialog) => {
      if (dialog && dialog.imageURL) {
        this.imageURL = dialog.imageURL
      } else {
        this.imageURL = this.defaultImageURL
      }

      this.dialog.imageURL = dialog.imageURL
    })

    this.$serverBus.$on('pa-dialog-image-removed', () => {
      this.dialog.imageURL = null
    })

    this.$serverBus.$on('pa-dialog-video-updated', (dialog) => {
      this.dialog.videoURL = dialog.videoURL
      this.dialog.transcodedURL = dialog.transcodedURL
    })

    this.$serverBus.$on('pa-dialog-video-removed', () => {
      this.dialog.videoURL = null
      this.dialog.transcodedURL = null
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('pa-dialog-image-updated')
    this.$serverBus.$off('pa-dialog-image-removed')
    this.$serverBus.$off('pa-dialog-video-removed')
    this.$serverBus.$off('pa-dialog-video-removed')
  }
}
</script>

<style lang="scss">
.con-vs-popup.fullscreen .vs-popup {
  .vs-popup--header {
    display: none;
  }
  .vs-popup--content {
    height: calc(100vh - 100px);
  }
}
.croppa-container.croppa--has-target {
  border-radius: 10px;
}
.check-circle-icon {
  position: absolute;
  top: 15px;
  width: 50px;
  height: 50px;
  right: 20px;
  z-index: 999;
  border-radius: 6px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}
.check-circle-icon:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  background-color: #026e02 !important;
  opacity: 0.6;
}
.configure-dialog-step-two {
  .check-circle-icon {
    position: absolute;
    top: 15px;
    width: 50px;
    height: 50px;
    right: 15px;
    z-index: 999;
    border-radius: 6px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  }
  .check-circle-icon:hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    background-color: #026e02 !important;
    opacity: 0.6;
  }
}
</style>
